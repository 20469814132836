import React, { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { getAllUsers, disableUser, enableUser, getCompanyUsers, createUser } from '../service/users'
import { AuthContext } from '../contexts/authContext'
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef } from 'material-react-table';
import {
  Edit as EditIcon,
  Person as PersonIcon,
  PersonOff as PersonOffIcon,
  Add as AddIcon
} from '@mui/icons-material';
import IconButton from '@material-ui/core/IconButton'
import { Company, Tag, User } from '../libs/types'
import { CompaniesContext } from '../contexts/companyContext'
import { getReactTableLocalisation } from '../libs/i18n'
import { useTranslation } from 'react-i18next'
import { UserStatsPanel } from '../components/stats/userStatsPanel'
import { DialogTitle, DialogContent, Select, DialogActions, Button, TextField, Tooltip, Dialog, MenuItem, Typography, FormGroup } from '@material-ui/core'
import Avatar from '@mui/material/Avatar';
import { Stack } from '@mui/material'
import bgF2m from '../ressources/backgroundf2m.png';
import frFlag from '../locales/fr/flag.png';
import enFlag from '../locales/en/flag.png';
import Services from '../service/services'
import { TagContext } from '../contexts/TagContext'
import TagSelector from '../components/tags/tagSelector'
import { UsersContext } from '../contexts/userContext'
import { Language } from '@material-ui/icons'
import LangSelector from '../components/langSelector'
import toast, { Toaster } from 'react-hot-toast'

var data: userRepresentation[] | null = null
interface userRepresentation {
  user: User
  companyName: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  spacedTop: {
    marginTop: "30px"
  },
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },
  session: {
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  green: {
    backgroundColor: 'green',
  },
  hero: {
    width: '100%'
  }
}))

export default function Users() {
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  var fleetManagerProfile = auth.sessionInfo?.fleetManagerProfile
  const companies = useContext(CompaniesContext)
  const companiesMap = useContext(CompaniesContext).companyMap
  const tagContext = useContext(TagContext)
  const usersContext = useContext(UsersContext)
  const users = usersContext.users
  const usersRefresh = useContext(UsersContext).refresh
  const userColumn = useMemo<MRT_ColumnDef<userRepresentation>[]>(() => userColumnDef(t, !fleetManagerProfile), [i18n.language, usersContext.users])
  const [userToEdit, setUserToEdit] = useState(null as (User | null))
  const classes = useStyles()
  const [createModalOpen, setCreateModalOpen] = useState(false);

  var [statedata, setstatedata] = useState(data);
  var refresh = async () => {
    var d: userRepresentation[] = []
    users.forEach(user => {
      d.push({
        user,
        companyName: user.companyID ? companiesMap.get(user.companyID)!.name : " - "
      })
    })
    setstatedata(d)
  }

  useEffect(() => {
    refresh()
  }, [companies.companies, tagContext.tags, users])
  return (
    <>
      <Toaster
        position="top-right"
      />
      <Grid className={classes.root} container direction="column" alignItems="center">
        <Grid container spacing={1} >
          <Grid container item xs={12} direction="column" >
            <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
              <MaterialReactTable
                state={{
                  isLoading: statedata && statedata.length > 0 ? false : true,
                  density: "compact"
                }}
                renderTopToolbarCustomActions={() => {
                  if (fleetManagerProfile) {
                    return (<></>)
                  }


                  return (

                    <Tooltip title={t('addUserTitle')}>
                      <IconButton onClick={() => {
                        setUserToEdit(null)
                        setCreateModalOpen(true)
                      }} >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                muiTableBodyCellProps={{
                  sx: {
                    alignItems: "center",
                    "& .MuiBox-root": {
                      padding: "0px",
                      //lineHeight:'5px',
                      //height:'10px',
                      //maxHeight:'10px'
                    },

                  },
                }}
                enableGlobalFilter={true}
                enableRowActions
                renderRowActions={({ row }) => (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <IconButton onClick={() => {
                      setUserToEdit(row.original.user)
                      setCreateModalOpen(true)
                    }
                    } >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={async () => {
                      let u = statedata![row.index].user
                      if (u.enabled) {
                        try {
                          await disableUser(u.userName)
                          usersContext.refresh()
                          toast.success(t('userDisabled'))
                        } catch (e) {
                          toast.error(t('errorDisablingUser'))
                          console.log(e)
                        }
                      } else {
                        try {
                          await enableUser(u.userName)
                          usersContext.refresh()
                          toast.success(t('userEnabled'))
                        } catch (e) {
                          toast.error(t('errorEnablingUser'))
                          console.log(e)
                        }
                      }
                      refresh()
                    }}>
                      {
                        statedata && statedata![row.index].user.enabled ?
                          <PersonOffIcon /> :
                          <PersonIcon />
                      }
                    </IconButton>
                  </Box>
                )}
                columns={userColumn}
                data={statedata || []}
                enableRowSelection={false}
                enableColumnOrdering={false}
                enableHiding={false}
                enableDensityToggle={false}
                localization={getReactTableLocalisation()}
                renderDetailPanel={row => {
                  if (row.row.getIsExpanded()) {
                    return UserStatsPanel(row.row.original.user)
                  }
                  return (<></>)

                }}
              />
            </Box>
          </Grid>
        </Grid>
        <CreateModal
          key={userToEdit ? userToEdit.userName : "newUser"}
          columns={userColumn}
          selectedUSer={userToEdit}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onSubmit={async (values, isUpdate) => {
            setstatedata(null)
            if (isUpdate) {
              await services.updateUser(valuesToUser(values));
            } else {
              await services.createUser(valuesToUser(values));
            }
            await usersRefresh()

          }}
          title={t('addUserTitle')}
          confirmButtonCaption={userToEdit ? t("editUserConfirm") : t("addUserConfirm")}
        />
      </Grid>
    </>
  )
}


const userColumnDef = (t: (s: string) => string, showClientColumn: boolean): MRT_ColumnDef<userRepresentation>[] => {

  var columns: MRT_ColumnDef<userRepresentation>[] = []
  if (showClientColumn) {
    columns.push(
      {
        accessorKey: 'companyName',
        header: t('client'),
        enableEditing: false
      }
    )
  }
  columns = [...columns,
  {
    accessorKey: 'user.userName',
    header: t('userName'),
    enableEditing: false
  },
  {
    accessorKey: 'user.profile',
    header: t('profileType'),
    accessorFn: (row) => { return row.user ? t(row.user.profile) : " - " }
  },
  {
    accessorKey: 'user.firstName',
    header: t('firstName'),
  },
  {
    accessorKey: 'user.lastName',
    header: t('lastName'),
  },
  {
    accessorKey: 'user.email',
    header: t('email'),
  },
  {
    accessorKey: 'user.restrictions',
    header: t('restrictions'),
    accessorFn: (row) => { return row.user && row.user.restrictions ? row.user.restrictions.map(tag => { return tag.label }).join(",") : "-" }
  },
  {
    accessorKey: 'user.tags',
    header: t('entityTags'),
    accessorFn: (row) => { return row.user && row.user.tags ? row.user.tags.map(tag => { return tag.label }).join(",") : "-" }
  },
  {
    accessorKey: 'user.language',
    header: t('language'),
    Cell: cell => (

      <Box component="span" >
        {cell.row.original.user.language == 'fr' ?
          <IconButton >
            <Avatar alt="Francais" src={frFlag} sx={{ height: '20px', width: '20px' }} />
          </IconButton>

          :

          <IconButton>
            <Avatar alt="English" src={enFlag} sx={{ height: '20px', width: '20px' }} />
          </IconButton>}
      </Box>
    )
  }

  ]
  return columns
}


interface CreateModalProps {
  columns: MRT_ColumnDef<userRepresentation>[];
  onClose: () => void;
  onSubmit: (values: any, isUpdate: boolean) => void;
  selectedUSer: User | null
  open: boolean;
  title: string
  confirmButtonCaption: string
}

export const CreateModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  selectedUSer,
  title,
  confirmButtonCaption
}: CreateModalProps) => {
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );
  const handleSubmit = () => {
    values["company.companyID"] = selectedCompany
    values["user.restrictions"] = restrictions
    onSubmit(values, selectedUSer ? true : false);
    onClose();
  };

  const companies = useContext(CompaniesContext).nonTechnicalCompanies || []
  const [selectedCompany, setselectedCompany] = useState(selectedUSer ? selectedUSer.companyID : companies[0] ? companies[0].id : "")
  const [selectedProfile, setselectedProfile] = useState(selectedUSer ? selectedUSer.profile as string : "endUser")


  const [restrictions, setRestrictions] = useState([] as Tag[])
  const auth = useContext(AuthContext)
  const tagContext = useContext(TagContext)
  useEffect(() => {
    if (selectedUSer) {
      var v = JSON.parse(JSON.stringify(values))
      v["company.companyID"] = selectedUSer.companyID
      v["user.restrictions"] = selectedUSer.restrictions
      v["user.firstName"] = selectedUSer.firstName
      v["user.lastName"] = selectedUSer.lastName
      v["user.email"] = selectedUSer.email
      v["user.userName"] = selectedUSer.userName
      v["user.language"] = selectedUSer.language
      v["user.profile"] = selectedUSer.profile
      v["user.tags"] = selectedUSer.tags
      setValues(v)
    }
  }, [selectedUSer])

  var currentProfile = values["user.profile"]

  var fleetManagerProfile = auth.sessionInfo?.fleetManagerProfile
  var profiles = ["endUser"]
  if (!fleetManagerProfile) {
    //admin
    profiles = ["fleetManager", "admin"]//TODO mising setups screen for end users it case of manual invite
  }
  const labelsMap = columns.reduce((acc, column) => {
    acc[column.accessorKey ?? ''] = t(column.header)
    return acc
  }
    , {} as any)
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => {
              switch (column.accessorKey) {
                case "companyName":
                  return (<FormGroup>
                    <label>{t("client")}</label>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={companies.length == 1}
                      value={selectedCompany}
                      label={labelsMap[column.accessorKey]}
                      onChange={(e) => {

                        setValues({ ...values, ["company.name"]: e.target.value })
                          ; setselectedCompany(e.target.value as string)
                      }}
                    >

                      {companies.map(company =>
                        <MenuItem value={company.id}>{company.name}</MenuItem>)
                      }
                    </Select>
                  </FormGroup>)
                case "user.profile":
                  return (<FormGroup>
                    <label>{t("profileType")}</label>
                    <Select
                      value={values[column.accessorKey]}
                      label={column.header}
                      name={column.accessorKey}
                      disabled={fleetManagerProfile}
                      onChange={(e) => {
                        setValues({ ...values, [e.target.name as string]: e.target.value })
                      }}
                    >
                      {profiles.map(profile =>
                        <MenuItem value={profile}>{t(profile)}</MenuItem>)
                      }
                    </Select>
                  </FormGroup>)
                case "user.lastcreditNote":
                  return (<></>)
                case "user.enabled":
                  return (<></>)
                case "user.status":
                  return (<></>)
                case "user.firstName":
                  return (<TextField
                    // @ts-ignore
                    value={values[column.accessorKey]}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />)
                case "user.lastName":
                  return (<TextField
                    // @ts-ignore
                    value={values[column.accessorKey]}
                    label={column.header}
                    name={column.accessorKey}

                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }


                  />)

                case "user.restrictions":
                  if (currentProfile != "fleetManager") {
                    return (<></>)
                  }
                  return (<>
                    <Typography>{t("restrictions")}</Typography>
                    <TagSelector
                      currentTags={selectedUSer ? selectedUSer.restrictions ? selectedUSer.restrictions : [] : []}
                      multipleselect={true}
                      tags={tagContext.tags}
                      handleChange={(tags) => { setRestrictions(tags) }}
                    /></>)

                case "user.userName":
                  return (<TextField
                    // @ts-ignore
                    value={values[column.accessorKey]}
                    disabled={selectedUSer ? true : false}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />)
                case "user.email":
                  return (<TextField
                    // @ts-ignore
                    value={values[column.accessorKey]}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />)
                case "user.language":
                  return (
                    <LangSelector
                      userLanguage={values[column.accessorKey]}
                      setuserLanguage={(language: string) => setValues({ ...values, ["user.language"]: language })}
                    />
                  )
                case "user.tags":
                  if (currentProfile != "endUser") {
                    return (<></>)
                  }
                  return (<>
                    <Typography>{t("entityTags")}</Typography>
                    <TagSelector
                      currentTags={selectedUSer ? selectedUSer.tags ? selectedUSer.tags : [] : []}
                      multipleselect={false}
                      tags={tagContext.tags}
                      handleChange={(tags) => { setValues({ ...values, ["user.tags"]: tags }) }}
                    /></>)
                default:
                  return (<TextField
                    // @ts-ignore
                    value={selectedUSer ? selectedUSer[column.accessorKey?.split('user.')[1]] : ""}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />)
              }
            }
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions >
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          {confirmButtonCaption}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const valuesToUser = (values: any): User => {
  var user = Object.keys(values).reduce((acc, key) => {
    let splited = key.split('user.')
    if (splited.length == 2) {
      let value = values[key]
      return { ...acc, [splited[1]]: value };
    }
    return acc
  }, {} as any);
  user.companyID = values["company.companyID"]

  return user
}