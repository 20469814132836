import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#066DCD',
            dark: '#044c8f'
          },
          secondary: {
            main: '#212966',
          },
    },
  });

