import { format } from "path"
import { calendar, CreditNote, Note } from "./types"

/**
 * Converts a timestamp to a Date object.
 * @param ts - The timestamp to convert.
 * @returns The Date object representing the timestamp.
 */
export const tsToDate = (ts: number): Date => {
    return new Date(ts * 1000)
}

/**
 * Rounds a number to the specified decimal places.
 * @param value - The number to round.
 * @param decimalNumber - The number of decimal places to round to.
 * @returns The rounded number.
 */
export const Round = (value: number, decimalNumber: number): number => {
    return Math.round(value * Math.pow(10, decimalNumber)) / Math.pow(10, decimalNumber)
}


export const formatCalendarData = (c: calendar, year: number): any[] => {
    switch (c.ID) {
        case "tempo":
            return formatTempoCalendar(c, year)
    }
    return []
}

interface displaybleCalendar {
    date: string
    count: number
    level: number
}

interface tempoData {
    BLUE: string[] // example ["2023-01-02"]
    WHITE: string[]
    RED: string[]
}

function formatTempoCalendar(c: calendar, year: number): displaybleCalendar[] {
    var data: tempoData = c.data
    var res: displaybleCalendar[] = []

    data.BLUE.forEach((date: string) => {
        res.push({ date: date, count: 1, level: 3 })
    }
    )
    data.WHITE.forEach((date: string) => {
        res.push({ date: date, count: 1, level: 2 })
    }
    )
    data.RED.forEach((date: string) => {
        res.push({ date: date, count: 1, level: 1 })
    }
    )
    //add a level 0 in the res array at the first date of teh year absent in teh res array begining at the first of january
    var guess = new Date(year, 0, 1)
    res.push({ date: guess.toISOString().split('T')[0], count: 0, level: 0 })



    // Add a level 0 in the res array at the last date of the year absent in the res array
    var lastYearDate = new Date(year, 11, 31)
    var lastYearDateString = lastYearDate.toISOString().split('T')[0]
    if (!res.find((el) => el.date == lastYearDateString)) {
        res.push({ date: lastYearDateString, count: 0, level: 0 })
    }
    //add a level 0 in the res array at the last  date of the year absent in the res array
    return res
}

export const getDateAsYYYYMMDD = (date: Date): string => {
    return date.toISOString().split('T')[0]
}


export function getDistanceFromLatLonInM(lat1: number, lon1: number, lat2: number, lon2: number) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c * 1000; // Distance in m
    return d
}

function deg2rad(deg: number) {
    return deg * (Math.PI / 180)
}


export function noteMapFromCreditNoteArray(carray: CreditNote[]): Map<string, Note> {
    const r = new Map<string, Note>()
    for (var cn of carray) {
        for (var n of cn.notes) {
            r.set(n.id, n)
        }
    }
    return r
}