import React, { useContext, useState } from 'react';
import * as types from '../../libs/types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slider from '@mui/material/Slider';
import utc from 'dayjs/plugin/utc';
import {
    Calculate as CalculateIcon
} from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import 'dayjs/locale/fr';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ChartValue, LineChart } from "../graph";
import { TransitionProps } from '@mui/material/transitions';
import { Grid, Slide, Select, makeStyles, Typography } from '@material-ui/core';
import { PeriodEditor } from '../periodsSetter';
import SpecialDaysSelector from './specialDayselector';
import FixedPriceSetter from './fixedPricedSetter';
import { AuthContext } from '../../contexts/authContext';
import Services from '../../service/services';
import { useTranslation } from 'react-i18next';

dayjs.extend(utc);
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    availableGrids: types.Grid[];
    isOpen: boolean;
    onclose: () => void;
}

const useStyles = makeStyles((theme) => ({
    spaced: {
        marginLeft: "15px",
        marginRight: "15px"
    },
    popupTitle: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "1.8rem!important"
    },
    verticalCenter: {
        marginTop: 'auto',
        marginBottom: 'auto'
    },
}))

const SimulatorPopup: React.FC<Props> = ({ availableGrids, isOpen, onclose }) => {
    const { t } = useTranslation();
    const classes = useStyles()
    const auth = useContext(AuthContext)
    const services = new Services(auth.sessionInfo?.accessToken || "")
    const [selectedGridId, setSelectedGridId] = useState(availableGrids[0] ? availableGrids[0].id : '');
    const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);
    const [selectedSpecialDays, setselectedSpecialDays] = useState([] as string[]);
    const [duration, setDuration] = useState(0);
    const [chargedPower, setChargedPower] = useState(0);
    const [hcPeriods, setHcPeriods] = useState([[new Date("01/01/2000 00:00"), new Date("01/01/2000 23:59")]]);
    const [chartValues, setchartValues] = useState([] as ChartValue[]);
    const [fixedPrices, setfixedPrices] = useState({ HPF: '0', HCF: '0' } as { HPF: string, HCF: string });
    const [chartAnnotations, setchartAnnotations] = useState([] as any[]);
    const [simualtionResult, setsimualtionResult] = useState({} as any);
   
    const [lastKey, setlastKey] = useState(Math.random());
    const getGrid = (id: string) => {
        return availableGrids.find((grid) => grid.id === id);
    };
    const currentGrid = selectedGridId ? getGrid(selectedGridId) : null;
    const currentformulaGroup = currentGrid && selectedDateTime ? currentGrid!.formulaGroups.find((fg) => {
        return fg.start.valueOf() <= selectedDateTime.valueOf() && fg.end.valueOf() >= selectedDateTime.valueOf()
    }) : null

    const canCompute = selectedGridId &&
        selectedDateTime &&
        duration &&
        chargedPower &&
        currentGrid &&
        currentformulaGroup &&
        (!currentGrid.isDualTarif
            || (currentGrid.fixedHCperiods)
            || (currentformulaGroup.HCperiods)
        )

    const isHCperiodNeeded = selectedGridId && getGrid(selectedGridId)!.isDualTarif && !getGrid(selectedGridId)!.fixedHCperiods
    const isSpecialDaysNeeded = selectedGridId && getGrid(selectedGridId)!.hasSpecialDays
    const isFixedPriceNeeded = selectedGridId && getGrid(selectedGridId)!.isFixedPrice
    

    const compute = async () => {
        const grid = getGrid(selectedGridId)
        var gridopts: any[] = []
        if (grid!.isDualTarif) {
            //gridopts may be required if 
            if (!grid!.fixedHCperiods) {
                //gridOpts required
                for (let periods of hcPeriods) {

                    let s = new Date(periods[0])
                    let e = new Date(periods[1])
                    gridopts.push({
                        start: s.getHours().toString().padStart(2, '0') + ":" + s.getMinutes().toString().padStart(2, '0'),
                        end: e.getHours().toString().padStart(2, '0') + ":" + e.getMinutes().toString().padStart(2, '0')
                    })
                }
            } else {
                // we need to find the correct formula group
                for (let periods of currentformulaGroup!.HCperiods!) {
                    let s = new Date(periods[0])
                    let e = new Date(periods[1])
                    gridopts.push({
                        start: s.getHours() + ":" + s.getMinutes(),
                        end: e.getHours() + ":" + e.getMinutes()
                    })
                }
            }
        }
        const params = {
            "session": {
                "chargePointID": "testCPID",
                "start": selectedDateTime!.valueOf() / 1000,//2024-12-15T10:00:00.000Z,
                "end": (selectedDateTime!.valueOf() + duration * 60 * 1000) / 1000,// 2024-12-15T12:00:00.000Z,
                "startIndex": 0,
                "endIndex": chargedPower
            },
            "cp": {
                "grid": selectedGridId,
                "gridOpts": gridopts,
                "specialDays": isSpecialDaysNeeded ? selectedSpecialDays : [],
                "fixedPrice": isFixedPriceNeeded ? {
                    "HCF": parseFloat(fixedPrices.HCF),
                    "HPF": parseFloat(fixedPrices.HPF)
                } : null
            }
        }
        const res = await services.computeMockSession(params)
        setsimualtionResult(res)
        setchartValues(computeChartValues())
        setchartAnnotations(computeAnnotation(res))
        setlastKey(Math.random())

    }
    //function returning the grid object from the id


    const computeAnnotation = (simualtionResult: any) => {
        const res: any[] = []
        if (simualtionResult.dayIndexs) {
            Object.keys(simualtionResult.dayIndexs).forEach((day: string) => {
                let dayIdxs = simualtionResult.dayIndexs[day]
                dayIdxs.periods.HC.forEach((period: any) => {
                    period.periods.forEach((elem: any) => {
                        res.push({
                            start: elem.start * 1000,
                            end: elem.end * 1000,
                            label: "HC"
                        })
                    });


                })
                dayIdxs.periods.HP.forEach((period: any) => {
                    period.periods.forEach((elem: any) => {
                        res.push({
                            start: elem.start * 1000,
                            end: elem.end * 1000,
                            label: "HP"
                        })
                    });

                })

            });

        }
        return res
    }
    const computeChartValues = () => {
        const res: ChartValue[] = []
        if (selectedDateTime && duration && chargedPower) {
            // start of hours of selectedDateTime
            let start = new Date(selectedDateTime)
            start.setMinutes(start.getMinutes() - 10)
            start.setSeconds(0)
            start.setMilliseconds(0)
            let end = new Date(selectedDateTime.valueOf() + duration * 60 * 1000)
            end.setMinutes(end.getMinutes() + 10)
            end.setSeconds(0)
            end.setMilliseconds(0)
            res.push(
                {
                    ts: start?.valueOf() / 1000 as number,
                    value: 0
                },

                {
                    ts: selectedDateTime?.valueOf() / 1000 as number,
                    value: 0
                },
                {
                    ts: (selectedDateTime?.valueOf() as number + duration * 60 * 1000) / 1000,
                    value: chargedPower
                },
                {
                    ts: end?.valueOf() / 1000 as number,
                    value: chargedPower
                }
            )
        }
        return res
    }

    const handleGridChange = (event: any) => {
        setSelectedGridId(event!.target!.value);
    };

    const handleDateTimeChange = (value: Date | null) => {
        setSelectedDateTime(value);
        setchartValues(computeChartValues())
        //setchartAnnotations(computeAnnotation())
    };

    const handleDurationChange = (event: Event, value: number | number[]) => {
        setDuration(value as number);
        setchartValues(computeChartValues())
        //setchartAnnotations(computeAnnotation())
    };

    const handleChargedPowerChange = (event: Event, value: number | number[]) => {
        setChargedPower(value as number);
        setchartValues(computeChartValues())
        //setchartAnnotations(computeAnnotation())
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onclose}
                fullScreen
                TransitionComponent={Transition}
            >
                <DialogTitle className={classes.popupTitle}>{t("costTool")}</DialogTitle>
                <DialogContent >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                        {/* Content of the LocalizationProvider */}
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={6}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography><b>{t("chargingParameters")}</b></Typography>
                                    </Grid>
                                    <Grid item xs={8}>

                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>{t("grid")}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select
                                            fullWidth
                                            id="grid" value={selectedGridId}
                                            onChange={(e) => handleGridChange(e)}>
                                            {availableGrids.map((grid) => (
                                                <option key={grid.id} value={grid.id}>
                                                    {grid.provider} - {grid.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={4}>
                                    </Grid>
                                </Grid>


                                <br />
                                <Grid container direction="row" spacing={2} className={classes.verticalCenter}>
                                    <Grid item xs={4} className={classes.verticalCenter}>
                                        <Typography>{t("start")}:</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DateTimePicker
                                            timezone='UTC'
                                            ampm={false}
                                            label="..."
                                            value={selectedDateTime}
                                            onChange={(newValue: any) => handleDateTimeChange(newValue)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container direction="row" spacing={2} className={classes.verticalCenter}>
                                    <Grid item xs={4}>
                                        <Typography>{t("duration")}:</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Slider
                                            id="duration"
                                            min={0}
                                            max={20 * 60}
                                            value={duration}
                                            onChange={handleDurationChange}
                                            valueLabelDisplay="auto"
                                        />

                                    </Grid>
                                    <Grid item xs={4}>
                                        <span>{duration} min</span>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container direction="row" spacing={2} className={classes.verticalCenter}>
                                    <Grid item xs={4}>
                                        <Typography>{t("chargedPower")}:</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Slider
                                            id="chargedPower"
                                            min={0}
                                            max={100}
                                            value={chargedPower}
                                            onChange={handleChargedPowerChange}
                                            valueLabelDisplay="auto"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span>{chargedPower} kWh</span>
                                    </Grid>
                                </Grid>
                                <br />
                                {(isHCperiodNeeded || isSpecialDaysNeeded || isFixedPriceNeeded) && (
                                    <>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={4}>
                                                <Typography><b>{t("contractOpts")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>

                                            </Grid>
                                        </Grid>
                                        <br />
                                    </>
                                )}
                                {
                                    isSpecialDaysNeeded && (
                                        <SpecialDaysSelector
                                            handleChange={(days: string[]) => setselectedSpecialDays(days)}
                                        />
                                    )
                                }
                                {
                                    isFixedPriceNeeded && (
                                        <FixedPriceSetter
                                            isDualTarif={currentGrid?.isDualTarif || false}
                                            handleChange={(tarif) => setfixedPrices(tarif)}

                                        />
                                    )
                                }
                                {
                                    isHCperiodNeeded && (
                                        <>
                                            <Typography>{t("offPeakHours")}:</Typography>
                                            <br />
                                            <PeriodEditor
                                                editMode={true}
                                                periods={hcPeriods}
                                                setPeriods={(p) => {
                                                    setHcPeriods(p)
                                                }}
                                            />
                                        </>
                                    )}
                                {!canCompute && (
                                    <Typography color="error">{
                                        !selectedGridId ? t("chooseGrid") : "" ||
                                            !selectedDateTime ?  t("chooseDate") : "" ||
                                                !duration ?  t("chooseDuration") : "" ||
                                                    !chargedPower ? t("choosePower") : "" ||
                                                        !currentformulaGroup ? t("gridConfNotfound") : ""
                                    }</Typography>
                                )
                                }
                                <Button
                                    color={"secondary"}
                                    disabled={!canCompute}
                                    variant="contained"
                                    startIcon={<CalculateIcon />}
                                    onClick={() => compute()}>
                                    {t("compute")}
                                </Button>
                                <br />
                                <br />


                            </Grid>
                            <Grid item xs={6}>
                                <Typography><b>{t("results")}:</b></Typography>
                                <LineChart
                                    key={lastKey}
                                    width={600}
                                    height={300}
                                    values={chartValues}
                                    annotations={chartAnnotations}
                                    startGauge={null}
                                    endGauge={null}

                                />
                                <br />

                                <Grid container direction="row" spacing={2} className={classes.verticalCenter}>
                                    <Grid item xs={2} className={classes.verticalCenter}>
                                        <b>Total:</b>
                                    </Grid>
                                    <Grid item xs={2} className={classes.verticalCenter}>
                                        <p>TTC: <b>{simualtionResult.TTC ? simualtionResult.TTC.toFixed(2) : "-"}</b></p>
                                    </Grid>
                                    <Grid item xs={2} className={classes.verticalCenter}>
                                        <p>TVA: <b>{simualtionResult.TVA ? simualtionResult.TVA.toFixed(2) : "-"}</b></p>
                                    </Grid>
                                </Grid>
                                {simualtionResult && simualtionResult.dayRes && simualtionResult.dayRes.map((dayResult: any) => (
                                    <div key={dayResult.day}>
                                        {simualtionResult.dayRes.length > 1 && (
                                            <>
                                                <Grid container direction='row'>
                                                    <Grid item xs={3}>
                                                        <h3>{new Date(dayResult.day * 1000).toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })}</h3>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <p>TTC: {dayResult.TTC.toFixed(2)}</p>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <p>TVA: {dayResult.TVA.toFixed(2)}</p>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid container direction='row'>
                                            <Grid item xs={3}></Grid>
                                            {
                                                currentGrid!.isDualTarif && (
                                                    <Grid item xs={2}>
                                                        HC: {dayResult.symbols.HC.toFixed(2)} kwh
                                                    </Grid>
                                                )
                                            }

                                            <Grid item xs={2}>
                                                HP: {dayResult.symbols.HP.toFixed(2)} kwh
                                            </Grid>
                                        </Grid>

                                        <p>{t("formula")}: {dayResult.formula}</p>
                                    </div>
                                ))}

                            </Grid>
                        </Grid>

                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onclose}>{t("close")}</Button>
                </DialogActions>
            </Dialog>

        </div >
    );
};

export default SimulatorPopup;