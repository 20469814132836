import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { AuthContext } from '../contexts/authContext'
import MaterialReactTable from 'material-react-table';
import type { MRT_Cell, MRT_ColumnDef, MRT_RowSelectionState } from 'material-react-table';
import {
  Download as DownloadIcon, AttachEmail as EmailIcon
} from '@mui/icons-material';

import IconButton from '@material-ui/core/IconButton'
import { User, Note, Company, CreditNote } from '../libs/types'
import { downloadNote, downloadNotes, getNoteForCompany, getNoteForUser, sendNotes } from '../service/creditNote'
import { useTranslation } from 'react-i18next'
import { getReactTableLocalisation } from '../libs/i18n'
import { CompaniesContext } from '../contexts/companyContext'
import { Button, Tooltip } from '@material-ui/core'
import toast, { Toaster } from "react-hot-toast";
import { String } from 'aws-sdk/clients/cloudsearchdomain'
import bgF2m from '../ressources/backgroundf2m.png';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { getDateAsYYYYMMDD } from '../libs/utils'
import Services from '../service/services'

interface EndUserState {
  creditNote: (Notepresentation)[]
  profil: User
}

interface Notepresentation {
  userName: string
  lastname: string
  firstname: string
  companyName: string
  companyID: String
  note: Note
}

var state: EndUserState;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  spacedTop: {
    marginTop: "30px"
  },
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },
  session: {
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  green: {
    backgroundColor: 'green',
  },
  hero: {
    width: '100%'
  },
  oneLineButton: {
    width: '350px',
    margin: 'auto',
    marginLeft: '10px',
  },

}))


const handleExportData = (t: (n: string) => string, data: Notepresentation[]) => {
  const now = new Date()
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: getDateAsYYYYMMDD(now) + "-" + t('creditNotes'),
    title: getDateAsYYYYMMDD(now) + " " + t('creditNotes')
  });

  const csvData = data.map((row) => {
    const res = {} as any
    res[t("firstName")] = row.firstname
    res[t("lastName")] = row.lastname
    res[t("sessions")] = row.note.sessions.length
    res[t("TTC")] = row.note.billing.total
    res[t("HT")] = row.note.billing.HT
    res[t('TVA')] = row.note.billing.TVA
    res[t('EditionDate')] = new Date(row.note.ts).toLocaleDateString()
    return res
  }) as unknown as { [k: string]: unknown;[k: number]: unknown; }[]
  const csv = generateCsv(csvConfig)(csvData);
  download(csvConfig)(csv);
}

export default function CreditNotesPage() {

  const classes = useStyles()
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const { t, i18n } = useTranslation();
  var [statedata, setstatedata] = useState(state);
  const companies = useContext(CompaniesContext).nonTechnicalCompanies
  const companyMap = useContext(CompaniesContext).companyMap
  const isFleetOrAdmin = auth.sessionInfo?.fleetManagerProfile || auth.sessionInfo?.isAdmin ? true : false
  const noteColumn = useMemo<MRT_ColumnDef<Notepresentation>[]>(() => noteColumnDef(t, isFleetOrAdmin, auth.sessionInfo?.isAdmin), [i18n.language])
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  useEffect(() => {
    const interval = setInterval(() => {
      getall();
    }, 1000);
    return () => clearInterval(interval);
  }, [statedata]);

  async function getall() {
    var promises = []
    switch (true) {
      case typeof auth.sessionInfo?.fleetManagerProfile !== 'undefined':
        promises.push(services.getNoteForCompany(auth.sessionInfo?.companyID!))
        break;
      case auth.sessionInfo?.isAdmin:
        companies.forEach(company => {
          promises.push(services.getNoteForCompany(company.id))
        })
        break;
      default://endUser
        promises.push(services.getNoteForUser(auth.User))
        break;
    }
    let creditNotesResults = (await Promise.all(promises)).flat();
    var notes: Notepresentation[] = []
    creditNotesResults.forEach(userNote => {
      if (userNote && userNote.notes) {
        userNote.notes.forEach(note => {
          notes.push({
            lastname: userNote.lastName,
            firstname: userNote.firstName,
            userName: userNote.userName,
            companyName: companyMap.get(userNote.companyID)?.name || " - ",
            companyID: userNote.companyID,
            note
          })
        })
      }
    })
    setstatedata(
      {
        profil: auth.User as User,
        creditNote: notes
      }
    )
  }
  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="column" >
          <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
            <Toaster />
            <MaterialReactTable
              enableRowActions={isFleetOrAdmin}
              enableGlobalFilter={isFleetOrAdmin}
              columns={noteColumn}
              initialState={{
                density: "compact",
                sorting: [
                  {
                    id: 'note.ts',
                    desc: true,
                  }
                ],
              }}

              data={statedata && statedata.creditNote && statedata.creditNote ? statedata.creditNote : []}
              state={{ isLoading: statedata ? false : true, rowSelection }}
              renderRowActions={({ row }) => (
                auth.sessionInfo?.isAdmin ? (
                  <Box sx={{ height: '15px', width: '15px', display: statedata.creditNote[row.index].note.file ? 'flex' : 'none', flexWrap: 'nowrap' }}>
                    <IconButton onClick={() => downloadNote(statedata.creditNote[row.index].companyID, statedata.creditNote[row.index].userName, statedata.creditNote[row.index].note.file)}>
                      <DownloadIcon />
                    </IconButton>
                  </Box>) : null
              )}
              enableRowSelection={(row) => {return (row.original.note != null && row.original.note.file!=null && row.original.note.file!=undefined && row.original.note.file.length>0)}}
              onRowSelectionChange={setRowSelection}
              renderTopToolbarCustomActions={() => {
                const creditNote: any[] = []
                for (let idx in rowSelection) {
                  if (rowSelection[idx]) {
                    creditNote.push({ key: statedata.creditNote[Number(idx)].note.file, id: statedata.creditNote[Number(idx)].note.id, companyID: statedata.creditNote[Number(idx)].companyID, userName: statedata.creditNote[Number(idx)].userName })
                  }
                }
                return (
                  <>
                    <Grid container direction='row'>
                      <Grid item xs={3}>
                        <Button color={creditNote.length > 0 ? "secondary" : "default"} className={classes.oneLineButton} variant="contained" startIcon={<EmailIcon />}
                          onClick={async () => {
                            if (creditNote.length == 0) {
                              toast.error(t("noteSelectionRequired"))
                              return
                            }
                            await sendNotes(creditNote)
                            toast.success(t("notesent"))
                            setRowSelection({})
                          }}>{t("sendcreditNotesToManager")}</Button>
                      </Grid>
                        {!isFleetOrAdmin ? (
                          <Grid item xs={3}>
                          <Button color={creditNote.length > 0 ? "secondary" : "default"} className={classes.oneLineButton} variant="contained" startIcon={<DownloadIcon />}
                            onClick={async () => {
                              if (creditNote.length == 0) {
                                toast.error(t("noteSelectionRequired"))
                                return
                              }
                              const filesIDs = []
                              for (let note of creditNote) {
                                filesIDs.push(note.key)
                              }
                              try {
                                await downloadNotes(auth.User?.companyID, auth.User?.userName, filesIDs)
                                toast.success(t("notesDownloaded"))
                              } catch {
                                toast.error(t("errorDownloadingNotes"))
                              }
                              setRowSelection({})
                            }}>{t("creditNotes")}</Button></Grid>) :
                          null
                        }
                         <Grid item xs={1}>
                        <Tooltip title={t("csvExport")}>
                          <IconButton onClick={(e) => { handleExportData(t, statedata && statedata.creditNote && statedata.creditNote ? statedata.creditNote : []) }}>
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        </Grid>
                        <Grid item xs={5}></Grid>
                      </Grid>
                    

                  </>
                )
              }
              }

              enableColumnOrdering={false}
              enableHiding={false}
              enableDensityToggle={false}
              localization={getReactTableLocalisation()}
            />
          </Box >
        </Grid>
      </Grid>
    </Grid>
  )
}


const noteColumnDef = (t: (s: string) => string, withName: boolean = false, withCompany: boolean = false): MRT_ColumnDef<Notepresentation>[] => {
  var columns: MRT_ColumnDef<Notepresentation>[] = []
  if (withCompany) {
    columns.push({
      accessorKey: 'companyName',
      header: 'companyName',
      accessorFn: (row) => { return row.companyName },
      Cell: (cell) => formatCell(t, cell.cell)
    })
  }
  if (withName) {
    columns.push({
      accessorKey: 'lastname',
      header: 'Utilisateur',
      accessorFn: (row) => { return row.lastname ? row.lastname + " " + row.firstname : " - " },
      Cell: (cell) => formatCell(t, cell.cell)
    })
  }

  return [...columns,
  {
    accessorKey: 'note.ts',
    header: t('EditionDate'),
    //accessorFn: (row) => { return new Date(row.note.ts).toLocaleDateString() },
    Cell: (cell) => formatCell(t, cell.cell),
    sortingFn: 'datetime'
  },
  {
    accessorKey: 'note.sessions',
    header: t('sessionNumber'),
    accessorFn: (row) => { return row.note && row.note.sessions ? row.note.sessions.length : " - " },
    Cell: (cell) => formatCell(t, cell.cell)
  },
  {
    accessorKey: 'note.billing.total',
    header: t('TTC'),
    Cell: (cell) => formatCell(t, cell.cell)
  },
  {
    accessorKey: 'note.billing.HT',
    header: t('HT'),
    Cell: (cell) => formatCell(t, cell.cell)
  },
  {
    accessorKey: 'note.billing.TVA',
    header: t('VAT'),
    Cell: (cell) => formatCell(t, cell.cell)
  }
  ]
}

const formatCell = (t: (s: string) => string, cell: MRT_Cell<Notepresentation>, translatable: boolean = false): ReactNode => {
  return (
    <Box
      component="span"
    >
      {cell.column.columnDef.accessorKey == "note.ts" ? new Date(cell.row.original.note.ts).toLocaleDateString() : cell.getValue<String>()}
    </Box>
  )
}

