

import axios from 'axios'
import fileDownload from 'js-file-download'
import { BASE_URL } from './conf'
import { CreditNote, Note, User } from '../libs/types'



export async function generateNote(authToken: string, user: User, sessionIds: string[], start: Date, end: Date | null) : Promise<void> {
  if (!end) {
    end = new Date()
  }
  const body = {
    start: String(Math.floor(new Date(start).getTime() / 1000)),
    end: String(Math.floor(new Date(end).getTime() / 1000)),
    sessionIds
  }
  return new Promise(function (resolve, reject) {
    const params = new URLSearchParams([]);
    axios.post(BASE_URL + `/creditNote/${user.companyID}/user/${user.userName}`, body, {
      params,
      responseType: 'json',
      headers: {
        Authorization: authToken
      }
    })
      .then((res: any) => {
        if (!res.data) {
          resolve()
        }
        resolve(res.data)
      }).catch(e => reject(e))
  })
}

export async function getNoteForCompany(authToken: string, companyID: string): Promise<CreditNote[]> {
  return new Promise(function (resolve, reject) {
    const params = new URLSearchParams([]);
    axios.get(BASE_URL + `/creditNote/${companyID}`, {
      params,
      responseType: 'json',
      headers: {
        Authorization: authToken
      }
    })
      .then((res: any) => {
        if (!res.data || res.data.length == 0) {
          resolve([])
        }
        var notes: CreditNote[] = []
        res.data.forEach((n: CreditNote) => notes.push(n))
        resolve(notes)

      }).catch(e => reject(e))
  })
}


export async function getNoteForUser(authToken: string, user: User): Promise<CreditNote[] | null> {
  return new Promise(function (resolve, reject) {
    const params = new URLSearchParams([]);
    axios.get(BASE_URL + `/creditNote/${user.companyID}/user/${user.userName}`, {
      params,
      responseType: 'json',
      headers: {
        Authorization: authToken
      }
    }).then((res: any) => {
      if (!res.data) {
        resolve(null)
      }
      resolve(res.data)
    }).catch(e => reject(e))
  })
}
export async function downloadNotes(companyID: string, username: string, filedIDs: string[]): Promise<null> {
  return new Promise(function (resolve, reject) {
    const params = new URLSearchParams([]);
    let idslist =""
    for ( let file of filedIDs){
      idslist+=`"${file}"`
      if (filedIDs.indexOf(file) != filedIDs.length -1){
        idslist+=(",")
      }
    }
    axios.get(BASE_URL+ `/file/${companyID}/user/${username}/files?keys=[${idslist}]`, {
      headers: {
        Accept: 'application/pdf',
        // ContentType :'applSication/pdf',
      },
      responseType: 'blob',
    })

      .then((res: any) => {
        const fileName = res.headers['content-disposition'] ? res.headers['content-disposition'].split('filename=')[1] : "note.pdf"
        fileDownload(res.data, fileName.replace(/"/g, ''))
        resolve(null)
      })
      .catch(e => reject(e))
  })

}

export async function downloadNote(companyID: string, username: string, filedID: string): Promise<null> {
  return new Promise(function (resolve, reject) {
    const params = new URLSearchParams([]);
    axios.get(BASE_URL + `/creditNote/${companyID}/user/${username}/file/${filedID}`, {
      headers: {
        Accept: 'application/pdf',
        // ContentType :'applSication/pdf',
      },
      responseType: 'blob',
    })

      .then((res: any) => {
        const fileName = res.headers['content-disposition'] ? res.headers['content-disposition'].split('filename=')[1] : "note.pdf"
        fileDownload(res.data, fileName.replace(/"/g, ''))
        resolve(null)
      })
      .catch(e => reject(e))
  })

}

export async function sendNotes(notes: any[]): Promise<void> {
  return new Promise(function (resolve, reject) {
    const params = new URLSearchParams([]);
    const body = {
      notes
    }
    axios.post(BASE_URL + `/mail/sendcreditNotes`, body, {
      responseType: 'json',
    })

      .then((res: any) => {
        resolve()
      })
      .catch(e => reject(e))
  })

}

