import { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Download as DownloadIcon } from '@mui/icons-material';
import { Car, ChargePoint, Company, CreditNote, Note, Session, Tag, User } from '../libs/types'
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef } from 'material-react-table';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, TextField, Tooltip } from '@material-ui/core'
import { Stack } from '@mui/material'
import { CompaniesContext } from '../contexts/companyContext'
import { getReactTableLocalisation } from '../libs/i18n'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/authContext'
import bgF2m from '../ressources/backgroundf2m.png';
import Services from '../service/services'
import { TagContext } from '../contexts/TagContext'
import TagSelector from '../components/tags/tagSelector'
import { UsersContext } from '../contexts/userContext'
import React from 'react'
import { ChargePointContext } from '../contexts/cpContext'
import { DeviceContext } from '../contexts/deviceContext'
import { noteMapFromCreditNoteArray, tsToDate } from '../libs/utils'
import { downloadNote } from '../service/creditNote'


interface SessionRepresentation {
  session: Session
  cp: ChargePoint | null
  User: User
  company: Company
}


interface state {
  sessions: SessionRepresentation[],
  NoteMap: Map<string, Note>
}

interface noteRepresentation {
  note: Note
  companyID: string
  userName: string

}
//mock data - strongly typed if you are using TypeScript (optional, but recommended)
var data: state;
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
  },
  spaced: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  spacedTop: {
    marginTop: "30px"
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vvw',
    backgroundColor: 'white',
    border: '2px solid #000',
  }
}))

//this is only visible for admin users
export default function CompanySessionPage() {

  const classes = useStyles()
  var [statedata, setstatedata] = useState(data);
  const companyContext = useContext(CompaniesContext)
  const cpContext = useContext(ChargePointContext)
  const deviceContext = useContext(DeviceContext)
  const userContext = useContext(UsersContext)
  const companies = companyContext.nonTechnicalCompanies
  const companyMap = companyContext.companyMap
  const cpMap = cpContext.cpMap
  const deviceMap = deviceContext.deviceMap
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext)
  const userMap = userContext.getMap()
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const isFleetManager = auth.sessionInfo?.fleetManagerProfile ? true : false
  const CarColumns = useMemo<MRT_ColumnDef<SessionRepresentation>[]>(() => columnFactory(t, statedata ? statedata.NoteMap : new Map<string, Note>(), isFleetManager), [i18n.language, statedata])

  var refresh = async () => {


    //retrieve all notes for companies
    const notePromises: Promise<CreditNote[]>[] = []
    for (var company of companies) {//if admin === all companies
      notePromises.push(services.getNoteForCompany(company.id))
    }

    const cn = (await Promise.all(notePromises)).flat()




    var d: SessionRepresentation[] = []
    const promises: Promise<Session[]>[] = []
    for (var company of companies) {//if admin === all companies
      promises.push(services.getSessionForCompany(company.id, null, null, false))
    }
   

    await Promise.all(promises).then(async (companySession) => {
      companySession.forEach(sessions => {
        sessions.forEach(session => {
          var cp = cpMap.get(session.chargePointID)
          var device = deviceMap.get(session.deviceID!)
          var user = userMap.get(device?.user!)
          d.push({
            session: session,
            cp: cp || null,
            User: user!,
            company: companyMap.get(user?.companyID!)!
          })
        })

      })
    })
    setstatedata({
      sessions: d,
      NoteMap: noteMapFromCreditNoteArray(cn)
    })
  }

  useEffect(() => {
    refresh()
  }, [userContext.users, cpContext.chargepoints, deviceContext.devices, companyContext.companies])
  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="column" >
          <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
            <MaterialReactTable
              enableRowActions={false}
              columns={CarColumns}
              state={{ isLoading: statedata ? false : true, density: "compact" }}
              data={statedata ? statedata.sessions : []}
              enableRowSelection={false} //enable some features
              enableColumnOrdering={false}
              enableHiding={false}

              enableDensityToggle={false}
              enableGlobalFilter={true}
              enableTopToolbar={true}
              renderDetailPanel={row => {
                /* */
                return (<></>)
              }}
              initialState={{
                density: "compact",
                sorting: [
                  {
                    id: 'start',
                    desc: true,
                  },
                  {
                    id: 'status',
                    desc: true,
                  },
                ],
              }}
              localization={getReactTableLocalisation()}
            />
            <br />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}


const columnFactory = (t: (s: string) => string, noteMap: Map<string, Note>, isFleetManager: boolean): MRT_ColumnDef<SessionRepresentation>[] => {

  const columns: MRT_ColumnDef<SessionRepresentation>[] = []
  if (!isFleetManager) {
    columns.push({
      accessorKey: 'company.name',
      header: t('client'),
      enableEditing: false
    })
  }

  columns.push(...[
    {
      accessorKey: 'session.user',
      header: 'user',
      enableEditing: false,
      accessorFn: (row) => {
        return row.User ? row.User.firstName + " " + row.User.lastName : " - "
      },
    },
    {
      accessorKey: 'session.carDetails',
      header: 'car',
      enableEditing: false,
      accessorFn: (row) => {
        return row.session && row.session.carDetails && row.session.carDetails.PlateNumber ? row.session.carDetails.PlateNumber : " - "
      },
    },
    {
      accessorKey: 'start',
      header: t('start'),
      accessorFn: (row) => row.session && row.session.start ? tsToDate(row.session.start).toLocaleString(["fr"], { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) : " - ",
      sortingFn: (rowA: any, rowB: any, id): number => {
        const a = rowA.original.session.start;
        const b = rowB.original.session.start;
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
      minSize: 50,
      size: 50,
      maxSize: 80
    },
    {
      accessorKey: 'end',
      header: t('end'),
      accessorFn: (row) => row.session && row.session.end ? tsToDate(row.session.end).toLocaleString([], { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) : " - ",

      sortingFn: (rowA: any, rowB: any, id): number => {
        const a = rowA.original.session.end;
        const b = rowB.original.session.end;
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      },
      minSize: 50,
      maxSize: 50,
      size: 50,
    },
    {
      accessorKey: 'consumedPower.total',
      header: 'kwh',
      accessorFn: (row) => row.session && row.session.consumedPower && row.session.consumedPower.total ? Math.round(row.session.consumedPower.total * 10) / 10 : "- ",
      minSize: 30,
      size: 30,
      maxSize: 30,
    },
    {
      accessorKey: 'creditNote',
      header: t('creditNote'),
      accessorFn: (row) => {
        return row.session && row.session.creditNote && row.session.creditNote.id && row.User ?
          {
            note: noteMap.get(row.session.creditNote.id),
            userName: row.User.userName,
            companyID: row.User.companyID

          } as noteRepresentation : null
        /* const date = row.session && row.session.creditNote?.ts ? new Date(row.session.creditNote.ts) : null;
         return date ? date.toLocaleDateString() : " - ";*/
      },
      minSize: 50,
      maxSize: 50,
      size: 50,
      Cell: (cell) => {
        if (!cell.cell.getValue()) {
          return "-"
        }
        const n = cell.cell.getValue<noteRepresentation>()
        if (!n.note) {
          return "-"
        }
        const date = new Date(n.note.ts).toLocaleString([], { month: 'numeric', day: 'numeric'})

        return <Button variant="contained" color="primary" onClick={() => downloadNote(n.companyID, n.userName, n.note.file)} startIcon={<DownloadIcon />} component="span">
          {date}
        </Button>

        /*
        <Tooltip title="Justificatif">
          <IconButton 
            
            onClick={() => downloadNote(n.companyID, n.userName, n.note.file)}>
            <DownloadIcon />
            {date}
          </IconButton>
        </Tooltip>
*/

      }
    },

    /*
     {
       accessorKey: 'car.model',
       header: t('model'),
       accessorFn: (row) => {
         return row.car ? row.car.brand + " " + row.car.model : "-"
       },
     },
     {
       accessorKey: 'car.user',
       header: t('user'),
       accessorFn: (row) => {
         return row.car && row.car.user ? userMap.get(row.car.user)?.firstName + " " + userMap.get(row.car.user)?.lastName : " - "
       }
     },
     {
       accessorKey: 'car.status',
       header: t('carStatus'),
       accessorFn: (row) => {
         return row.car && row.car.status ? t(row.car.status) : t("active")
       }
     },
     {
       accessorKey: 'car.tags',
       header: t('entityTags'),
       accessorFn: (row) => {
         return row.car && row.car.tags ? row.car.tags.map(tag => tag.label).join(",") : " - "
       },
     },
     {
       accessorKey: 'car.lastCharge',
       header: t('lastSession'),
       enableEditing: false,
       accessorFn: (row) => {
         return row.car && row.car.lastCharge ? row.car.lastCharge.toLocaleString() : " - "
       },
     },
 */
  ] as MRT_ColumnDef<SessionRepresentation>[])
  return columns

}
