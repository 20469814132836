import { DialogTitle, DialogContent, Select, DialogActions, Button, TextField, Dialog, MenuItem, makeStyles } from "@material-ui/core";
import { Stack } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import {  DeviceTypeLabels, FirmWare } from "../../libs/types";
import { createFw, uploadFwToS3 } from "../../service/firmwares";
import { FileUpload } from "@mui/icons-material";

interface CreateModalProps {
    onClose: () => void;
    onSubmit: () => void;
    open: boolean;
}



const useStyles = makeStyles((theme) => ({

    spaced: {
        marginLeft: "15px",
        marginRight: "15px"
    },
    popupTitle: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "1.8rem!important"
    }
}))


//example of creating a mui dialog modal for creating new rows

export const CreateFwModal = ({
    open,
    onClose,
    onSubmit,
}: CreateModalProps) => {

    const [selectedType, setselectedType] = useState(DeviceTypeLabels[0]);
    const [name, setName] = useState('');
    const classes = useStyles()
    var [isLoading, setIsLoading] = useState(false);
    var [newFw, setNewFw] = useState(null as unknown as FirmWare);
    const
        handleSubmit = () => {
            // onSubmit(values);
            onSubmit();
        };

    return (
        <Dialog open={open}>
            <DialogTitle /*textAlign="center"*/>{t('createFirmware')}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedType}
                            label="Select Type"
                            onChange={(e) => {
                                setselectedType(e.target.value as string)
                            }}
                        >
                            {DeviceTypeLabels.map(label =>
                                <MenuItem value={label}>{t(label)}</MenuItem>)
                            }
                        </Select>
                        <TextField
                            label={t('name')}
                            name={t('name')}
                            onChange={(e) =>
                                setName(e.target.value as string)
                            }
                            value={name}
                        />
                        <br />
                        <input
                             disabled={name.length == 0}
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={async (e) => {
                                if (e.target.files) {
                                    try {
                                        setIsLoading(true)
                                        let fw = newFw
                                        if (!newFw) {
                                            fw = await createFw(selectedType, name)
                                        }
                                        await uploadFwToS3({ file: e.target.files[0], fw })
                                        setNewFw(fw)
                                        setIsLoading(false)
                                        // onSucess()
                                    }
                                    catch (e) {
                                        //toast.error("error uploading document")
                                        console.log(e)
                                    }
                                }
                                return null
                            }}
                        />
                        <label htmlFor="raised-button-file">
                            <Button  disabled={name.length == 0} variant="contained" color="primary" style={{ display: isLoading ? "none" : "inline-flex" }} className={classes.spaced} startIcon={<FileUpload />} component="span">
                                {t("firmwareUploadButton")}
                            </Button>
                        </label>
                        <span className="loader" style={{ width: '50px', display: isLoading ? "block" : "none" }}></span>


                    </Stack>
                </form>
            </DialogContent>
            <DialogActions /*sx={{ p: '1.25rem' }}*/>
                <Button onClick={onClose}>{t("cancel")}</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    {t('confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );

};
