import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@mui/material/Typography';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import Paper from '@material-ui/core/Paper'
import { useValidPassword } from '../../hooks/useAuthHooks'
import { Password, Username } from '../../components/authComponents'
import { AuthContext } from '../../contexts/authContext'
import TextField from '@material-ui/core/TextField'
import bgF2m from '../../ressources/backgroundf2m.png';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { theme } from '../../libs/theme'
import { useTranslation } from 'react-i18next'
import frFlag from '../../locales/fr/flag.png';
import enFlag from '../../locales/en/flag.png';

const useStyles = makeStyles({
  root: {
    overflow: 'auto',
    height: '100vh',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '2.0rem!important',
  },
})

export default function CreatePassword() {


  const classes = useStyles()
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)
  const { t, i18n } = useTranslation();
  const userName = new URLSearchParams(useLocation().search).get("userName")

  
  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid,
  } = useValidPassword('')
  const [oldPassword, setOldPassword] = useState('')

  const [login, setLogin] = useState(userName)
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const confirmPasswordIsvalid =  confirmNewPassword == newPassword
  const isValid = !confirmPasswordIsvalid  || !newPasswordIsValid || newPassword.length === 0
  const history = useHistory()
  const authContext = useContext(AuthContext)

  const changePassword = async () => {
    try {
      await authContext.createPassword(login, oldPassword, newPassword)
      setError("")
      setReset(true)
    } catch (err: any) {
      setError(err.message)
    }
  }
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const signOut = async () => {
    try {
      await authContext.signOut()
      history.push('/')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Box    m={1}>
        <TextField
          value={userName || ''}
          fullWidth
          variant="outlined"
          label={'login'}
          //error={!isValidID}
          onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
            setLogin(evt.target.value)
          }}
        />
      </Box>
      <Box m={1}>
        <Password
          label="tempPassword"
          errorLabel='mandatory'
          passwordIsValid={true}
          setPassword={setOldPassword} />
      </Box>
      <Box m={1}>
        <Password
          label="newPassword"
          errorLabel='passwordPolicy'
          passwordIsValid={newPasswordIsValid}
          setPassword={setNewPassword} />

      </Box>
      <Box  m={1}>
        <Password
          label="passwordConfirm"
          errorLabel='passworddontMatch'
          passwordIsValid={confirmPasswordIsvalid}
          setPassword={setConfirmNewPassword} />
      </Box>
      <Box  m={1}>
      </Box>
      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => history.goBack()} color="secondary" variant="contained">
              {t("cancel")}
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="contained" onClick={changePassword}>
            {t("create")}
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const passWordCreationConfirm = (
    <>
      <Typography variant="h5">{t('successPasswordCreate')}</Typography>
      <Box>
        <br/>
        <Button onClick={signOut} color="primary" variant="contained">
          {t('login')}
        </Button>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Box sx={{
        display: 'inline-flex',
        top: '20px',
        position: 'absolute',
        color: 'white'
      }}>
        <ElectricCarIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/#/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          HAYLECTRIC
        </Typography>
      </Box>
      <Grid xs={12} sm={6} lg={5} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 32 }}>
          <Grid container direction="row" justify="center" >
            <Grid item xs={1} alignItems="center">

            </Grid>
            <Grid item xs={8} alignItems="center">
              <Typography className={classes.title}>{t("createPassword")}</Typography>
            </Grid>
            <Grid item xs={2} style={{ right: '10px' }}>
              <IconButton onClick={e => { changeLanguage('fr') }} sx={{ p: 0 }}>
                <Avatar alt="Francais" src={frFlag} sx={{ height: '30px', width: '30px' }} />
              </IconButton>
              <IconButton onClick={e => { changeLanguage('en') }} sx={{ p: 1 }}>
                <Avatar alt="English" src={enFlag} sx={{ height: '30px', width: '30px' }} />
              </IconButton>
            </Grid>
            <Grid item xs={1} alignItems="center">
            </Grid>
            <Grid item xs={8} alignItems="center">
              <br />
              <Typography color="error">
                {error}
              </Typography>
              <br />
            </Grid>
            <Grid item xs={3} style={{ right: '10px' }}>
            </Grid>
            <Grid item xs={1} ></Grid>
            <Grid item xs={10} >{!reset ? updatePassword : passWordCreationConfirm}</Grid>
            
            
            
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
