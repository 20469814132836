import React, { useState, useEffect, useContext } from 'react'
import { Car, Grid, Tag } from '../libs/types';
import { AuthContext } from './authContext'
import Services from '../service/services';
import { CompaniesContext } from './companyContext';

export interface ICar {
  cars: Car[]
  refresh: () => void
}

const defaultState: ICar = {
  cars: [],
  refresh: () => { }
}

type Props = {
  children?: React.ReactNode
}

export var CarContext = React.createContext(defaultState)

const CarProvider = ({ children }: Props) => {
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const [carData, setCarData] = useState(defaultState.cars)
  const companies = useContext(CompaniesContext).nonTechnicalCompanies;
  const state: ICar = {
    cars: carData,
    refresh: async () => {
      setCarData(await fetch())
    }
  }

  const fetch = async (): Promise<Car[]> => {
    try {
      const cars: Car[] = []
      for (let company of companies) {
        cars.push(...await services.getAllCarsCompany(company.id))
      }
      //cars.sort((a, b) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name))
      return cars
    } catch (err) {
      console.log(err)
      return []
    }

  }
  useEffect(() => {
    async function fetchData() {
      setCarData(await fetch())
    }
    fetchData()
  }, [companies])
  return <CarContext.Provider value={state}>{children}</CarContext.Provider>
}

export default CarProvider
