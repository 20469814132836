import { Grid, Typography, FormControlLabel, makeStyles } from "@material-ui/core"
import { useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    spaced: {
        marginLeft: "15px",
        marginRight: "15px"
    },
    popupTitle: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "1.8rem!important"
    },
    verticalCenter: {
        marginTop: 'auto',
        marginBottom: 'auto'
    },
}))



interface Props {
    handleChange: (days: string[]) => void
}


const SpecialDaysSelector: React.FC<Props> = ({ handleChange }) => {

    const { t } = useTranslation();
    const [selectedSpecialDays, setselectedSpecialDays] = useState([] as string[]);
    const classes = useStyles()

    const handleSpecialDaysChange = (day: string, event: any) => {
        const sd = JSON.parse(JSON.stringify(selectedSpecialDays))
        if (sd.includes(day) && !event.target.checked) {
            sd.splice(sd.indexOf(day), 1)
        }
        if (!sd.includes(day) && event.target.checked) {
            sd.push(day)
        }
        setselectedSpecialDays(sd)
        handleChange(sd)

    }

    return (

        <Grid container direction="row" spacing={2} className={classes.verticalCenter}>
            <Grid item xs={4} className={classes.verticalCenter}>
                <Typography>Jours spéciaux:</Typography>
            </Grid>
            {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
                <Grid item xs={1}>
                    <FormControlLabel control={
                        <Checkbox
                            key={day}
                            checked={selectedSpecialDays.includes(day)}
                            onChange={(e) => {
                                handleSpecialDaysChange(day, e)
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />} label={t(day)} />

                </Grid>
            ))
            }
        </Grid>
    )

}

export default SpecialDaysSelector;