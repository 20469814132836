import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import frFlag from '../locales/fr/flag.png';
import enFlag from '../locales/en/flag.png';
import frFlagGrey from '../locales/fr/flag_grey.png';
import enFlagGrey from '../locales/en/flag_grey.png';
import f2mlogo from '../ressources/f2m_logo.png'
import avatar from '../ressources/avatar.png';
import { AuthContext } from '../contexts/authContext'
import { useTranslation } from 'react-i18next';

interface Page {
    label: string
    target: string
    isSelected: boolean
}
export const usersPage: Page = {
    label: 'users',
    target: '/users',
    isSelected: false
}

export const clientsPage: Page = {
    label: 'clients',
    target: '/companies',
    isSelected: false
}

export const chargePointsPage: Page = {
    label: 'chargePoints',
    target: '/chargePoints',
    isSelected: false
}

export const carsPage: Page = {
    label: 'cars',
    target: '/cars',
    isSelected: false
}
export const creditNotesPage: Page = {
    label: 'creditNotes',
    target: '/creditNotes',
    isSelected: false
}
export const SessionsPage: Page = {
    label: 'Sessions',
    target: '/sessions',
    isSelected: false
}

export const CompanySessionsPage: Page = {
    label: 'companySessions',
    target: '/CompanySessions',
    isSelected: false
}
export const ArchivedPage: Page = {
    label: 'archived',
    target: '/archived',
    isSelected: false
}

export const gridsPage: Page = {
    label: 'grids',
    target: '/grids',
    isSelected: false
}

export const metricsPage: Page = {
    label: 'metrics',
    target: '/metrics',
    isSelected: false
}
export const devicesPage: Page = {
    label: 'devices',
    target: '/devices',
    isSelected: false
}
export const accountPage: Page = {
    label: 'myAccount',
    target: '/account',
    isSelected: false
}

export const tagPage: Page = {
    label: 'tags',
    target: '/tags',
    isSelected: false
}

var pages: Page[];
const settings = ['myAccount', 'logout'];

function ResponsiveAppBar({ landingPage }: {
    landingPage: Page
}) {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const auth = useContext(AuthContext)
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        if (!auth.sessionInfo) {
            return
        }
        if (auth.sessionInfo && auth.sessionInfo.language) {
            changeLanguage(auth.sessionInfo.language)
        }

    }, [auth.sessionInfo])

    var [pagesData, setpages] = useState(pages);

    const history = useHistory()

    const handleCloseUserMenu = async (setting: string) => {
        switch (setting) {
            case 'logout':
                await auth.signOut()
                history.push('/')
                window.location.reload();
                break;
            case 'myAccount':
                history.push('/account')
                break;
        }
        setAnchorElUser(null);
    };

    const handleCloseNavMenu = async (selectedPage: Page) => {
        var newPages = JSON.parse(JSON.stringify(pagesData))
        for (const page of newPages) {
            if (page.label == selectedPage.label) {
                page.isSelected = true
            } else {
                page.isSelected = false
            }
        }
        await setpages(newPages)
        setAnchorElNav(null);
        history.push(selectedPage.target)
    };
    if (!pagesData && auth.sessionInfo) {

        landingPage.isSelected = true
        if (auth.sessionInfo.isAdmin) {
            setpages([usersPage, clientsPage, carsPage, CompanySessionsPage, chargePointsPage, creditNotesPage, tagPage, metricsPage, gridsPage, devicesPage])
        }
        if (auth.sessionInfo.isEndUser) {
            setpages([SessionsPage, creditNotesPage, ArchivedPage])
        }
        if (auth.sessionInfo.fleetManagerProfile) {
            setpages([usersPage, carsPage, CompanySessionsPage, chargePointsPage, creditNotesPage, tagPage, metricsPage, devicesPage, gridsPage])
        }
    }
    //{/*"dev" ? "green" : "#1976d2" }}>*/}

    return (
        <AppBar position="static" sx={{ backgroundColor: process.env.REACT_APP_STAGE == "dev" ? "#1e0e3f" : "#1e0e3f" }} >

            <Container style={{ maxWidth: "95%" }}>
                <Toolbar disableGutters >
                    {/*<ElectricCarIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/""}
                    <img
                        src={f2mlogo}
                        height={'30px'}
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/#/"
                        sx={{
                            ml: 2,
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 600,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        HAYLECTRIC
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pagesData && pagesData.map((page) => (
                                <MenuItem key={page.label} onClick={() => handleCloseNavMenu(page)}  >
                                    <Typography id={page.label} textAlign="center">{t(page.label)}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <ElectricCarIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 400,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LOGO
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pagesData && pagesData.map((page) => (
                            <Button
                                id={page.label}
                                key={page.label}
                                onClick={() => handleCloseNavMenu(page)}
                                sx={{ mx: 1, my: 2, color: page.isSelected ? '"#1e0e3f"' : 'white', backgroundColor: page.isSelected ? 'white' : '#1e0e3f"', hover: "white", display: 'block', fontWeight: 600 }}
                            >
                                {t(page.label)}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <> <span id="languageControl">
                            <IconButton onClick={e => { changeLanguage('fr') }} sx={{ p: 0 }}>
                                <Avatar alt="Francais" src={i18n.language == 'fr' ? frFlagGrey : frFlag} sx={{ height: '30px', width: '30px' }} />
                            </IconButton>
                            <IconButton onClick={e => { changeLanguage('en') }} sx={{ p: 1 }}>
                                <Avatar alt="English" src={i18n.language == 'en' ? enFlagGrey : enFlag} sx={{ height: '30px', width: '30px' }} />
                            </IconButton>
                        </span>
                        </>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                                <Avatar alt="Remy Sharp" src={avatar} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                    <Typography textAlign="center">{t(setting)}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;