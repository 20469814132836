import { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Company, CompanyStats, UserStats } from '../libs/types'
import { CompaniesContext } from '../contexts/companyContext'
import { useTranslation } from 'react-i18next'
import { getCompanyStats } from '../service/stats'
import { Grid, Box, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Card, InputLabel, MenuItem, Select, IconButton, Button, Tooltip } from '@material-ui/core'
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { getReactTableLocalisation } from '../libs/i18n'
import bgF2m from '../ressources/backgroundf2m.png';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker'
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider'
import { mkConfig, generateCsv, download } from "export-to-csv";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Download as DownloadIcon } from '@mui/icons-material';
import { getDateAsYYYYMMDD } from '../libs/utils'
import { AuthContext } from '../contexts/authContext'
import Services from '../service/services'


const WEEK_PERIOD = "week"
const MONTH_PERIOD = "month"
const YEAR_PERIOD = "year"

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px"
  },
  spacedRight: {
    marginRight: "15px"
  },
  spacedTop: {
    marginTop: "30px"
  },
  spacedBottom: {
    marginBottom: "30px"
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  content: {
    backgroundColor: 'white',
  },
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },
  session: {
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  green: {
    backgroundColor: 'green',
  },
  hero: {
    width: '100%'
  }
}))


var computeDates = (period: string): [Date, Date] => {
  var minus = 0;

  switch (period) {
    case WEEK_PERIOD:
      minus = 7
      break;
    case MONTH_PERIOD:
      minus = 30
      break;
    case YEAR_PERIOD:
      minus = 365
      break;
  }
  var s = new Date()
  s.setDate(s.getDate() - minus)
  var e = new Date()
  return [s, e]
}




const handleExportData = (t: (n: string) => string, start: Date, end: Date, data: UserStats[]) => {
  const now = new Date()
  const s = now.getFullYear() + "-" + (("" + (now.getMonth() + 1)).padStart(2, '0')) + "-" + ("" + now.getDate()).padStart(2, '0')

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: getDateAsYYYYMMDD(start) + "_" + getDateAsYYYYMMDD(end) + t('metrics'),
    title: getDateAsYYYYMMDD(start) + " -> " + getDateAsYYYYMMDD(end) + t('metrics')

  });
  const csvData = data.map((row) => {
    const res = {} as any
    res[t("firstName")] = row.firstName
    res[t("lastName")] = row.lastName
    res[t("sessions")] = row.sessionNb
    res[t("chargedPower")] = row.totalPower
    res[t("totalAmount")] = row.total
    res[t('creditNotes')] = row.creditNoteNb
    res[t("entityTags")] = row.tags ? row.tags.map(tag => tag.label).join(",") : ""
    return res
  }


  ) as unknown as { [k: string]: unknown;[k: number]: unknown; }[]
  const csv = generateCsv(csvConfig)(csvData);
  download(csvConfig)(csv);

};

export default function Metrics() {
  const { t, i18n } = useTranslation();
  const companies = useContext(CompaniesContext).nonTechnicalCompanies
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const classes = useStyles()
  const [selectedPeriod, setSelectedPeriod] = useState(YEAR_PERIOD)
  const [stats, setStats] = useState(new Map<string, CompanyStats>())
  const [selectedCompany, setselectedCompany] = useState("")
  const userStatColumns = useMemo<MRT_ColumnDef<UserStats>[]>(() => userStatColumnDef(t), [i18n.language])
  const [end, setEnd] = useState(new Date())//year
  const s = new Date()
  s.setDate(end.getDate() - 365)
  const [start, setStart] = useState(s)
  const [isFetching, setIsFetching] = useState(false)

  var fetchCompanyStats = (companies: Company[], start: Date, end: Date): Promise<Map<string, CompanyStats>> => {
    return new Promise((resolve, reject) => {
      var companyStats = new Map<string, CompanyStats>()
      var promises = []
      for (var company of companies) {
        promises.push(services.getCompanyStats(company, start, end))
      }
      Promise.all(promises).then(values => {
        for (var companyStat of values) {
          companyStats.set(companyStat.companyID, companyStat)
        }
        resolve(companyStats)
      })
    })
  }

  useEffect(() => {
    if (start < end) {
      setIsFetching(true)
      fetchCompanyStats(companies, start, end).then(stats => {
        setStats(stats)
        setIsFetching(false)
      }
      )
    }
  }, [start, end])
  useEffect(() => {
    if (companies.length > 0) {
      setselectedCompany(companies[0].id)
    }
  }, [companies])

  if (!companies || Object.keys(companies).length == 0) {
    //companies not loaded yet
    return (<></>)
  }
  var selectedStat: CompanyStats
  var isFleetManger = Object.keys(companies).length == 1
  selectedStat = stats.get(selectedCompany)!
  if (!selectedStat) {
    return (<></>)
  }
  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="column" >
          <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop, classes.content].join(" ")} >
            <FormControl style={{ width: '100%' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container direction="row" spacing={1} justify="flex-start">
                  {isFleetManger ? (<></>) : (
                    <Grid item xs={2}>
                      <Box maxWidth={'100%'} className={[classes.spacedTop].join(" ")}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Select Company</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCompany}
                            label="Select Company"
                            onChange={(e) => { setselectedCompany(e.target.value as string) }}
                          >
                            {companies.map(company =>
                              <MenuItem value={company.id}>{company.name}</MenuItem>)
                            }
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={isFleetManger ? 12 : 8}>
                    <Box maxWidth={'100%'} className={[classes.spacedTop].join(" ")}>
                      <FormLabel id="demo-row-radio-buttons-group-label">{t('period')}</FormLabel>
                      <Grid container direction='row'>
                        <Grid item xs={2}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e => {
                              setSelectedPeriod(e.target.value)
                              const dates = computeDates(e.target.value)
                              setStart(dates[0])
                              setEnd(dates[1])
                            })}
                          >
                            <FormControlLabel value={WEEK_PERIOD} control={<Radio />} checked={selectedPeriod == WEEK_PERIOD} label="7 jours" />
                            <FormControlLabel value={MONTH_PERIOD} control={<Radio />} checked={selectedPeriod == MONTH_PERIOD} label="30 jours" />
                            <FormControlLabel value={YEAR_PERIOD} control={<Radio />} checked={selectedPeriod == YEAR_PERIOD} label="1 an" />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={10}>
                          <KeyboardDatePicker
                            disableToolbar
                            className={[classes.verticalCenter, classes.spaced].join(" ")}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="start"
                            label="Début"
                            value={start}
                            error={end < start}
                            onChange={(date: Date | null) => {
                              setStart(date!)
                            }
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                          <KeyboardDatePicker
                            disableToolbar
                            className={[classes.verticalCenter, classes.spaced].join(" ")}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="end"
                            label="Fin"
                            value={end}
                            error={end < start}
                            onChange={(date: Date | null) => {
                              setEnd(date!)
                            }
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </Grid>

                    </Box>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </FormControl>
            <hr color="primary" />
            <Box maxWidth={'100%'} bgcolor={'white'} >

              {isFetching ?
                <Box style={{ height: '100%', justifyContent: 'center', display: 'flex', alignContent: 'center' }}>
                  <span className="loader" style={{ width: '50px', margin: 'auto' }}></span>
                </Box>
                :
                <Grid container direction="row" spacing={1} justify="center">
                  <Grid item xs={3}>
                    <Grid container direction="row" spacing={1} justify="center">
                      <Grid item xs={4}>
                        <Typography>{t("sessions")} :</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography> {selectedStat.sessionNb}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{t("chargedPower")} :</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography> {Math.round(selectedStat.totalPower * 100) / 100} kWh</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{t("totalAmount")} :</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography> {Math.round(selectedStat.total * 100) / 100} €</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{t('creditNotes')}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>{selectedStat.creditNoteNb}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedRight].join(" ")} >
                      <MaterialReactTable
                        enableRowActions={false}
                        columns={userStatColumns}
                        initialState={{
                          sorting: [
                            {
                              id: 'lastName',
                              desc: false,
                            },
                            {
                              id: 'firstName',
                              desc: false,
                            }
                          ],
                        }}
                        data={selectedStat.userStats}
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableDensityToggle={false}
                        enableGlobalFilter={true}
                        localization={getReactTableLocalisation()}
                        renderTopToolbarCustomActions={(table) => (
                          <>
                            <Box>
                              <Tooltip title={t("csvExport")}>
                                <IconButton onClick={(e) => { handleExportData(t, start, end, selectedStat.userStats) }}>
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </>
                        )
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              }
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>

  )
}



const userStatColumnDef = (t: (s: string) => string, withName: boolean = false, withCompany: boolean = false): MRT_ColumnDef<UserStats>[] => {
  var columns: MRT_ColumnDef<UserStats>[] = []
  return [
    {
      accessorKey: 'firstName',
      header: t("firstName"),
      accessorFn: (row) => { return row.firstName ? row.firstName : " - " }

    },
    {
      accessorKey: 'lastName',
      header: t("lastName"),
      accessorFn: (row) => { return row.lastName ? row.lastName : " - " }

    },
    {
      accessorKey: 'tags',
      header: t("entityTags"),
      accessorFn: (row) => { return row.tags ? row.tags.map(tag => tag.label).join(",") : " - " }
    },
    {
      accessorKey: 'sessionNb',
      header: t("sessions"),
    },
    {
      accessorKey: 'creditNoteNb',
      header: t('creditNotes'),
    },
    {
      accessorKey: 'totalPower',
      header: t("chargedPower"),
      accessorFn: (row) => { return Math.round(row.totalPower * 100) / 100 }
    },
    {
      accessorKey: 'total',
      header: t("totalAmount"),
      accessorFn: (row) => { return Math.round(row.total * 100) / 100 }
    }

  ]
}

