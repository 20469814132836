import axios from 'axios'
import { Company } from '../libs/types'
import { BASE_URL } from './conf'

/*
 id: string;
    name: string;
    adress: string;
    zipCode: string;
    city: string;
    logoUrl: string | null;

*/
export async function getAllCompanies(authToken: string): Promise<Company[]> {
    return new Promise(function (resolve, reject) {
        axios.get(BASE_URL + '/companies', {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var companies: Company[] = []
                res.data.forEach((company: Company) => {
                    companies.push(company)
                })
                resolve(companies)
            })
            .catch(e => reject(e))


    })
}

export async function getCompany(authToken: string, id: string): Promise<Company | null> {
    return new Promise(function (resolve, reject) {
        axios.get(`${BASE_URL}/companies/${id}`, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                if (!res) {
                    resolve(null)
                }
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}


export async function createCompany(authToken: string, company: Company): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.post(BASE_URL + '/companies',
            company,
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            },
        )
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}

export async function updateCompany(authToken: string, company: Company): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.put(BASE_URL + '/companies', company,
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}
export async function deleteCompany(authToken: string, name: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.delete(`${BASE_URL}/companies/name/${name}`, {
            responseType: 'json', headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}