import React, { useState, useEffect, useContext } from 'react'
import { ChargePoint } from '../libs/types';
import { AuthContext } from './authContext';
import Services from '../service/services';

export interface ICps {
  chargepoints: ChargePoint[]
  cpMap: Map<string, ChargePoint>
  myCps?: () => ChargePoint[]
  refresh: () => void
  getAllChargePoints: () => ChargePoint[]
}

const defaultState: ICps = {
  chargepoints: [],
  cpMap: new Map<string, ChargePoint>(),
  refresh: () => { },
  getAllChargePoints: () => { return [] }
}

type Props = {
  children?: React.ReactNode
}
export var ChargePointContext = React.createContext(defaultState)


const ChargePointProvider = ({ children }: Props) => {
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const [ChargePointData, setchargePointData] = useState([] as ChargePoint[])
  const [cpmap, setChargePointData] = useState(new Map<string, ChargePoint>())
  
  const state: ICps = {
    chargepoints: ChargePointData,
    cpMap: cpmap,
    refresh: async () => {
      var data = await fetch()
      //console.log
      var map =  new Map<string, ChargePoint>()
      for(var cp of data){
        map.set(cp.id,cp)
      }

      setchargePointData(data)
      setChargePointData(map)
    },
    getAllChargePoints: () => { return ChargePointData }

  }


  const fetch = async (): Promise<ChargePoint[]> => {
    try {
      let cps: ChargePoint[] = []
      if (auth.sessionInfo?.isEndUser) {
        let cp = await services.getAllChargePointsforUser(auth.User!)
        if (cp) cps.push(...cp)
        return cps
      }
      if (auth.sessionInfo?.fleetManagerProfile) {
        let cp = await services.getAllChargePointsforCompany(auth.sessionInfo.companyID!)
        if (cp) cps.push(...cp)
        return cps
      } else {
        cps = await services.getAllChargePoints()
        return cps
      }

    } catch (err) {
      return []
    }

  }
  useEffect(() => {
    if (!auth.sessionInfo) {
      return
    }
    async function fecthCompaniesData() {
      var data = await fetch()
      //console.log
      var map =  new Map<string, ChargePoint>()
      for(var cp of data){
        map.set(cp.id,cp)
      }

      setchargePointData(data)
      setChargePointData(map)
      

    }
    fecthCompaniesData()
  }, [auth.sessionInfo])


  return <ChargePointContext.Provider value={state}>{children}</ChargePointContext.Provider>
}




export default ChargePointProvider
