import { useContext, useEffect, useState } from 'react'
import demoCps from '../components/demoTour/chargePoint.json'
import demoSessions from '../components/demoTour/sessions.json'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { AuthContext } from '../contexts/authContext'
import { Car, ChargePoint, Session, User } from '../libs/types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import toast, { Toaster } from "react-hot-toast";
import ChargePointList from '../components/chargePoints';
import { Cancel } from '@material-ui/icons';
import SessionList from '../components/sessions/sessionList';
import UserStatCard from '../components/stats/userStatCard'
import bgF2m from '../ressources/backgroundf2m.png';
import { useTranslation } from 'react-i18next'
import { DocumentPopup } from '../components/document/DocumentPopup'
import { MakeBillablePopup } from '../components/sessions/makeBillablePopup'
import Services from '../service/services'
import { CompaniesContext } from '../contexts/companyContext'
import { CarContext } from '../contexts/carContext'
import { useTour } from '@reactour/tour'
import TourContent from '../components/demoTour/tourContent'

import ModalPlayer from '../components/video/modalplayer'



interface EndUserState {
  cps: ChargePoint[]
  cars: Car[]
  sessions: Session[]
  profil: User
}
var state: EndUserState;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  spacedTop: {
    marginTop: "30px"
  },
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },
  session: {
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  green: {
    backgroundColor: 'green',
  },
  hero: {
    width: '100%'
  }
}))

var cpsMap = new Map()

interface commonDialogProps {
  titleCode: string;
  textContentcode: string;
  onClose: () => void;
  open: boolean;
}

export const MessagePopup = ({
  titleCode,
  textContentcode,
  onClose,
  open,
}: commonDialogProps) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles()
  return (
    <Dialog open={open}>
      <DialogTitle ><span className={classes.popupTitle}>{t(titleCode)}</span></DialogTitle>
      <DialogContent>
        <Typography>
          {t(textContentcode)}
        </Typography>
        <br />
      </DialogContent>
      <DialogActions /*sx={{ p: '1.25rem' }}*/>
        <Button variant="contained" startIcon={<Cancel />} onClick={onClose}> {t("close")}</Button>
      </DialogActions>
    </Dialog>
  ) 
}

const mapFromSession = (allSessions :Session[],sessionIds: string[]): Map<string, Session> => {
  const res = new Map<string, Session>
  sessionIds.forEach(id => {
    const session = allSessions.find(s => s.id == id)
    if (session) {
      res.set(id, session)
    }
  })
  return res
}

export default function Home() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { setIsOpen, setSteps, setCurrentStep } = useTour()
  
  var [forceFilterBillable, setforceFilterBillable] = useState(undefined as boolean | undefined);
  var [statedata, setstatedata] = useState(state);
  var [docPopupOpen, setdocPopupOpen] = useState(false);
  var [docConfirmOpen, setdocConfirmOpen] = useState(false);
  var [makeBillablePopupOpen, setmakeBillablePopupOpen] = useState(false);
  var [SessionsToMakeBillable, setSessionsToMakeBillable] = useState(new Map<string, Session>());
  var [lastUpdate, setlastUpdate] = useState(new Date().valueOf())
  var myCompany = useContext(CompaniesContext).nonTechnicalCompanies.find(c => c.id == statedata?.profil.companyID)!

  const makeBillable = (sessionIds: string[], reset: () => void): any => {
    setSessionsToMakeBillable(mapFromSession(statedata.sessions,sessionIds))
    setmakeBillablePopupOpen(true)
  }

  const auth = useContext(AuthContext)
  const cars = useContext(CarContext).cars
  const services = new Services(auth.sessionInfo?.accessToken || "")
  async function refresh(clearState = true) {
    let [cps, sessions] = await Promise.all([
      services.getAllChargePointsforUser(auth.User),
      services.getSessionForUser(auth.User, null, null,false)
    ]);
    for (let cp of cps) {
      cpsMap.set(cp.id, cp)
    }
    var data = {
      cps,
      cars,
      profil: auth.User,
      sessions
    }
    await setstatedata(data)
    if (clearState) {
      setlastUpdate(new Date().valueOf())
    }
    return data
  }
  let init = async () => {

    if (!statedata) {
      // @ts-ignore

      var data = await refresh()
      var docPopupOpen = false
      data.cps.forEach(cp => {
        if (!cp.grid) {
          docPopupOpen = true
        }
      })
      setdocPopupOpen(docPopupOpen)
    }

  }
  useEffect(() => {
    // @ts-ignore
    setSteps(
      [
        {
          selector: '#Sessions',
          content: () => <TourContent title={t('sessionTabTourTitle')} content={t('sessionTabTour')} />
        },
        {
          selector: '#creditNotes',
          content: () => <TourContent title={t('expenseReportTabTourTitle')} content={t('expenseReportTabTour')} />

        },
        {
          selector: '#sessionsList',
          content: t('sessionListTour'),
          action: () => {
            setforceFilterBillable(undefined)//needed in case of goback
            setlastUpdate(new Date().valueOf())
          }
        },
        {
          selector: '#sessionsList',
          content: t('notBillableListTour'),
          action: () => {
            setforceFilterBillable(false)
            setlastUpdate(new Date().valueOf())
          }
        },
        {
          selector: '#userStatCard',
          content: t('userStatCardTour'),
          action: () => {
            setforceFilterBillable(undefined)
            setlastUpdate(new Date().valueOf())
          }
        }
        // ...
      ]
    )
    init()
  }
    , [auth.User])


  const createNote = async (sessionIds: string[], reset: () => void) => {
    try {
      await services.generateNote(statedata?.profil, sessionIds, new Date(), null)
      displayNotification("Note de frais en cours d'édition", false)
      reset()
    } catch (error) {
      console.log(error)
      displayNotification("Generation en erreur", true)
    }
  };

  return (
    <>
   
      {
        makeBillablePopupOpen ?
          <MakeBillablePopup
            onAddCar={refresh}
            isManualEnable={myCompany.options && myCompany.options.nonCompanyCarEnable}
            cpsMap={cpsMap}
            cars={cars.filter(c => (!c.status || c.status == "active"))}
            onClose={() => { setmakeBillablePopupOpen(false) }}
            onSubmit={() => {
              setmakeBillablePopupOpen(false);
              refresh()
            }}
            open={makeBillablePopupOpen}
            sessionMap={SessionsToMakeBillable} />
          :
          <></>
      }
      <MessagePopup
        open={docConfirmOpen}
        titleCode={'documentUploaded'}
        textContentcode={'fleetManagerValidationNeeded'}
        onClose={() => {
          setdocConfirmOpen(false)
        }}
      />
      <DocumentPopup
        company={myCompany}
        open={docPopupOpen}
        cps={statedata ? statedata.cps : []}
        onClose={() => {
          setdocPopupOpen(false)
        }}
        onSubmit={() => { }}
        onSucess={(validateNeeded) => {
          setdocPopupOpen(false);
          if (validateNeeded) {
            setdocConfirmOpen(true)
          }
          refresh()
        }}
      />
      <Toaster
        position="top-right"
      />
      <Grid className={classes.root} container direction="column" alignItems="center">
        <Grid container spacing={1} >
          <Grid container item xs={3} direction="column" >
            <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
              <ChargePointList
                 key={lastUpdate}
                cps={statedata && statedata.cps ? statedata.cps : []}
              />
            </Box>
            <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
              <UserStatCard
                id="userStatCard"
                user={statedata ? statedata.profil : null} />
            </Box>
          </Grid>
          <Grid container item xs={8} direction="column" >
            
              <SessionList
                displayNotification={displayNotification}
                archiveMode={false}
                filterBillable={forceFilterBillable}
                id="sessionsList"
                makeBillable={makeBillable}
                key={statedata ? lastUpdate : 0}
                createNote={createNote}
                cpMap={cpsMap}
                isLoading={statedata ? false : true}
              />
            
          </Grid>
          <Grid container item xs={2} direction="column" >
          </Grid>
          {/*<button onClick={async () => {
            var d: any = {
              cps: demoCps,
              cars: statedata.cars,
              profil: auth.User,
              sessions: demoSessions
            }
            //console.log("data",data)
            setlastUpdate(new Date().valueOf())
            await setstatedata(d)
            setCurrentStep(0)
            setIsOpen(true)
          }
          }>Open Tour</button>
          */  
        }
        </Grid>
      </Grid>
    </>
  )
}

const displayNotification = (text: string, isError: boolean) => {
  if (isError) {
    toast.error(text)
    return
  }
  toast.success(text)
}