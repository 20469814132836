import React, { useState } from 'react';
import { Cancel, Add, Remove } from "@material-ui/icons";
import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";

interface PeriodEditorProps {
    editMode: boolean;
    periods: Date[][];
    setPeriods: (periods: Date[][]) => void;

}

const useStyles = makeStyles((theme) => ({
    spaced: {
        marginLeft: "15px",
        marginRight: "15px"
    },
    popupTitle: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "1.8rem!important"
    }
}))

export const PeriodEditor: React.FC<PeriodEditorProps> = ({
    editMode,
    periods,
    setPeriods,

}) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const [periodErrorMessage, setPeriodErrorMessage] = useState<string>("");
    const validatePeriods = (periods: string[][]) => {
        var isError = false
        periods.forEach((period, index) => {
            if (period.length < 2 || !period[0] || !period[1] || new Date(period[0]).getTime() > new Date(period[1]).getTime()) {
                setPeriodErrorMessage(t("periodsError"));
                isError = true
            } else {
                if (isNaN(new Date(period[0]).getTime()) || isNaN(new Date(period[1]).getTime())) {
                    setPeriodErrorMessage(t("periodsError"));
                    isError = true
                }
            }
        })
        if (periods.length == 1) {
            if (!isError) {
                setPeriodErrorMessage("");
            }
        }
        //verifie si 2 periode ne s'overlappent pas
        for (let i = 0; i < periods.length; i++) {
            for (let j = i + 1; j < periods.length; j++) {
                const i0 = new Date(periods[i][0]);
                const i1 = new Date(periods[i][1]);
                const j0 = new Date(periods[j][0]);
                const j1 = new Date(periods[j][1]);
                if (periods[i][0] && periods[j][0] && i0 >= j0 && i0 <= j1) {
                    setPeriodErrorMessage(t("periodsOverlapError"));
                    isError = true;
                }
                if (periods[i][1] && periods[j][0] && i1 >= j0 && i1 <= j1) {
                    setPeriodErrorMessage(t("periodsOverlapError"));
                    isError = true;
                }
                if (periods[j][0] && periods[i][0] && j0 >= i0 && j0 <= i1) {
                    setPeriodErrorMessage(t("periodsOverlapError"));
                    isError = true;
                }
                if (periods[j][1] && periods[i][0] && j1 >= i0 && j1 <= i1) {
                    setPeriodErrorMessage(t("periodsOverlapError"));
                    isError = true;
                }
            }
        }

        if (!isError) {
            setPeriodErrorMessage("");
        }
    }
    const addPeriod = () => {
        let p = clone(periods);
        p.push([new Date("01/01/2000 00:00"), new Date("01/01/2000 23:59")]);
        setPeriods(p);
        validatePeriods(p);
    };

    const removePeriod = (index: number) => {
        let p = clone(periods);
        p.splice(index, 1);
        setPeriods(p);
        validatePeriods(p);
    };

    const clone = (arr: any[]) => {
        return JSON.parse(JSON.stringify(arr));
    };
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {periods.map((period, index) => (
                    
                    <>
                        <SingleInputTimeRangeField
                            disabled={!editMode}
                            key={index}
                            className={classes.spaced}
                            label={t('offPeakPeriod') + " " + (index + 1)}
                            value={period.length > 0 ? [new Date(period[0]), new Date(period[1])] : [new Date("01/01/2000 00:00"), new Date("01/01/2000 23:59")]}
                            ampm={false}
                            color={'error'}
                            onChange={(newValue) => {
                                let p = clone(periods);
                                p[index] = [newValue[0]!, newValue[1]!];
                                setPeriods(p);
                                validatePeriods(p);
                            }}
                        />
                        {
                            editMode && index === 0 ?
                                (
                                    <IconButton onClick={addPeriod}>
                                        <Add color={'action'} />
                                    </IconButton>
                                ) :
                                <></>
                        }
                        {
                            editMode && index > 0 ?
                                (
                                    <>
                                        <IconButton onClick={() => removePeriod(index)}>
                                            <Remove />
                                        </IconButton>
                                    </>
                                ) :
                                <></>
                        }


                        <br />
                        <br />
                    </>
                ))}
            </LocalizationProvider>
            <Typography color={'error'}>{t(periodErrorMessage)}</Typography>
            <br />
        </>
    );
};
