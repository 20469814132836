import { useState } from "react"
import { Tag } from "../../libs/types"
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core"
import { useTranslation } from "react-i18next"

interface Props {
    tags: Tag[],
    multipleselect: boolean,
    currentTags: Tag[],
    handleChange: (selectectTags: Tag[]) => void
}

const TagSelector: React.FC<Props> = ({ tags, handleChange, multipleselect, currentTags }) => {
    const { t, i18n } = useTranslation();
    const currentTagMap = new Map<string, Tag[]>()
    currentTags.forEach(tag => {
        var typeTags = currentTagMap.get(tag.type)
        if (!typeTags) {
            currentTagMap.set(tag.type, [tag])
        } else {
            typeTags.push(tag)
            currentTagMap.set(tag.type, typeTags)
        }
    }
    )


    const [selectedTags, setSelectedTags] = useState<Map<string, Tag[]>>(currentTagMap)

    const getTags=(m:Map<string, Tag[]>):Tag[] =>{
        const res :Tag[]= []
        m.forEach((tag,_)=>{
            res.push(...tag)
        })
            return res
        }
    const typeTagsMap = new Map<string, Tag[]>()
    tags.forEach(tag => {
        if (typeTagsMap.has(tag.type)) {
            typeTagsMap.get(tag.type)?.push(tag)
        } else {
            typeTagsMap.set(tag.type, [tag])
        }
    })
    if (multipleselect) {
        return (
            <>
                {Array.from(typeTagsMap.keys()).map(type => (
                    <div key={type}>
                        <h3>{type}</h3>
                        <Grid container direction="row" spacing={1}>
                            {typeTagsMap.get(type)?.map(tag => (
                                <Grid item xs={2}>
                                    <div key={tag.name}>
                                        <input type={"checkbox"} id={tag.name} name={tag.name} value={tag.name} onChange={(e) => {
                                            const current = selectedTags.get(tag.type)?selectedTags.get(tag.type)!:[]
                                            if (e.target.checked) {
                                                tags = [...current, tag]
                                            } else {
                                                var tags = current.filter(t => t.name !== tag.name)
                                            }
                                            selectedTags.set(type,tags)
                                            setSelectedTags(selectedTags)
                                            handleChange(getTags(selectedTags))
                                        }}
                                            checked={selectedTags.get(tag.type) && selectedTags.get(tag.type)!.map(tag => tag.name).includes(tag.name)}
                                        />
                                        <label htmlFor={tag.name}>{tag.label}</label>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ))}
            </>
        )
    }
    return (
        <>
            {Array.from(typeTagsMap.keys()).map(type => (
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectedTags.get(type) &&  selectedTags.get(type)!.length>0? selectedTags.get(type)![0].name : "####"}
                >
                    <div key={type}>
                        <h3>{type}</h3>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={2}>
                                <FormControlLabel value={"####"} onClick={() => { 
                                    selectedTags.set(type,[])
                                    setSelectedTags(selectedTags)
                                    handleChange(getTags(selectedTags))
                                    }} control={<Radio />} label={t("none")} />
                            </Grid>
                            {typeTagsMap.get(type)?.map(tag => (
                                <Grid item xs={2}>
                                    <FormControlLabel value={tag.name} onChange={() => {
                                        if (selectedTags.get(type) && selectedTags.get(type)!.length>0&&selectedTags.get(type)![0].name == tag.name) {
                                            selectedTags.set(type,[])
                                            setSelectedTags(selectedTags)
                                            handleChange(getTags(selectedTags))
                                            return
                                        }
                                        selectedTags.set(type,[tag])
                                        setSelectedTags(selectedTags)
                                        handleChange(getTags(selectedTags))

                                    }} control={<Radio />} label={tag.label} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </RadioGroup>
            ))}
        </>
    )
}

export default TagSelector