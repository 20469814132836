import axios from 'axios'
import { BASE_URL } from './conf'
import { Tag, TagMember } from '../libs/types'

export async function getAllTagsForCompany(authToken: string, companyID: string): Promise<Tag[]> {
    return new Promise(function (resolve, reject) {
        axios.get(BASE_URL + '/tags/company/' + companyID, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                resolve(res.data)
            }).catch(e => reject(e))
    })
}

export async function createTag(authToken: string, type: string, companyId: String, name: String, label: String): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.post(
            `${BASE_URL}/tags/company/${companyId}/name/${name}`,
            {
                name,
                type,
                label
            },
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}

export async function deleteTag(authToken: string, companyId: String, name: String): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.delete(
            `${BASE_URL}/tags/company/${companyId}/name/${name}`,
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((_: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}


export async function listTagMembers(authToken: string, companyID: string, name: String,): Promise<TagMember[]> {
    return new Promise(function (resolve, reject) {
        axios.get(
            `${BASE_URL}/tags/company/${companyID}/name/${name}/members`,

            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((res: any) => {
                resolve(res)
            })
            .catch(e => reject(e))
    })
}


export async function attachTag(authToken: string, companyID: string, type: string, tagName: String, entityId: String): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.post(
            `${BASE_URL}/tags/company/${companyID}/name/${tagName}/members`,
            {
                add: [{ type, entityId }]
            },
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}

export async function detachTag(authToken: string, companyID: string, tagName: String, entityId: String): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.post(
            `${BASE_URL}/tags/company/${companyID}/name/${tagName}/members`,
            {
                remove: [entityId]
            },
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}
