import { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef } from 'material-react-table';
import {
  Download as DownloadIcon,
  DoneOutlineOutlined as ValidateIcon
} from '@mui/icons-material';
import IconButton from '@material-ui/core/IconButton'
import { Address, ChargePoint, Company, Grid as priceGrid, User } from '../libs/types'
import { downladGridDoc } from '../service/chargepoints'
import { CompaniesContext } from '../contexts/companyContext'
import { GetMultiPinGMap } from '../components/gmaps'
import { Card } from '@material-ui/core'
import bgF2m from '../ressources/backgroundf2m.png';
import { list } from '../service/grid'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/authContext'
import Services from '../service/services'
import { GridContext } from '../contexts/GridContext';
import { UsersContext } from '../contexts/userContext';
import { ChargePointContext } from '../contexts/cpContext';

interface ChargePointRepresentation {
  cp: ChargePoint
  company: Company
  user: User
}
//mock data - strongly typed if you are using TypeScript (optional, but recommended)
var data: ChargePointRepresentation[];

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
  },
  spaced: {
    marginLeft: '15px',
    marginRight: '15px',

  },
  spacedTop: {
    marginTop: "30px"
  },
  spacedRight: {
    marginRight: "15px"
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
}))

export default function ChargePoints() {
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext)
  const cpContext = useContext(ChargePointContext)
  const gridMap = useContext(GridContext).gridMap
  const services = new Services(auth.sessionInfo?.accessToken || "")
  var fleetManagerProfile = auth.sessionInfo?.fleetManagerProfile
  const cpColumns = useMemo<MRT_ColumnDef<ChargePointRepresentation>[]>(() => columnFactory(t, gridMap, !fleetManagerProfile), [i18n.language])
  const classes = useStyles()
  const companies = useContext(CompaniesContext)
  const usersMap = useContext(UsersContext).getMap()
  var [statedata, setstatedata] = useState(data);
  const addresses: Address[] = []
  const labels: string[] = []
  if (statedata) {
    statedata.forEach(data => {
      if (data.cp.address) {
        addresses.push(data.cp.address)
        labels.push(data.cp.name)
      }
    })
  }
  var refresh = async () => {
    const cprs: ChargePointRepresentation[] = []
    for (const company of companies.nonTechnicalCompanies) {
      let cps = cpContext.chargepoints
      for (const cp of cps) {
        cprs.push({
          cp,
          company,
          user: usersMap.get(cp.users[0].toLowerCase())!

        })
      }
    }
    setstatedata(cprs)
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="row" >
          <Grid item xs={6}>
            <Grid container item xs={12} direction="column" >
              <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
                <MaterialReactTable
                  enableRowActions
                  renderRowActions={({ row }) => {
                    const grid = row.original.cp.grid ? gridMap.get(row.original.cp.grid) : null
                    if (grid && grid.billNeeded) {
                      if (!row.original.cp.isGridValidated) {
                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <Box sx={{ flexWrap: 'nowrap' }}>
                              <IconButton onClick={() => {
                                downladGridDoc(row.original.cp)
                              }}>
                                <DownloadIcon />
                              </IconButton>
                              <IconButton onClick={async () => {
                                let cp = row.original.cp
                                cp.isGridValidated = true
                                await services.updateChargePoint(cp)
                                refresh()
                              }}>
                                <ValidateIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        )
                      }
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                          <Box sx={{ flexWrap: 'nowrap' }}>
                            <IconButton onClick={() => {
                              downladGridDoc(statedata[row.index].cp)
                            }}>
                              <DownloadIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      )
                    }
                  }}
                  columns={cpColumns}
                  data={statedata ? statedata : []}
                  state={{ isLoading: statedata ? false : true, density: "compact" }}
                  enableColumnOrdering={false}
                  enableHiding={false}
                  enableDensityToggle={false}
                  enableGlobalFilter={false}
                />
                <br />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Card className={[classes.spaced, classes.spacedTop, classes.spacedRight].join(" ")} >
              {GetMultiPinGMap(labels, addresses, -1, -1)}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const columnFactory = (t: (s: string) => string, grids: Map<string, priceGrid>, withCompany: boolean): MRT_ColumnDef<ChargePointRepresentation>[] => {
  let res: MRT_ColumnDef<ChargePointRepresentation>[] = []
  if (withCompany) {
    res.push({
      accessorKey: 'company.name', //simple recommended way to define a column
      header: 'company',
      size: 100,
    })
  }
  return [...res,
  {
    accessorKey: 'user.userName', //simple recommended way to define a column
    header: t('user'),
    accessorFn: (row) => {
      return (row.user && row.user.userName) ? row.user.firstName + " " + row.user.lastName : '-'
    },
    size: 100,
  },
  {
    accessorKey: 'cp.name', //simple recommended way to define a column
    header: t('cpName'),
    size: 100,
  },
  {
    accessorKey: 'cp.grid', //simple recommended way to define a column
    header: 'grid',
    size: 150,
    accessorFn: (row) => {
      return row.cp && row.cp.grid ? grids.get(row.cp.grid)?.name : ''
    }
  },
  {
    accessorKey: 'cp.isGridValidated', //simple recommended way to define a column
    header: t('status'),
    Cell: (cell) => {
      let label = t('gridValid')
      let row = cell.row.original
      if (row.cp && !row.cp.grid) {
        label = t('missingGrid')
      } else {
        if (row.cp && !row.cp.isGridValidated) {
          label = t('waitingForValidation')
        }
      }
      return (
        <Box
          component="span"
        >
          {label}
        </Box>
      )
    },
    size: 150,
  }]
}
