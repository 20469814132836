import axios from 'axios'
import{ BASE_URL } from './conf'
import{  ChargePoint, Index } from '../libs/types'
import fileDownload from 'js-file-download'

export async function getIndexes(ID : string,start:Date|null,end:Date|null) :Promise<Index[]> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);     
        if (start){
            params.append('start',String(Math.floor(new Date(start).getTime() / 1000)))
        }
        if (end){
            params.append('end',String(Math.floor(new Date(end).getTime() / 1000)))
        }
        axios.get(BASE_URL+`/counter/${ID}/values`, {
            params,
            responseType: 'json',
        })
            .then((res: any) => {

                 if (!res.data || res.data.length==0){
                    resolve([])
                 }
                 var idxs : Index[] = []
                 res.data.forEach( (idx: Index) =>{
                    idxs.push( idx  )
                 })
                resolve(idxs)
            }).catch(e => reject(e))


    })

}



export async function downloadIndex(cp:ChargePoint): Promise<void> {
    return new Promise(function (resolve, reject) {
      const params = new URLSearchParams([]);
      axios.get(BASE_URL + `/counter/${cp.id}/values/csv`, {
        headers: {
          Accept: 'application/csv',
         // ContentType :'application/pdf',
        },
        responseType: 'blob',
      })

      .then((res: any) => {
        fileDownload(res.data, "index.csv")
        resolve()
      })
        .catch(e => reject(e))
    })

  }