import { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Car, CarCharge, ChargePoint, Company, Tag, User } from '../libs/types'
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef, MaterialReactTableProps } from 'material-react-table';
import {
  Unpublished as UnpublishedIcon,
  PublishedWithChanges as PublishedIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import IconButton from '@material-ui/core/IconButton'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, MenuItem, Select, TextField, Tooltip } from '@material-ui/core'
import { Stack } from '@mui/material'
import { getCarChargeS, updateCar } from '../service/cars'
import { CompaniesContext } from '../contexts/companyContext'
import { getReactTableLocalisation } from '../libs/i18n'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/authContext'
import bgF2m from '../ressources/backgroundf2m.png';
import { tsToDate } from '../libs/utils'
import Services from '../service/services'
import { TagContext } from '../contexts/TagContext'
import TagSelector from '../components/tags/tagSelector'
import { UsersContext } from '../contexts/userContext'
import { ChargePointContext } from '../contexts/cpContext'


interface CarRepresentation {
  car: Car
  company: Company
}
interface CarChargeRepresentation {
  charge: CarCharge
  cp: ChargePoint | null
}

interface state {
  cars: CarRepresentation[],
  cps: Map<string, ChargePoint>
}
//mock data - strongly typed if you are using TypeScript (optional, but recommended)
var data: state;
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
  },
  spaced: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  spacedTop: {
    marginTop: "30px"
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vvw',
    backgroundColor: 'white',
    border: '2px solid #000',
  }
}))

export default function CarPage() {

  const classes = useStyles()
  var [statedata, setstatedata] = useState(data);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [carToEdit, setCarToEdit] = useState(null as Car | null);
  const companies = useContext(CompaniesContext).nonTechnicalCompanies
  const companyMap = useContext(CompaniesContext).companyMap
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext)
  const userMap = useContext(UsersContext).getMap()
  const cpContext = useContext(ChargePointContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const isFleetManager = auth.sessionInfo?.fleetManagerProfile ? true : false
  const CarColumns = useMemo<MRT_ColumnDef<CarRepresentation>[]>(() => columnFactory(t, userMap, isFleetManager), [i18n.language])


  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});


  /*const handleSaveRowEdits: MaterialReactTableProps<CarRepresentation>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        let car = valuesToCar(companyMap, values)
        await updateCar(valuesToCar(companyMap, values));
        exitEditingMode(); //required to exit editing mode and close modal
        refresh()
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };
*/
  var refresh = async () => {
    var d: CarRepresentation[] = []

    //  var SohMap = new Map<string, ChartValue[]>
    const promises: Promise<Car[]>[] = []

    for (const company of companies) {
      promises.push(services.getAllCarsCompany(company.id))

    }

    await Promise.all(promises).then(async (CompanyCars) => {
      CompanyCars.forEach(cars => {
        cars.forEach(c => {
          d.push({
            car: c,
            company: companyMap.get(c.companyID) || companies[0]
          })
        })
      })
    })
    setstatedata({
      cars: d,
      cps: cpContext.cpMap
    })
  }

  useEffect(() => {
    refresh()
  }, [])
  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="column" >
          <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
            <MaterialReactTable
              enableRowActions
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <IconButton onClick={() => {
                    setCarToEdit(row.original.car)
                    setCreateModalOpen(true)
                  }
                  } >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={async () => {
                    let car = JSON.parse(JSON.stringify(row.original.car))
                    car.status = car.status == "inactive" ? "active" : "inactive"
                    await services.updateCar(car);
                    refresh()
                    /* setCarToEdit(row.original.car)
                     setCreateModalOpen(true)*/
                  }
                  } >
                    {row.original.car.status && row.original.car.status == "inactive" ? <PublishedIcon /> : <UnpublishedIcon />}
                  </IconButton>
                </Box>

              )}
              columns={CarColumns}
              state={{ isLoading: statedata ? false : true, density: "compact" }}
              data={statedata ? statedata.cars : []}
              enableRowSelection //enable some features
              enableColumnOrdering={false}
              enableHiding={false}

              enableDensityToggle={false}
              enableGlobalFilter={true}
              renderTopToolbarCustomActions={() => (
                <Tooltip title="Ajouter un véhicule">
                  <IconButton onClick={() => setCreateModalOpen(true)} >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              renderDetailPanel={row => {
                if (row.row.getIsExpanded()) {
                  return CarPanel(statedata.cars[row.row.index], statedata.cps/*statedata.SOH.get(statedata.cars[row.row.index].car.VIN)*/)

                }
                return (<></>)
              }}
              localization={getReactTableLocalisation()}
            />
            <br />
          </Box>
        </Grid>
      </Grid>
      <CreateModal
        key={carToEdit?.VIN || Math.random()}
        columns={CarColumns}
        open={createModalOpen}
        selectedCar={carToEdit}
        onClose={() => { setCarToEdit(null); setCreateModalOpen(false) }}
        onSubmit={async (values, isUpdate) => {
          if (isUpdate) {

            await services.updateCar(valuesToCar(companyMap, values));
          } else {

            await services.createCar(valuesToCar(companyMap, values));
          }
          refresh()
        }}
        title={carToEdit ? t('editCarTitle') : t('createCarTitle')}
        confirmButtonCaption={carToEdit ? t("carEditConfirm") : t("carCreateConfirm")}
      />
    </Grid>
  )
}


const columnFactory = (t: (s: string) => string, userMap: Map<string, User>, isFleetManager: boolean): MRT_ColumnDef<CarRepresentation>[] => {

  const columns: MRT_ColumnDef<CarRepresentation>[] = []
  if (!isFleetManager) {
    columns.push({
      accessorKey: 'company.name',
      header: t('client'),
      enableEditing: false
    })
  }

  columns.push(...[
    {
      accessorKey: 'car.VIN',
      header: 'VIN',
      enableEditing: false,
      accessorFn: (row) => {
        return row.car && row.car.VIN ? row.car.VIN : " - "
      },
    },
    {
      accessorKey: 'car.plateNumber',
      header: t('plateNumber'),
    },
    /*{
      accessorKey: 'car.brand',
      header: t('brand'),
    },*/
    {
      accessorKey: 'car.model',
      header: t('model'),
      accessorFn: (row) => {
        return row.car ? row.car.brand + " " + row.car.model : "-"
      },
    },
    {
      accessorKey: 'car.user',
      header: t('user'),
      accessorFn: (row) => {
        return row.car && row.car.user ? userMap.get(row.car.user)?.firstName + " " + userMap.get(row.car.user)?.lastName : " - "
      }
    },
    {
      accessorKey: 'car.status',
      header: t('carStatus'),
      accessorFn: (row) => {
        return row.car && row.car.status ? t(row.car.status) : t("active")
      }
    },
    {
      accessorKey: 'car.tags',
      header: t('entityTags'),
      accessorFn: (row) => {
        return row.car && row.car.tags ? row.car.tags.map(tag => tag.label).join(",") : " - "
      },
    },
    {
      accessorKey: 'car.lastCharge',
      header: t('lastSession'),
      enableEditing: false,
      accessorFn: (row) => {
        return row.car && row.car.lastCharge ? row.car.lastCharge.toLocaleString() : " - "
      },
    },

  ] as MRT_ColumnDef<CarRepresentation>[])

  if (!isFleetManager) {
    columns.push({
      accessorKey: 'car.group',
      header: t('plastikGroup'),
    },
      {
        accessorKey: 'car.plastikID',
        header: t('plastikID'),
      })
  }
  return columns

}

interface CreateModalProps {
  columns: MRT_ColumnDef<CarRepresentation>[];
  onClose: () => void;
  onSubmit: (values: any, isUpdate: boolean) => void;
  selectedCar: Car | null
  open: boolean;
  title: string
  confirmButtonCaption: string
}


export const CreateModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  selectedCar,
  title,
  confirmButtonCaption
}: CreateModalProps) => {



  const { t, i18n } = useTranslation();
  const usersMap = useContext(UsersContext).getCompanyMap()
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );


  useEffect(() => {
    if (selectedCar) {
      var v = JSON.parse(JSON.stringify(values))
      v["company.companyID"] = selectedCar.companyID
      v["car.plateNumber"] = selectedCar.plateNumber
      v["car.VIN"] = selectedCar.VIN
      v["car.tags"] = selectedCar.tags
      v["car.brand"] = selectedCar.brand
      v["car.model"] = selectedCar.model
      v["car.group"] = selectedCar.group
      v["car.plastikID"] = selectedCar.plastikID
      v["car.user"] = selectedCar.user

      setValues(v)
    } else {
      setValues({})
    }
  }, [selectedCar])

  const handleSubmit = () => {
    values["car.tags"] = selectedTags
    values["company.companyID"] = selectedCompany
    if (values["car.user"] == 0) {
      delete values["car.user"]
    }

    onSubmit(values, selectedCar != null);
    onClose();
  };

  const companies = useContext(CompaniesContext).nonTechnicalCompanies
  const tags = useContext(TagContext).tags
  const [selectedCompany, setselectedCompany] = useState(selectedCar ? selectedCar.companyID : companies[0].id)
  const [selectedTags, setselectedTags] = useState(selectedCar ? selectedCar.tags : [] as Tag[])
  const [selectedUser, setSelectedUser] = useState(selectedCar ? selectedCar.user : null)
  values["company.companyID"] = selectedCompany
  return (
    <Dialog open={open} fullWidth maxWidth={"md"} >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => {
              if (column.accessorKey == "car.tags") {
                return (<TagSelector
                  currentTags={values[column.accessorKey] || []}
                  multipleselect={false}
                  tags={tags}
                  handleChange={(tags) => setselectedTags(tags)}
                />)
              }
              if (column.accessorKey == "car.user") {
                return (
                  <FormGroup>
                    <label>{t("user")}</label>
                    <Select
                      labelId="demo-simple-select-label"
                      value={selectedUser}
                      label="Select User"
                      placeholder='User'
                      onChange={(e) => {
                        setValues({ ...values, ["car.user"]: e.target.value })
                          ; setSelectedUser(e.target.value as string)
                      }}
                    >
                      <MenuItem value={0}>{t('none')}</MenuItem>
                      {usersMap.get(selectedCompany) ? usersMap.get(selectedCompany)!.map(user =>
                        <MenuItem value={user.userName}>{user.firstName + " " + user.lastName}</MenuItem>)
                        : <></>
                      }
                    </Select>
                  </FormGroup>)
              }
              if (column.accessorKey == "car.model") {
                //brand and model have been combined in main table so split is needed here
                return (
                  <>
                    <TextField
                      label={t("brand")}
                      name={"car.brand"}
                      // @ts-ignore
                      value={values["car.brand"]}
                      onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                      }
                    />
                    <TextField
                      label={t("model")}
                      name={"car.model"}
                      // @ts-ignore
                      value={values["car.model"]}
                      onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                      }
                    />
                  </>)


                return (<></>)
              }
              if (column.accessorKey == "company.name") {
                return (<FormGroup>
                  <label>{t("client")}</label>
                  <Select
                    disabled={selectedCar != null}
                    labelId="demo-simple-select-label"
                    value={selectedCompany}
                    label="Select Company"
                    onChange={(e) => {

                      setValues({ ...values, ["company.companyID"]: e.target.value })
                        ; setselectedCompany(e.target.value as string)
                    }}
                  >
                    {companies.map(company =>
                      <MenuItem value={company.id}>{company.name}</MenuItem>)
                    }
                  </Select>
                </FormGroup>)
              }
              if (column.accessorKey == "car.lastCharge") {
                return (<></>)
              }
              return (<TextField
                label={column.header}
                name={column.accessorKey}
                // @ts-ignore
                value={values[column.accessorKey]}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />)
            }
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions /*sx={{ p: '1.25rem' }}*/>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          {confirmButtonCaption}
        </Button>
      </DialogActions>
    </Dialog>
  );
};



function CarPanel(car: CarRepresentation, cps: Map<string, ChargePoint>, /*chartValues: ChartValue[] | undefined*/) {
  //var [gridID, setGridID] = useState(cp.grid);
  const classes = useStyles()
  const load = async () => {
    var c = await getCarChargeS(car.car)
    var chargesRep: CarChargeRepresentation[] = []
    for (var charge of c) {
      chargesRep.push({
        charge,
        cp: charge.chargePointID ? cps.get(charge.chargePointID) || null : null
      })
    }
    setcharges(chargesRep)
  }
  useEffect(() => {
    var c = load()
  }, []);
  var [charges, setcharges] = useState([] as CarChargeRepresentation[]);
  const chargeColumns = useMemo<MRT_ColumnDef<CarChargeRepresentation>[]>(chargesColumFactory, [])

  return (
    <>
      <MaterialReactTable
        initialState={{ density: "compact" }}
        columns={chargeColumns}
        data={charges || []}
        enableRowSelection={false} //enable some features
        enableColumnOrdering={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableTopToolbar={false}
        enableFullScreenToggle={false}
        enableColumnFilters={false}
        enableExpandAll={true}
        enableSorting={false}
        enableGlobalFilter={false} //turn off a feature
      />
    </>
  )
}

const valuesToCar = (companyMap: Map<string, Company>, values: any): Car => {
  var car = Object.keys(values).reduce((acc, key) => {
    let splited = key.split('car.')
    if (splited.length == 2) {
      let value = values[key]
      return { ...acc, [splited[1]]: value };
    }
    return acc
  }, {} as any);
  var company = companyMap.get(values["company.companyID"])
  car.companyID = company?.id
  car.tags = values["car.tags"]
  return car
}

const chargesColumFactory = (): MRT_ColumnDef<CarChargeRepresentation>[] => [
  {
    accessorKey: 'cp',
    header: 'point de charge ',
    accessorFn: (row) => {
      return row.cp ? row.cp.name : " - "
    },
  },
  {
    accessorKey: 'charge.ts',
    header: 'Debut',
    accessorFn: (row) => {
      return row.charge.ts ? tsToDate(row.charge.ts).toLocaleString() : " - "
    },
  },
  {
    accessorKey: 'charge.end',
    header: 'Fin',
    accessorFn: (row) => {
      return row.charge.end ? tsToDate(row.charge.end).toLocaleString() : " - "
    },
  },
  {
    accessorKey: 'charge.startGauge',
    header: '% Debut',
  },
  {
    accessorKey: 'charge.endGauge',
    header: '% Fin',
  },
  {
    accessorKey: 'charge.energyAdded',
    header: 'kWh',
  },
  {
    accessorKey: 'charge.location.lat',
    header: 'Lat',
  },
  {
    accessorKey: 'charge.location.lng',
    header: 'Long',
  },

]