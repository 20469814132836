import React from 'react'
import './App.css'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn, IsAdmin, IsEndUser, IsFleetManager } from './contexts/authContext'
import SignIn from './routes/auth/signIn'
import VerifyCode from './routes/auth/verify'
import RequestCode from './routes/auth/requestCode'
import ForgotPassword from './routes/auth/forgotPassword'
import ChangePassword from './routes/auth/changePassword'
import Home from './routes/home'
import ResponsiveAppBar, { SessionsPage, usersPage } from './components/navbar'
import CompanyPage from './routes/company'
import ChargePoints from './routes/chargepoints'
import Users from './routes/users'
import CarPage from './routes/car'
import CompanySessionPage from './routes/sessions'
import CreditNotesPage from './routes/creditNotes'
import CompanyProvider from './contexts/companyContext'
import CreatePassword from './routes/auth/createPassword'
import GridPage from './routes/grid'
import GridProvider from './contexts/GridContext'
import { theme } from './libs/theme'
import Metrics from './routes/metrics'
import DevicesPage from './routes/devices'
import { Register } from './routes/signup/register'
import Account from './routes/account'
import CreditNotesDlPage from './routes/filedl'
import TagsPage from './routes/tags'
import TagProvider from './contexts/TagContext'
import CarProvider from './contexts/carContext'
import UserProvider from './contexts/userContext'
import { TourProvider } from '@reactour/tour'
import Archived from './routes/archived'
import ChargePointProvider from './contexts/cpContext'
import DeviceProvider from './contexts/deviceContext'

let lightTheme = responsiveFontSizes(theme)

// let darkTheme = createMuiTheme({
//   palette: {
//     type: 'dark',
//   },
// })
// darkTheme = responsiveFontSizes(darkTheme)

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={Register} />
      <Route path="/verify" component={VerifyCode} />
      <Route path="/requestcode" component={RequestCode} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/createpassword" component={CreatePassword} />
      <Route path="/fdl" component={CreditNotesDlPage} />
      <Route path="/" component={SignIn} />
    </Switch>
  </Router>
)

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <ResponsiveAppBar
      landingPage={usersPage}
    />
    <Switch>
      <Route path="/account" component={Account} />
      <Route path="/changepassword" component={ChangePassword} />
      <Route path="/companies" component={CompanyPage} />
      <Route path="/chargePoints" component={ChargePoints} />
      <Route path="/users" component={Users} />
      <Route path="/cars" component={CarPage} />
      <Route path="/creditNotes" component={CreditNotesPage} />
      <Route path="/CompanySessions" component={CompanySessionPage} />
      <Route path="/tags" component={TagsPage} />
      <Route path="/metrics" component={Metrics} />
      <Route path="/Grids" component={GridPage} />
      <Route path="/devices" component={DevicesPage} />
      <Route path="/fdl" component={CreditNotesDlPage} />
      <Route path="/" component={Users} />
    </Switch>
  </Router>
)

const EndUserRoute: React.FunctionComponent = () => (
  <Router>
    <ResponsiveAppBar
      landingPage={SessionsPage} />
    <Switch>
      <Route path="/account" component={Account} />
      <Route path="/changepassword" component={ChangePassword} />
      <Route path="/creditNotes" component={CreditNotesPage} />
      <Route path="/archived" component={Archived} />
      <Route path="/" component={Home} />
      <Route path="/sessions" component={Home} />

      <Route path="/fdl" component={CreditNotesDlPage} />
    </Switch>
  </Router>
)

const FleetManagerRouter: React.FunctionComponent = () => (
  <Router>
    <ResponsiveAppBar
      landingPage={usersPage} />
    <Switch>
      <Route path="/account" component={Account} />
      <Route path="/changepassword" component={ChangePassword} />
      <Route path="/companies" component={CompanyPage} />
      <Route path="/chargePoints" component={ChargePoints} />
      <Route path="/users" component={Users} />
      <Route path="/cars" component={CarPage} />
      <Route path="/creditNotes" component={CreditNotesPage} />
      <Route path="/CompanySessions" component={CompanySessionPage} />
      <Route path="/Grids" component={GridPage} />
      <Route path="/devices" component={DevicesPage} />
      <Route path="/tags" component={TagsPage} />
      <Route path="/metrics" component={Metrics} />
      <Route path="/fdl" component={CreditNotesDlPage} />
      <Route path="/" component={Users} />
    </Switch>
  </Router>
)

const App: React.FunctionComponent = () => (

  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <AuthProvider>
      <AuthIsSignedIn>
        <TourProvider steps={[]}>
          <CompanyProvider>
            <UserProvider>
              <CarProvider>
                <TagProvider>
                  <GridProvider>
                    <ChargePointProvider>
                      <IsEndUser>
                        <EndUserRoute />
                      </IsEndUser>
                      <IsAdmin>
                        <DeviceProvider>
                          <MainRoute />
                        </DeviceProvider>
                      </IsAdmin>
                      <IsFleetManager>
                        <DeviceProvider>
                          <FleetManagerRouter />
                        </DeviceProvider>
                      </IsFleetManager>
                    </ChargePointProvider>
                  </GridProvider>
                </TagProvider>
              </CarProvider>
            </UserProvider>
          </CompanyProvider>
        </TourProvider>
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <SignInRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>
  </ThemeProvider>
)

export default App
