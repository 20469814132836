import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from "i18next-http-backend";
import translationEN from '../locales/en/translation.json';
import translationFR from '../locales/fr/translation.json';
import { MRT_Localization } from 'material-react-table';
import {MRT_Localization_EN} from 'material-react-table/locales/en'
import {MRT_Localization_FR} from 'material-react-table/locales/fr'
const resources = {
    en: {
      translation: translationEN
    },
    fr :{
      translation: translationFR
    },
  };


i18n
.use(Backend)    
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

  });

export const getReactTableLocalisation  = ():MRT_Localization=>{
  return i18n.language == "fr" ? MRT_Localization_FR : MRT_Localization_EN
}

export default i18n;