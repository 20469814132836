import React, { useState, useEffect, useContext } from 'react'
import { Grid, Tag } from '../libs/types';
import { AuthContext } from './authContext'
import Services from '../service/services';
import { CompaniesContext } from './companyContext';

export interface ITag {
  tags: Tag[]
  getCompany?: () => Grid
  refresh: () => void
}

const defaultState: ITag = {
  tags: [],
  refresh: () => { }
}

type Props = {
  children?: React.ReactNode
}

export var TagContext = React.createContext(defaultState)

const TagProvider = ({ children }: Props) => {
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const [tagData, setTagData] = useState(defaultState.tags)
  const companies = useContext(CompaniesContext).nonTechnicalCompanies;
  const state: ITag = {
    tags: tagData,
    refresh: async () => {
      setTagData(await fetch())
    }
  }

  const fetch = async (): Promise<Tag[]> => {
    try {
      const tags: Tag[] = []
      for (let company of companies) {
        tags.push(...await services.listTags(company.id))
      }
      tags.sort((a, b) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name))
      return tags
    } catch (err) {
      console.log(err)
      return []
    }

  }
  useEffect(() => {
    async function fetchData() {
      setTagData(await fetch())
    }
    fetchData()
  }, [companies])
  return <TagContext.Provider value={state}>{children}</TagContext.Provider>
}

export default TagProvider
