import { useState, useEffect } from 'react'

import * as yup from 'yup'

const passwordRegexp = /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
export const useValidEmail = (initialValue: string) => {
  const [email, setEmail] = useState(initialValue)
  const [emailIsValid, setEmailIsValid] = useState(true)

  useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required(),
    })

    if (email.length === 0) {
      setEmailIsValid(true)
      return
    }

    const isValid = emailSchema.isValidSync({ email })

    setEmailIsValid(isValid)
  }, [email])

  return { email, setEmail, emailIsValid }
}

export const useValidPassword = (initialValue: string) => {
  const [password, setPassword] = useState(initialValue)
  const [passwordIsValid, setPasswordIsValid] = useState(true)

  useEffect(() => {
    const passwordSchema = yup.object().shape({
     // password: yup.string().min(8).required(),
   
     password: yup.string()
          .required()
          .matches(passwordRegexp, 'Name is not valid'),

    })

    if (password.length === 0) {
      setPasswordIsValid(true)
      return
    }

    const isValid = passwordSchema.isValidSync({ password })

    setPasswordIsValid(isValid)
  }, [password])

  return { password, setPassword, passwordIsValid }
}

export const useValidUsername = (initialValue: string) => {
  const [username, setUsername] = useState(initialValue)
  const [usernameIsValid, setUsernameIsValid] = useState(true)

  useEffect(() => {
    const usernameSchema = yup.object().shape({
      username: yup.string().min(4).required(),
    })

    if (username.length === 0) {
      setUsernameIsValid(true)
      return
    }

    const isValid = usernameSchema.isValidSync({ username })

    setUsernameIsValid(isValid)
  }, [username])

  return { username, setUsername, usernameIsValid }
}


export const useValidName = (initialValue: string) => {
  const [name, setname] = useState(initialValue)
  const [nameIsValid, setnameIsValid] = useState(true)

  useEffect(() => {
    const nameSchema = yup.object().shape({
      name: yup.string().min(2).required(),
    })

    if (name.length === 0) {
      setnameIsValid(true)
      return
    }

    const isValid = nameSchema.isValidSync({ name })

    setnameIsValid(isValid)
  }, [name])

  return { name, setname, nameIsValid }
}
export const useValidLastName = (initialValue: string) => {
  const [lastName, setLastName] = useState(initialValue)
  const [lastNameIsValid, setLastNameIsValid] = useState(true)

  useEffect(() => {
    const lastNameSchema = yup.object().shape({
      lastName: yup.string().min(2).required(),
    })

    if (lastName.length === 0) {
      setLastNameIsValid(true)
      return
    }

    const isValid = lastNameSchema.isValidSync({ lastName })

    setLastNameIsValid(isValid)
  }, [lastName])

  return { lastName, setLastName, lastNameIsValid }
}

export const useValidCode = (initialValue: string) => {
  const [code, setCode] = useState(initialValue)
  const [codeIsValid, setCodeIsValid] = useState(true)

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    })

    if (code.length === 0) {
      setCodeIsValid(true)
      return
    }

    const isValid = codeSchema.isValidSync({ code })

    setCodeIsValid(isValid)
  }, [code])

  return { code, setCode, codeIsValid }
}
