import axios from 'axios'
import { BASE_URL } from './conf'
import { Address, ChargePoint, User } from '../libs/types'
import fileDownload from 'js-file-download'

export async function getAllChargePointsforCompany(authToken: string, companyID: string): Promise<ChargePoint[]> {
    return new Promise(function (resolve, reject) {
        axios.get(BASE_URL + '/chargepoints/company/' + companyID, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {

                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var cps: ChargePoint[] = []
                res.data.forEach((cp: ChargePoint) => {
                    cps.push(cp)
                }

                )
                resolve(cps)
            }).catch(e => reject(e))
    })
}

export async function getAllChargePoints(authToken: string): Promise<ChargePoint[]> {
    return new Promise(function (resolve, reject) {
        axios.get(BASE_URL + '/chargepoints', {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {

                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var cps: ChargePoint[] = []
                res.data.forEach((cp: ChargePoint) => {
                    cps.push(cp)
                }

                )
                resolve(cps)
            }).catch(e => reject(e))
    })
}

export async function createChargePoint(authToken: string, address: Address, deviceID: String, name: String): Promise<String> {
    return new Promise(function (resolve, reject) {
        axios.post(
            `${BASE_URL}/chargepoints`,
            {
                deviceID,
                name,
                address
            },
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((res: any) => {
                resolve(res.data.chargePoint.id)
            })
            .catch(e => 
                reject(e))
    })
}


export async function updateChargePoint(authToken: string, cp: ChargePoint): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.put(
            `${BASE_URL}/chargepoints/${cp.id}`,
            cp,
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}


export async function getAllChargePointsforUser(authToken: string, user: User): Promise<ChargePoint[]> {
    
    return new Promise(function (resolve, reject) {
        if (!user.companyID || !user.userName) {
            resolve([])
        }
        axios.get(`${BASE_URL}/chargepoints/company/${user.companyID}/user/${user.userName}`, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {

                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var cps: ChargePoint[] = []
                if (res.data){
                    res.data.forEach((cp: ChargePoint) => {
                        cps.push(cp)
                    })
                    resolve(cps)
                }resolve ([])
                
            })
            .catch(e => reject(e))
    })

}

export async function getChargePoint(id: String): Promise<ChargePoint> {
    return new Promise(function (resolve, reject) {
        axios.get(`${BASE_URL}/chargepoints/${id}`, {
            responseType: 'json',

        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    return reject("no chargepoint found")
                }
                resolve(res.data)
            })
            .catch(e => reject(e))
    })

}

export async function setNonDocGrid(
    authToken: string,
    cp: ChargePoint,
    gridID: string,
    periods: Date[][],
    HCF: number | null,
    HPF: number | null
): Promise<void> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        const opts: string[] = []
        for (const period of periods) {
            var s = period[0].getHours() + ":" + period[0].getMinutes()
            var e = period[1].getHours() + ":" + period[1].getMinutes()
            opts.push(s + "-" + e)
        }
        params.append("gridOpts", JSON.stringify(opts))
        if (HPF) {
            params.append("HPF", "" + HPF)
        }
        if (HCF) {
            params.append("HCF", "" + HCF)
        }
        axios.patch(BASE_URL + `/chargepoints/${cp.id}/grid/${gridID}`, {
            headers: {
                Authorization: authToken
            },
            params,
            responseType: 'json'
        })
            .then((res: any) => {
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}






export async function uploadGridDocLink(cp: ChargePoint,
    fileExt: string,
    gridID: string,
    periods: Date[][],
    HCF: number | null,
    HPF: number | null,

): Promise<PresignedPostUrlResponse> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        params.append("ext", fileExt)
        params.append("gridID", gridID)
        const opts: string[] = []
        for (const period of periods) {
            var s = period[0].getHours() + ":" + period[0].getMinutes()
            var e = period[1].getHours() + ":" + period[1].getMinutes()
            opts.push(s + "-" + e)
        }
        params.append("gridOpts", JSON.stringify(opts))
        if (HPF) {
            params.append("HPF", "" + HPF)
        }
        if (HCF) {
            params.append("HCF", "" + HCF)
        }
        axios.get(BASE_URL + `/chargepoints/${cp.id}/grid/uploadLink`, {
            headers: {
                Accept: '*/*',
            },
            params,
            responseType: 'json'
        })

            .then((res: any) => {
                resolve(res.data)
            })
            .catch(e => reject(e))
    })

}

type PresignedPostUrlResponse = {
    url: string;
    fields: {
        key: string;
        acl: string;
        bucket: string;
    };
    filePath: string;
};



export async function uploadToS3({ file, cp, gridID, periods, HCF = null, HPF = null }: {
    file: File;
    cp: ChargePoint;
    gridID: string;
    periods: Date[][];
    HCF: number | null;
    HPF: number | null;
}): Promise<void> {
    return new Promise(async function (resolve, reject) {
        try {
            const presignedPostUrl = await uploadGridDocLink(cp, file.name?.split('.')?.pop()!, gridID, periods, HCF, HPF);
            const formData = new FormData();
            //formData.append('Content-Type', file.type);
            Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
                formData.append(k, v);
            });
            formData.append('file', file); // The file has be the last element

            const response = await axios.post(presignedPostUrl.url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            resolve()
        } catch (e) {
            console.log(e)
            //TODO chase the axios bug
            resolve()
            //reject(e)
        }
    })
}
export async function downladGridDoc(cp: ChargePoint): Promise<void> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        axios.get(BASE_URL + `/chargepoints/${cp.id}/grid/doc`, {
            headers: {
                Accept: 'application/pdf',
                // ContentType :'application/pdf',
            },
            responseType: 'blob',
        }).then((res: any) => {
            const fileName = res.headers['content-disposition'] ? res.headers['content-disposition'].split('filename=')[1] : "bill.pdf"
            fileDownload(res.data, fileName.replace(/"/g, ''))
        })
            .catch(e => reject(e))
    })
}



