import React, { useState, useEffect, useContext } from 'react'
import { Grid } from '../libs/types';
import { list } from '../service/grid';
import { AuthContext } from './authContext'


export interface IGrid {
  grids: Grid[]
  gridMap: Map<string, Grid>
  getCompany?: () => Grid
  refresh: () => void
}

const defaultState: IGrid = {
  grids: [],
  gridMap: new Map<string, Grid>(),
  refresh: () => { }
}

type Props = {
  children?: React.ReactNode
}
export var GridContext = React.createContext(defaultState)


const GridProvider = ({ children }: Props) => {
  const auth = useContext(AuthContext)
  const [gridData, setgridData] = useState(defaultState.grids)

  const state: IGrid = {
    grids: gridData,
    gridMap: new Map(gridData.map(grid => [grid.id, grid])),
    refresh: async () => {
      setgridData(await fetch())
    }
  }
  
  const fetch = async (): Promise<Grid[]> => {
    try {
      let grids  = await list(auth.sessionInfo?.accessToken || "")
      grids.sort((a, b) => a.provider.localeCompare(b.provider) || a.name.localeCompare(b.name))
      return grids
    } catch (err) {
      return []
    }

  }
  useEffect(() => {
    async function fetchData() {
      setgridData(await fetch())
    }
    fetchData()
  }, [setgridData])


  return <GridContext.Provider value={state}>{children}</GridContext.Provider>
}

export default GridProvider
