import React, { useState, useEffect, useContext } from 'react'

import * as cognito from '../libs/cognito'
import { User } from '../libs/types';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string,
    isAdmin?: boolean,
    fleetManagerProfile?: any,
    isEndUser?: boolean
    companyID?: string
    language?: string
  }
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  createPassword?: any
  getAttributes?: any
  setAttribute?: any
  User?: User | any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
}

type Props = {
  children?: React.ReactNode
}

export var AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus,sessionInfo }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn && sessionInfo?.accessToken? children : null}</>
}



export const IsAdmin = ({ children }: Props) => {

  const { sessionInfo }: IAuth = useContext(AuthContext)

  return <>{sessionInfo?.isAdmin ? children : null}</>
}

export const IsEndUser = ({ children }: Props) => {
  const { sessionInfo }: IAuth = useContext(AuthContext)
  return <>{sessionInfo?.isEndUser ? children : null}</>
}

export const IsFleetManager = ({ children }: Props) => {
  const { sessionInfo }: IAuth = useContext(AuthContext)

  return <>{sessionInfo?.fleetManagerProfile ? children : null}</>
}


export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const [attrInfo, setAttrInfo] = useState([])
  const [User, setUser] = useState({})


  useEffect( () => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession()

        window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
        window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
        const attr: any = await getAttributes()
        let isAdmin = false
        let isEndUser = false
        let fleetManagerProfile: any
        attr.forEach((i: { Name: string; Value: string }) => {
          if (i.Name === "custom:profile") {
            let o = JSON.parse(i.Value)
            switch (o.type) {
              case "admin":
                isAdmin = true
                break;
              case "fleetManager":
                fleetManagerProfile = { company: o.value }
                break;
              case "endUser":
                isEndUser = true
                break;

            }

          }
        })
        var u = fromAttribute(attr)
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          isAdmin: isAdmin,
          isEndUser: isEndUser,
          fleetManagerProfile: fleetManagerProfile,
          companyID: u.companyID,
          email: u.email,
          language: u.language
        })
        setUser(u)
        setAttrInfo(attr)
        setAuthStatus(AuthStatus.SignedIn)
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut)
      }
    }
     getSessionInfo()
  }, [setAuthStatus, authStatus])

  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function signUpWithEmail(name: string, lastName: string, username: string, email: string, password: string) {
    try {
      await cognito.signUpUserWithEmail(name, lastName, username, email, password)
    } catch (err) {
      throw err
    }
  }

  function signOut() {
    cognito.signOut()
    AuthContext = React.createContext(defaultState)
    setSessionInfo({})
    setAttrInfo([])
    setAuthStatus(AuthStatus.SignedOut)
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }



  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }
  async function createPassword(username: string, code: string, password: string) {
    try {
      await cognito.createPassword(username, code, password)
    } catch (err) {
      throw err
    }
  }

  function fromAttribute(attrs: any): User {
    let u: any = {
      userName: cognito.getCurrentUser().username,
      enabled: true
    }
    attrs.forEach((attr: any) => {
      switch (attr.Name) {
        case 'name':
          u.firstName = attr.Value
          break;
        case 'family_name':
          u.lastName = attr.Value
          break;
        case 'custom:profile':
          u.profile = JSON.parse(attr.Value)
          break;
        case 'custom:chargePoints':
          u.chargePoints = JSON.parse(attr.Value)
          break;
        case 'UserStatus':
          u.status = attr.Value
          break;
        case 'custom:companyID':
          u.companyID = attr.Value
          break;
        case 'custom:chargePoints':
          u.chargePoints = JSON.parse(attr.Value)
          break;
       case 'email':
          u.email = attr.Value
          break;
        case 'locale':
          u.language = attr.Value
          break;
      }
    });
    return u
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    User,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    createPassword,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
  }
  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
