

//create a recat component allowing to choose betewween 2 lang (fr and en)
import React from 'react';
import { Avatar, Grid, IconButton } from '@mui/material';
import frFlag from '../locales/fr/flag.png'
import enFlag from '../locales/en/flag.png'
import frFlagGrey from '../locales/fr/flag_grey.png'
import enFlagGrey from '../locales/en/flag_grey.png'
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    verticalCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

type LangSelectorProps = {
    userLanguage: string;
    setuserLanguage: (language: string) => void;
};

const LangSelector = ({ userLanguage, setuserLanguage }: LangSelectorProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container direction={"row"}>
            <Grid item xs={3} className={classes.verticalCenter}>
                <Typography>{t("language")}</Typography>
            </Grid>
            <Grid item xs={8} className={classes.verticalCenter}>
                <IconButton onClick={e => { setuserLanguage('fr') }} sx={{ p: 0 }}>
                    <Avatar alt="Francais" src={userLanguage == 'fr' ? frFlag : frFlagGrey} sx={{ height: '30px', width: '30px' }} />
                </IconButton>
                <IconButton onClick={e => { setuserLanguage('en') }} sx={{ p: 1 }}>
                    <Avatar alt="English" src={userLanguage == 'fr' ? enFlagGrey : enFlag} sx={{ height: '30px', width: '30px' }} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default LangSelector;
