import axios from 'axios'
import { BASE_URL } from './conf'
import { Car, CarCharge } from '../libs/types'

export async function getAllCarsCompany(authToken: string, companyID: string): Promise<Car[]> {
    return new Promise(function (resolve, reject) {
        axios.get(BASE_URL + '/cars/company/' + companyID, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var cars: Car[] = []
                res.data.forEach((car: any) => {
                    if (car.lastCharge) {
                        car.lastCharge = new Date(car.lastCharge * 1000)
                    }
                    cars.push(car)

                })
                resolve(cars)
            }).catch(e => reject(e))
    })


}


export async function getCarChargeS(car: Car): Promise<CarCharge[]> {
    return new Promise(function (resolve, reject) {
        axios.get(`${BASE_URL}/cars/${car.VIN}/charges`, {
            responseType: 'json',
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var charges: CarCharge[] = []
                res.data.forEach((charge: CarCharge) => charges.push(charge))
                resolve(charges)
            }).catch(e => reject(e))
    })
}

export async function createCar(authToken: string, car: Car): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.post(BASE_URL + `/cars/company/${car.companyID}`,
            car, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}

export async function updateCar(authToken: string, car: Car): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.put(BASE_URL + `/cars/company/${car.companyID}`,
            car,
            {
                responseType: 'json',
                headers: {
                    Authorization: authToken
                }
            })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}

