import axios from 'axios'
import { BASE_URL } from './conf'
import { ChargePointStats, Company, CompanyStats, UserStats } from '../libs/types';

export async function getCpStat(userName: string, start: Date | null, end: Date | null): Promise<ChargePointStats> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        if (start) {
            params.append('start', String(Math.floor(new Date(start).getTime() / 1000)))
        }
        if (end) {
            params.append('end', String(Math.floor(new Date(end).getTime() / 1000)))
        }
        axios.get(BASE_URL + `/stats/user/${userName}`, {
            params,
            responseType: 'json',
        })
            .then((res: any) => {
                resolve(res.data)
            }).catch(e => reject(e))
    })
}




export async function getUserStat(userName: string, start: Date | null, end: Date | null): Promise<UserStats> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        if (start) {
            params.append('start', String(Math.floor(new Date(start).getTime() / 1000)))
        }
        if (end) {
            params.append('end', String(Math.floor(new Date(end).getTime() / 1000)))
        }
        axios.get(BASE_URL + `/stats/user/${userName}`, {
            params,
            responseType: 'json',
        })
            .then((res: any) => {
                resolve(res.data)
            }).catch(e => reject(e))
    })
}


export async function getCompanyStats(authToken: string, company: Company, start: Date | null, end: Date | null): Promise<CompanyStats> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        if (start) {
            params.append('start', String(Math.floor(new Date(start).getTime() / 1000)))
        }
        if (end) {
            params.append('end', String(Math.floor(new Date(end).getTime() / 1000)))
        }
        axios.get(BASE_URL + `/stats/company/${company.id}`, {
            params,
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                resolve(res.data)
            }).catch(e => reject(e))
    })
}

