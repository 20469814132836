import { useContext, useMemo, useState } from 'react'
import toast, { Toaster } from "react-hot-toast";
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Company } from '../libs/types'
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef } from 'material-react-table';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Typography } from '@material-ui/core'
import { Stack } from '@mui/material'
import { CompaniesContext } from '../contexts/companyContext'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import bgF2m from '../ressources/backgroundf2m.png';
import Services from '../service/services'
import { AuthContext } from '../contexts/authContext'
import SaveIcon from '@mui/icons-material/Save';
import {
  Edit as EditIcon,
  Add as AddIcon
} from '@mui/icons-material';
import frFlag from '../locales/fr/flag.png';
import enFlag from '../locales/en/flag.png';
import Avatar from '@mui/material/Avatar';
import LangSelector from '../components/langSelector';
import ts from 'typescript';


var data: Company[];

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
  },
  spaced: {
    marginLeft: '15px',
    marginRight: '15px',

  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
  spacedTop: {
    marginTop: "30px"
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vvw',
    backgroundColor: 'white',
    border: '2px solid #000',
  }
}))

export default function CompanyPage() {
  const classes = useStyles()
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const companies = useContext(CompaniesContext)
  const companyMap = useMemo<Map<string, Company>>(() => (companies.companyMap), [companies.companies])
  const { t, i18n } = useTranslation();
  const userColumn = useMemo<MRT_ColumnDef<Company>[]>(() => columnFactory(t), [i18n.language])
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const [companyToEdit, setcompanyToEdit] = useState(null as (Company | null))



  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="column" >
          <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
            <Toaster />
            <MaterialReactTable
              enableEditing
              //onEditingRowSave={handleSaveRowEdits}
              //onEditingRowCancel={handleCancelRowEdits}
              renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <IconButton onClick={() => {
                    setcompanyToEdit(row.original)
                    setCreateModalOpen(true)
                  }
                  } >
                    <EditIcon />
                  </IconButton>

                </Box>
              )}
              enableRowActions
              columns={userColumn}
              data={companies.getAllCompanies() || []}
              enableColumnOrdering={false}
              enableHiding={false}
              enableDensityToggle={false}
              enableGlobalFilter={true} 
              renderDetailPanel={row => {
                return CompanyDetailPanel(companies.getAllCompanies()[row.row.index])
              }
              }
              renderTopToolbarCustomActions={() => (
                <Button
                  color="secondary"
                  onClick={() => {
                    setcompanyToEdit(null)
                    setCreateModalOpen(true)
                  }}
                  variant="contained"
                >
                  +
                </Button>
              )}
            />
            <br />
          </Box>
        </Grid>
      </Grid>
      <CreateModal
        key={Math.random()}
        selectedCompany={companyToEdit}
        columns={userColumn}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={async (c) => {
          if (companyToEdit) {
            c.id = companyToEdit.id
            await services.updateCompany(c)
            companies!.refresh()
          } else {
            await services.createCompany(c)
            companies!.refresh()
          }
        }}
        title={t("createCompany")}
        confirmButtonCaption={companyToEdit ? t("companyEditConfirm") : t("companyCreateConfirm")}
      />
    </Grid>
  )
}


const columnFactory = (t: (s: string) => string): MRT_ColumnDef<Company>[] => [
  {
    accessorKey: 'name',
    header: t('name'),
  },
  {
    accessorKey: 'adress',
    header: t('address'),
  },
  {
    accessorKey: 'zipCode',
    header: t('zipCode'),
  },
  {
    accessorKey: 'city',
    header: t('city'),
  },
  {
    accessorKey: 'notificationEmail',
    header: t('notificationEmail'),
  },
  {
    accessorKey: 'notificationLanguage',
    header: t('language'),
    Cell: cell => (

      <Box component="span" >
        {cell.row.original.notificationLanguage == 'fr' ?
          <IconButton >
            <Avatar alt="Francais" src={frFlag} sx={{ height: '20px', width: '20px' }} />
          </IconButton>
          :
          <IconButton>
            <Avatar alt="English" src={enFlag} sx={{ height: '20px', width: '20px' }} />
          </IconButton>}
      </Box>
    )
  }



]
interface CreateModalProps {
  columns: MRT_ColumnDef<Company>[];
  onClose: () => void;
  onSubmit: (values: Company) => void;
  selectedCompany: Company | null;
  open: boolean;
  title: string
  confirmButtonCaption: string
}

//example of creating a mui dialog modal for creating new rows
export const CreateModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  selectedCompany,
  title,
  confirmButtonCaption
}: CreateModalProps) => {

  var [values, setValues] = useState<any>(() => {
    if (selectedCompany) {
      return selectedCompany
    }
    return columns.reduce((acc, column) => {
      // @ts-ignore 
      acc[column.accessorKey ?? ''] = '';
      return acc;
    })
  }

  ) as any
  const
    handleSubmit = () => {
      onSubmit(values);
      onClose();
    };

  return (
    <Dialog open={open}>
      <DialogTitle /*textAlign="center"*/>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => {
              switch (column.accessorKey) {
                case 'notificationLanguage':
                  return (
                    <LangSelector
                      userLanguage={values[column.accessorKey]}
                      setuserLanguage={(language: string) => setValues({ ...values, ["notificationLanguage"]: language })}
                    />
                  )
                default:

                  return <TextField
                    disabled={column.accessorKey === 'name' && selectedCompany !== null}
                    label={column.header}
                    name={column.accessorKey}
                    //@ts-ignore
                    value={values[column.accessorKey]}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />
              }
            }
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions /*sx={{ p: '1.25rem' }}*/>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          {confirmButtonCaption}
        </Button>
      </DialogActions>
    </Dialog>

  );

};

function CompanyDetailPanel(company: Company) {
  if (!company.options) {
    company.options = {
      companyOnlyGrid: false,
      nonCompanyCarEnable: false
    }
  }
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const [companyOnlyGrid, setCompanyOnlyGrid] = useState<boolean>(company.options.companyOnlyGrid)
  const [nonCompanyCarEnable, setNonCompanyCarEnable] = useState<boolean>(company.options.nonCompanyCarEnable)
  return (
    <>

      <Typography>Options</Typography>
      <Grid container direction="row" spacing={1} justify="center">
        <Grid item xs={4}>
          <FormControlLabel control={
            <Checkbox
              checked={companyOnlyGrid}
              onChange={(e) => {
                setCompanyOnlyGrid(e.target.checked)

              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />}
            label={t("companyOnlyGrids")} />
          <FormControlLabel control={
            <Checkbox
              checked={nonCompanyCarEnable}
              onChange={(e) => {
                setNonCompanyCarEnable(e.target.checked)
              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />}
            label={t("nonCompanyCarEnable")} />
        </Grid>
        <Grid item xs={8}>
        </Grid>
        <Grid item>
          <Button
            color={"secondary"}
            //className={classes.oneLineButton}
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={async () => {
              try {
                company.options.companyOnlyGrid = companyOnlyGrid
                company.options.nonCompanyCarEnable = nonCompanyCarEnable
                await services.updateCompany(company);
                toast.success(t("changesSaved"))
              } catch (e) {
                toast.error(t("errorSaving"))
              }
            }
            }
          >
            {t("save")}
          </Button>
        </Grid>
      </Grid>



    </>
  )
}