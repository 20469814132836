

//cre   te a recat component in typescript
//this component will be a modal player that will play the video when clicked
//videoplayer must Reactplayer and the vide url is a parameter to the component
//the modal must have a close button to close the modal
//the model musyt be centered and have a width of 80% and height of 80%
//and use Modal  from material ui
//the modal must be a functional component

import React from 'react';
import ReactPlayer from 'react-player';
import { Modal, Button, DialogContent, Dialog, DialogActions, makeStyles, Typography, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface ModalPlayerProps {
  introText: string[];
  open: boolean;
  onClose: () => void;
  urls: string[];
  urlsTitle: string[];
}
const useStyles =  makeStyles((theme) =>({
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },

  modalStyle1: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block'
  },
  verticalCenter: {
    margin: 'auto',
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px"
  },
  spacedTop: {
    marginTop: "5%"
  },
}))

const ModalPlayer: React.FC<ModalPlayerProps> = ({ open, onClose, urls, urlsTitle, introText }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"} className={classes.modalStyle1}>
      <DialogTitle ><span className={classes.popupTitle}>{t("demoSessionTitle")}</span></DialogTitle>
      <DialogContent>
        {introText.map(text =>
        (
          <>
            <Typography>
              {t(text)}
            </Typography>

          </>
        ))}
        <br />
        {
          urls.map((url, index) => (
            <>
              <Typography >
                {t(urlsTitle[index]) + ":"}
              </Typography>
              <ReactPlayer url={url}
                quality="high"
                // width="80%"
                controls={true}
              />
              <br />
            </>
          ))
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}


export default ModalPlayer;