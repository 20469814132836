


import axios from 'axios'
import { BASE_URL } from './conf'
import { Grid } from '../libs/types'
import { AuthContext } from '../contexts/authContext'

export async function list(authtoken: string): Promise<Grid[]> {

    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        axios.get(BASE_URL + `/grids`, {
            params,
            responseType: 'json',
            headers: {
                'Authorization': authtoken
            }
        })
            .then((res: any) => {
                if (!res.data) {
                    resolve([])
                }
                var r: Grid[] = []
                res.data.forEach((grid: Grid) => {
                    r.push(JSON.parse(JSON.stringify(grid)))
                    for (var idx in grid.formulaGroups) {
                        r[r.length - 1].formulaGroups[idx].start = new Date(Number(grid.formulaGroups[idx].start) * 1000)
                        r[r.length - 1].formulaGroups[idx].end = new Date(Number(grid.formulaGroups[idx].end) * 1000)
                    }
                    return r
                })
                resolve(r)
            }).catch(e => reject(e))
    })
}

export async function getGrid(authtoken: string, id: string): Promise<Grid | null> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        axios.get(BASE_URL + `/grids/${id}`, {
            params,
            responseType: 'json',
            headers: {
                'Authorization': authtoken
            }
        })
            .then((res: any) => {
                if (!res.data) {
                    resolve(null)
                }
                resolve(res.data)
            }).catch(e => reject(e))
    })
}


export async function deleteGrid(authtoken: string,id: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        axios.delete(BASE_URL + `/grids/${id}`, {
            params,
            responseType: 'json',
            headers: {
                'Authorization': authtoken
            }
        })
            .then((res: any) => {
                resolve()
            }).catch(e => reject(e))
    })
}


export async function createGrid(authtoken: string, grid: Grid): Promise<void> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        axios.post(BASE_URL + `/grids`, grid, {
            params,
            responseType: 'json',
            headers: {
                'Authorization': authtoken
            }
        })
            .then((res: any) => {
                resolve()
            }).catch(e => reject(e))
    })
}

export async function updateGrid(authtoken: string, grid: Grid): Promise<void> {
    var data: any = JSON.parse(JSON.stringify(grid))
    for (var idx in grid.formulaGroups) {
        data.formulaGroups[idx].start = new Date(grid.formulaGroups[idx].start).valueOf() / 1000
        data.formulaGroups[idx].end = new Date(grid.formulaGroups[idx].end).valueOf() / 1000
    }

    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        axios.put(BASE_URL + `/grids/${grid.id}`, data, {
            params,
            responseType: 'json',
            headers: {
                'Authorization': authtoken
            }
        })
            .then((res: any) => {
                resolve()
            }).catch(e => reject(e))
    })
}


export async function computeMockSession(authtoken: string, params: any): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.post(BASE_URL + `/grids/test`,
            params, {
            responseType: 'json',
            headers: {
                'Authorization': authtoken
            }
        })
            .then((res: any) => {
                resolve(res.data)
            }).catch(e => reject(e))
    })
}