import { Box, Button, IconButton, Tooltip } from "@material-ui/core"
import { t } from "i18next"
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table"
import { useContext, useEffect, useMemo, useState } from "react"
import i18n from "../../libs/i18n"
import { FirmWare } from "../../libs/types"
import { AuthContext } from '../../contexts/authContext'
import { downladFw, getallFw } from "../../service/firmwares"
import { Download as DownloadIcon } from '@mui/icons-material';

export default function FirmwareList({ onCreateClick
}: {
    onCreateClick: () => void
}) {
    const auth = useContext(AuthContext)
    const firmwareColumns = useMemo<MRT_ColumnDef<FirmWare>[]>(() => firmwareColumnFactory(t, auth.sessionInfo?.isAdmin), [i18n.language])
    var [statedata, setstatedata] = useState([] as FirmWare[]);

    var refresh = async () => {
        var fws: FirmWare[] = []
        fws = await getallFw()
        setstatedata(fws)
    }

    useEffect(() => {
        refresh()
    }, [])

    return (
        <MaterialReactTable
            initialState={{ density: "compact" }}
            enableRowActions
            columns={firmwareColumns}
            data={statedata}
            enableColumnOrdering={false}
            enableHiding={false}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Tooltip title="Justificatif">
                        <IconButton onClick={() => downladFw(row.original)}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            enableDensityToggle={false}
            enableGlobalFilter={false} //turn off a feature
            renderTopToolbarCustomActions={() => (
                <Tooltip title={t('createFirmware')}>
                    <Button
                        color="secondary"
                        onClick={onCreateClick}
                        variant="contained"
                    >
                        +
                    </Button>
                </Tooltip>
            )}

        />

    )

}


const firmwareColumnFactory = (t: (s: string) => string, withCompany: boolean = false): MRT_ColumnDef<FirmWare>[] => {
    var columns: MRT_ColumnDef<FirmWare>[] = []
    columns.push(
        {
            accessorKey: 'name',
            header: "firmware",
            enableEditing: false
        },
    )
    return columns

}