
import Chart from "react-apexcharts";
import { Round } from "../libs/utils";




export interface ChartValue {
  ts: number,
  value: number
}

const getSeries = (values: ChartValue[], startGauge: number | null, endGauge: number | null): ({ ts: number, valueIndex: number, valueGauge: number }[]) => {
  let resIndex: { ts: number, valueIndex: number, valueGauge: number }[] = []

  let firstValue =  null as any
  values.forEach(value => {
    if (value.value!=undefined) {
      if (firstValue==null) {
        firstValue = value.value
      }
    }
  })


  values.forEach(value => {
    if (value.value != undefined) {

      let d = new Date(Number(value.ts) * 1000)
      let v = {
        ts: value.ts * 1000,//`${d.getHours()} : ${d.getMinutes()}`,
        valueIndex:  Round(value.value.valueOf() - firstValue.valueOf(),2),
        valueGauge: 0
      }
      if (startGauge && endGauge) {
        v.valueGauge = Round(v.valueIndex/firstValue.valueOf() * (endGauge - startGauge),2)

      }
      resIndex.push(v)

    }
  }
  )
  return resIndex
}


export function LineChart({ values, width, height, startGauge, endGauge, annotations = [] }: {
  values: ChartValue[],
  width: number,
  height: number,
  startGauge: number | null,
  endGauge: number | null,
  annotations?: any[]
}) {
  let xano: XAxisAnnotations[] | undefined
  if (annotations.length > 0) {

    xano = annotations.map(annotation => {
      return {
        x: annotation.start,//`${new Date(annotation.start*1000).getHours()} : ${new Date(annotation.start*1000).getMinutes()}` , //${d.getHours()} : ${d.getMinutes()}
        x2: annotation.end,//`${new Date(annotation.end*1000).getHours()} : ${new Date(annotation.end*1000).getMinutes()}`,
        fillColor: annotation.label == "HP" ? 'red' : '#B3F7CA',
        opacity: 0.4,
        label: {
          borderColor: '#B3F7CA',
          style: {
            fontSize: '10px',
            color: '#fff',
            background: '#00E396',
          },
          //offsetY: -10,
          text: annotation.label,
        }
      }
    })


  }
  let serie = getSeries(values, startGauge, endGauge)
  let cat = serie.map(s => s.ts)
  let valIndex = serie.map(s => s.valueIndex)
  let valueGauge = serie.map(s => s.valueGauge)
  let series = [
    {
      name: "kwh",
      data: valIndex
    }

  ]
  if (startGauge && endGauge) {
    series.push({
      name: "Gauge %",
      data: valueGauge
    })
  }
  let yaxis = [{
    title: {
      text: "kwh"
    },
    min: Math.min(...valIndex),
    max: Math.max(...valIndex),
  }] as any
  if (startGauge && endGauge) {
    yaxis.push({
      opposite: true,
      title: {
        text: "Gauge %"
      },
      min: Math.min(...valueGauge),
      max: Math.max(...valueGauge),

    })
  }
  let opts ={
    annotations: {
      xaxis: xano
    },
    stroke: {
      curve: 'smooth',
    },

    chart: {
      animations: {
        enabled: true
      },
      id: "Indexes"
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: cat,
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM \'yy',
          day: 'dd MMM',
          hour: 'HH:mm'
        }
      }
    },
    yaxis: yaxis
  } as any
  console.log("opts", opts)
  return (
    <Chart
      options={opts}       
      series={series}

      type="line"
      width={width + "px"}
      height={height + "px"}
    />)
}