
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Typography from '@mui/material/Typography';
import Paper from '@material-ui/core/Paper'

import frFlag from '../locales/fr/flag.png';
import enFlag from '../locales/en/flag.png';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import bgF2m from '../ressources/backgroundf2m.png';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { theme } from '../libs/theme'
import { downloadNote } from '../service/creditNote';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/authContext'


const useStyles = makeStyles({
    root: {
        overflow: 'auto',
        height: '100vh',
        backgroundImage: `url(${bgF2m})`,
        backgroundSize: 'cover',
        backgroundColor: "#1e0046"

    },
    topR: {
        position: "absolute",
        marginTop: "20px",
        marginRight: "20px",
        top: 0,
        right: 0
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '1.5rem!important',
    },
    centerText: {
        textAlign: 'center'
    },
    center: {
        margin: 'auto'
    },
    hover: {
        '&:hover': { cursor: 'pointer' },
    },
})


export default function CreditNotesDlPage() {
    const companyID = new URLSearchParams(useLocation().search).get("c")
    const userName = new URLSearchParams(useLocation().search).get("u")
    const file = new URLSearchParams(useLocation().search).get("f")

    const history = useHistory()
    const classes = useStyles()
    const { t, i18n } = useTranslation();
    const auth = useContext(AuthContext)
    const [dlError, setdlError] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const isLoggedIn = auth.User && auth.User.userName
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };
    const handleDownload = () => {
        if (inProgress) {
            return
        }
        if (companyID && userName && file) {
            try {
                setInProgress(true)
                downloadNote(companyID, userName, file).then(_ =>
                    setInProgress(false)
                )
            } catch (e) {
                setdlError(true)
                console.error(e)
            }
        } else {
            history.push('/')
        }
    };

    useEffect(() => { handleDownload(); return }, []);
    return (
        <>

            <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
                <Box className={classes.topR}>

                </Box>
                {
                    isLoggedIn ? <></> :
                        <Box sx={{
                            display: 'inline-flex',
                            top: '20px',
                            position: 'absolute',
                            color: 'white'
                        }}>
                            <ElectricCarIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="/#/"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                HAYLECTRIC
                            </Typography>
                        </Box>
                }

                <Grid xs={12} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
                    <Paper style={{ width: '100%', padding: 32 }}>
                        <Grid container direction="row" justify="center" >
                            <Grid item xs={1} alignItems="center">
                            </Grid>
                            <Grid item xs={isLoggedIn ? 10 : 8} alignItems="center">
                                <Typography className={[classes.title, classes.centerText].join(' ')}>{t("downloadExpenseReport")}</Typography>
                            </Grid>
                            {
                                <></>
                                /*isLoggedIn ? <>
                                </> :
                                    <Grid item xs={2} style={{ right: '10px' }}>
                                        <IconButton onClick={e => { changeLanguage('fr') }} sx={{ p: 0 }}>
                                            <Avatar alt="Francais" src={frFlag} sx={{ height: '30px', width: '30px' }} />
                                        </IconButton>
                                        <IconButton onClick={e => { changeLanguage('en') }} sx={{ p: 1 }}>
                                            <Avatar alt="English" src={enFlag} sx={{ height: '30px', width: '30px' }} />
                                        </IconButton>
                                    </Grid>
                                    */
                            }
                            <Grid item xs={1} alignItems="center">
                            </Grid>
                            <Grid item xs={1} alignItems="center">
                            </Grid>
                            <Grid item xs={10} alignItems="center" >
                                <br />
                                {inProgress && (
                                    <span className={['loader', classes.center].join(' ')} style={{ width: '50px', display: "block" }}></span>
                                )

                                }
                                {
                                    !inProgress && !dlError && (
                                        <span className={[classes.center].join(' ')}   >
                                            <Typography className={[classes.centerText].join(' ')}>{t("downloadComplete")}</Typography>
                                        </span>

                                    )
                                }
                                {
                                    dlError && (
                                        <span className={[classes.center].join(' ')}   >
                                            <Typography className={[classes.centerText].join(' ')}>{t("errorDownload")}</Typography>
                                        </span>

                                    )

                                }

                                <br />
                            </Grid>
                            <Grid item xs={1} style={{ right: '10px' }}>

                            </Grid>


                            {/* Error */}


                            {/* Buttons */}

                        </Grid>


                    </Paper>
                </Grid>
            </Grid >
        </>








    )



}