import axios from 'axios'


import { BASE_URL } from './conf'
import { ChargePoint, User } from '../libs/types'


export async function getCompanyUsers(authtoken: string, companyID: string): Promise<User[]> {
    return new Promise(function (resolve, reject) {
        axios.get(`${BASE_URL}/users/company/${companyID}`, {
            responseType: 'json',
            headers: {
                'Authorization': authtoken
            }
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var users: User[] = []
                res.data.forEach((cognitoUser: any) => {
                    users.push({
                        userName: cognitoUser.userName,
                        companyID: cognitoUser.companyID,
                        profile: (cognitoUser.profile && cognitoUser.profile.type) || "endUser",
                        firstName: cognitoUser.firstName || "",
                        lastName: cognitoUser.lastName || "",
                        matricule: cognitoUser.matricule,
                        status: cognitoUser.status,
                        lastcreditNote: null,
                        enabled: cognitoUser.enabled,
                        chargePoints: cognitoUser.chargePoints,
                        email: cognitoUser.email,
                        language: cognitoUser.language,
                        restrictions: cognitoUser.restrictions,
                        tags: cognitoUser.tags

                    })
                }

                )
                resolve(users)
            })
            .catch(e => reject(e))
    })

}

export async function getAllUsers(authtoken: string): Promise<User[]> {
    return new Promise(function (resolve, reject) {
        axios.get(`${BASE_URL}/users`, {
            responseType: 'json',
            headers: {
                'Authorization': authtoken
            }
        })
            .then((res: any) => {

                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var users: User[] = []
                res.data.forEach((cognitoUser: any) => {
                    users.push({
                        userName: cognitoUser.userName,
                        companyID: cognitoUser.companyID,
                        profile: (cognitoUser.profile && cognitoUser.profile.type) || "endUser",
                        firstName: cognitoUser.firstName || "",
                        lastName: cognitoUser.lastName || "",
                        matricule: cognitoUser.matricule,
                        status: cognitoUser.status,
                        lastcreditNote: null,
                        enabled: cognitoUser.enabled,
                        chargePoints: cognitoUser.chargePoints,
                        email: cognitoUser.email,
                        language: cognitoUser.language,
                        restrictions: cognitoUser.restrictions,
                        tags: cognitoUser.tags
                    })
                })
                resolve(users)
            }).catch(e => reject(e))
    })
}

export async function disableUser(userName: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.patch(BASE_URL + `/users/${userName}/disable`, { responseType: 'json' })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}

export async function enableUser(userName: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.patch(BASE_URL + `/users/${userName}/enable`, { responseType: 'json' })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}

//TODO add checkuserName 
export async function invite(userName: string,
    email: string,
    firstName: string,
    lastName: string,
    cp: ChargePoint,
    locale: string
): Promise<void> {
    let body = {
        firstName,
        lastName,
        companyID: cp.companyID,
        chargePoints: [cp.id],
        locale,
        email
    }
    return new Promise(function (resolve, reject) {
        axios.post(BASE_URL + `/users/${userName}/invite`, body, { responseType: 'json' })
            .then((res: any) => {
                resolve()
            })
            .catch(e => {
                console.log(e)
                reject(e)
            })

    })
}


export async function createUser(authtoken: string, user: User): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.post(BASE_URL + `/users`,
            user,
            {
                responseType: 'json',
                headers: {
                    'Authorization': authtoken
                }


            }
        )
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}

export async function updateUser(authtoken: string, user: User): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.put(BASE_URL + `/users/${user.userName}`,
            user,
            {
                responseType: 'json',
                headers: {
                    'Authorization': authtoken
                }


            })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}