import { Grid, Typography, TextField, makeStyles } from "@material-ui/core"
import { useState } from "react"


const useStyles = makeStyles((theme) => ({
    spaced: {
        marginLeft: "15px",
        marginRight: "15px"
    },
    popupTitle: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "1.8rem!important"
    },
    verticalCenter: {
        marginTop: 'auto',
        marginBottom: 'auto'
    },
}))



interface Props {
    isDualTarif: boolean
    handleChange: (prices: { HPF: string, HCF: string }) => void
}


const FixedPriceSetter: React.FC<Props> = ({ handleChange, isDualTarif }) => {

    const [fixedPrices, setfixedPrices] = useState({ HPF: '0', HCF: '0' } as { HPF: string, HCF: string });
    const classes = useStyles()
    return (
        <Grid container direction="row" spacing={2} className={classes.verticalCenter}>
            <Grid item xs={4} className={classes.verticalCenter}>
                <Typography>Prix fixes:</Typography>
            </Grid>
            {isDualTarif && (
                <Grid item xs={4}>
                    <TextField
                        label="HC"
                        value={fixedPrices.HCF}
                        variant='outlined'
                        onChange={
                            (e) => {
                                setfixedPrices({ ...fixedPrices, HCF: e.target.value })
                                handleChange({ ...fixedPrices, HCF: e.target.value })
                            }
                        }
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            )

            }
            <Grid item xs={4}>
                <TextField
                    label="HP"
                    value={fixedPrices.HPF}
                    onChange={
                        (e) => {
                            setfixedPrices({ ...fixedPrices, HPF: e.target.value })
                            handleChange({ ...fixedPrices, HPF: e.target.value })
                        }
                    }
                    variant='outlined'
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

        </Grid>
    )
}

export default FixedPriceSetter;