import axios from 'axios'


import { BASE_URL } from './conf'
import { Device } from '../libs/types'

export async function getAllDevices(authToken: string): Promise<Device[]> {
    return new Promise(function (resolve, reject) {
        axios.get(`${BASE_URL}/devices`, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                resolve(res.data)
            }).catch(e => reject(e))
    })
}

export async function createDevice(authToken: string, device: Device): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.post(`${BASE_URL}/devices`, device, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}


export async function getCompanyDevices(authToken: string, companyID: string): Promise<Device[]> {
    return new Promise(function (resolve, reject) {
        axios.get(`${BASE_URL}/devices/company/${companyID}`, {
            responseType: 'json',
            headers: {
                Authorization: authToken
            }
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                resolve(res.data)
            }).catch(e => reject(e))
    })


}
