import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Car, Grid, Tag, User } from '../libs/types';
import { AuthContext } from './authContext'
import Services from '../service/services';
import { CompaniesContext } from './companyContext';

export interface IUsers {
  users: User[]
  refresh: () => void
  getMap(): Map<string, User>
  getCompanyMap(): Map<string, User[]>
}

const defaultState: IUsers = {
  users: [],
  refresh: () => { },
  getMap(): Map<string, User> { return new Map() },
  getCompanyMap(): Map<string, User[]> { return new Map() }

}

type Props = {
  children?: React.ReactNode
}

export var UsersContext = React.createContext(defaultState)

const UserProvider = ({ children }: Props) => {
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const [userData, setUserData] = useState(defaultState.users)
  const companies = useContext(CompaniesContext).nonTechnicalCompanies;

  const UserMap = useMemo(() => {
    const m = new Map<string, User>()
    userData.forEach((user) => {
      m.set(user.userName.toLowerCase(), user)
    })
    return m
  }, [userData])

  const companyUserMap = useMemo(() => {  
    const m = new Map<string, User[]>()
    userData.forEach((user) => {
      if (!m.has(user.companyID!)) {
        m.set(user.companyID!, [user])
      }
      else {
        m.get(user.companyID!)?.push(user)
      }
      
    })
    return m
  }
  , [userData])
  const state: IUsers = {
    users: userData,
    refresh: async () => {
      setUserData(await fetch())
    },
    getMap(): Map<string, User> {
      return UserMap
    }
    ,
    getCompanyMap(): Map<string, User[]> {
      return companyUserMap
    }
  }

  const fetch = async (): Promise<User[]> => {
    try {
      const users: User[] = []
      if (auth.sessionInfo && auth.sessionInfo?.isAdmin){
        users.push(...await services.getUsers())
        return users
      }
      for (let company of companies) {
        users.push(...await services.getCompanyUsers(company.id))
      }
      //cars.sort((a, b) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name))
      return users
    } catch (err) {
      console.log(err)
      return []
    }

  }
  useEffect(() => {
    async function fetchData() {
      setUserData(await fetch())
    }
    fetchData()
  }, [companies])
  return <UsersContext.Provider value={state}>{children}</UsersContext.Provider>
}

export default UserProvider
