import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';


export const Password: React.FunctionComponent<{
  label: string
  errorLabel: string
  passwordIsValid: boolean
  setPassword: (_: string) => void
}> = ({ label, errorLabel, passwordIsValid, setPassword }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <TextField
      fullWidth
      InputProps={{ // <-- This is where the toggle button is added.
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      type={showPassword ? "text" : "password"}
      variant="outlined"
      label={passwordIsValid ? t(label) : t(errorLabel)}
      error={!passwordIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPassword(evt.target.value)
      }}
    />
  )
}

export const Username: React.FunctionComponent<{
  initialValue: string;
  usernameIsValid: boolean;
  setUsername:
  (_: string) => void
}>
  = ({
    usernameIsValid,
    setUsername,
    initialValue
  }) => {
    const { t } = useTranslation();
    return (
      <TextField
        fullWidth
        variant="outlined"
        value={initialValue}
        label={usernameIsValid ? t('userName') : t("8CharMinNoSpecial")}
        error={!usernameIsValid}
        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
          setUsername(evt.target.value)
        }}
      />
    )
  }

export const Name: React.FunctionComponent<{ nameIsValid: boolean; setname: (_: string) => void, initialValue?: string }> = ({
  initialValue,
  nameIsValid,
  setname,
}) => {
  const { t } = useTranslation();
  return (
    <TextField
      value={initialValue}
      fullWidth
      variant="outlined"
      label={nameIsValid ? initialValue ? null : t('firstName') : t('mandatory')}
      error={!nameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setname(evt.target.value)
      }}
    />
  )
}

export const LastName: React.FunctionComponent<{ lastNameIsValid: boolean; setLastName: (_: string) => void, initialValue?: string }> = ({
  initialValue,
  lastNameIsValid,
  setLastName,
}) => {
  const { t } = useTranslation();
  return (
    <TextField
      value={initialValue}
      fullWidth
      variant="outlined"
      label={lastNameIsValid ? initialValue ? null : t('lastName') : t('mandatory')}
      error={!lastNameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLastName(evt.target.value)
      }}
    />
  )
}


export const Email: React.FunctionComponent<{ emailIsValid: boolean; setEmail: (_: string) => void, initialValue?: string, disable?: boolean }> = ({
  initialValue,
  emailIsValid,
  setEmail,
  disable
}) => {
  const { t } = useTranslation();
  return (
    <TextField
      disabled={disable}
      value={initialValue}
      fullWidth
      variant="outlined"
      label={emailIsValid ? initialValue ? null : 'email' : t('mandatory')}
      error={!emailIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(evt.target.value)
      }}
    />
  )
}

export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
  codeIsValid,
  setCode,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={codeIsValid ? 'Code' : 'Minimum 6 characters'}
      error={!codeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value)
      }}
    />
  )
}
