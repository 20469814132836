import { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { AuthContext } from '../contexts/authContext'
import { Car, Session, User } from '../libs/types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import toast, { Toaster } from "react-hot-toast";
import { Cancel } from '@material-ui/icons';
import SessionList from '../components/sessions/sessionList';
import bgF2m from '../ressources/backgroundf2m.png';
import { useTranslation } from 'react-i18next'
import Services from '../service/services'
import { CarContext } from '../contexts/carContext'

interface EndUserState {
  cars: Car[]
  sessions: Session[]
}
var state: EndUserState;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  spacedTop: {
    marginTop: "30px"
  },
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },
  session: {
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  green: {
    backgroundColor: 'green',
  },
  hero: {
    width: '100%'
  }
}))

var cpsMap = new Map()

interface commonDialogProps {
  titleCode: string;
  textContentcode: string;
  onClose: () => void;
  open: boolean;
}

export const MessagePopup = ({
  titleCode,
  textContentcode,
  onClose,
  open,
}: commonDialogProps) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles()
  return (
    <Dialog open={open}>
      <DialogTitle ><span className={classes.popupTitle}>{t(titleCode)}</span></DialogTitle>
      <DialogContent>
        <Typography>
          {t(textContentcode)}
        </Typography>
        <br />
      </DialogContent>
      <DialogActions /*sx={{ p: '1.25rem' }}*/>
        <Button variant="contained" startIcon={<Cancel />} onClick={onClose}> {t("close")}</Button>
      </DialogActions>
    </Dialog>
  )
}


export default function Archived() {
  const { t } = useTranslation()
  const classes = useStyles()
  var [statedata, setstatedata] = useState(state);
  var [lastUpdate, setlastUpdate] = useState(new Date().valueOf())
  const auth = useContext(AuthContext)
  const cars = useContext(CarContext).cars
  const services = new Services(auth.sessionInfo?.accessToken || "")

  async function refresh() {
    let [sessions] = await Promise.all([
      services.getSessionForUser(auth.User, null, null, true)
    ]);

    var data = {
      cars,
      sessions
    }
    await setstatedata(data)
    setlastUpdate(new Date().valueOf())
    return data
  }

  useEffect(() => {
    refresh()
  }, [])



  return (
    <>
      <Toaster
        position="top-right"
      />
      <Grid className={classes.root} container direction="column" alignItems="center">
        <Grid container spacing={1} >
          <Grid container item xs={12} direction="column" >
            <Box className={[classes.spaced, classes.spacedTop].join(" ")} >
              <SessionList
                displayNotification={displayNotification}
                archiveMode={true}
                id="archivedSessionsList"
                key={statedata ? lastUpdate : 1234}
                cpMap={cpsMap}
                isLoading={statedata ? false : true}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const displayNotification = (text: string, isError: boolean) => {
  if (isError) {
    toast.error(text)
    return
  }
  toast.success(text)
}