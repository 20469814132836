import { Box, Grid, Typography, WithStyles, createStyles, makeStyles, withStyles } from "@material-ui/core";
import React, { MutableRefObject } from "react";
import f2mlogo from '../../ressources/f2m_logo.png'
import logoHay from '../../ressources/haylectricLogo.png';
import { QRCode } from 'react-qrcode-logo';
import { Device } from "../../libs/types";
const HAYLECTRIC_URL  = process.env.REACT_APP_FRONT_URL

const styles = (theme: any) => createStyles({

    spaced: {

        marginLeft: '10%',
        marginRight: '10%',
        maxWidth: '800px'
    },
    text: {
        fontSize: 28,
        fontWeight: 'bold'
    },
    text_sub:{
        fontSize: 16,
    },
    printOnly: {
        backgroundColor: 'black',
        color: 'white',
        '@media print': {
            display: 'block'
        },
        '@media screen': {
            display: 'none'
        }
    }
});

interface Props extends WithStyles<typeof styles> { ref: MutableRefObject<any>,device:Device|null }
// Using a class component, everything works without issue
class DeviceLabel extends React.Component<Props> {
    render() {
        if(!this.props.device){
            return (<></>)
        }
        var signuplink =`${HAYLECTRIC_URL}/#/signup?companyID=${this.props.device.companyID.substring(this.props.device.companyID.length -5)}`
        const { classes } = this.props;
        return (
            <div ref={this.props.ref} className={classes.printOnly}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid className={classes.spaced} container spacing={1} >
                      
                            <img  height={'80px'} src={f2mlogo} />
                        
                        
                        { 
                        /*
                        <Grid container item xs={12} direction="column" >
                            <Typography className={classes.text}>Signup here using this QRcode:</Typography>
                        </Grid>
                        <Grid container item xs={12} direction="column" >
                           
                            <QRCode
                                value={signuplink}
                                logoImage={logoHay}
                                size={300}
                                logoHeight={80}
                                logoWidth={80}
                               // removeQrCodeBehindLogo={true}
                            /></Grid>*/ 
                             
                           }
                        
                        <Grid container item xs={12} direction="column" >
                            <Typography className={classes.text}> Signup directly on the website using this code : <b>{this.props.device.id}</b></Typography>
                        </Grid>
                        <Grid container item xs={12} direction="column" >
                        <Typography className={classes.text}> <b>{`${HAYLECTRIC_URL}/#/signup`}</b></Typography>  
                        </Grid>
                    </Grid>
                </Grid>
                <Box>
                </Box>
            </div>
        );
    }
}
export default withStyles(styles)(DeviceLabel);