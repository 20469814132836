import { DialogTitle, Dialog, MenuItem, DialogContent, Typography, Select, Button, DialogActions, makeStyles, Grid, TextField, DialogContentText } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Car, ChargePoint, Session, User } from "../../libs/types";
import { tsToDate } from "../../libs/utils";
import { AuthContext } from "../../contexts/authContext";
import Services from "../../service/services";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CompaniesContext } from "../../contexts/companyContext";
import toast, { Toaster } from "react-hot-toast";
const filter = createFilterOptions<NewCar>();

interface MakeBillableProps {
    cpsMap: Map<string, ChargePoint>,
    sessionMap: Map<string, Session>,
    cars: Car[],
    isManualEnable: boolean,
    onAddCar: () => void;
    onClose: () => void;
    onSubmit: () => void;
    open: boolean;
}


const useStyles = makeStyles((theme) => ({

    spaced: {
        marginLeft: "15px",
        marginRight: "15px"
    },
    popupTitle: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "1.8rem!important"
    }
}))

interface NewCar {
    brand: string;
    model: string;
    plateNumber: string;
    inputValue?: string;

}

export const MakeBillablePopup = ({
    cpsMap,
    open,
    sessionMap,
    cars,
    isManualEnable,
    onAddCar,
    onClose,
    onSubmit
}: MakeBillableProps) => {
    const { t } = useTranslation();
    const classes = useStyles()
    const [sMap, setsMap] = useState(sessionMap);
    const auth = useContext(AuthContext)
    var myCompany = useContext(CompaniesContext)!.getCompany()!
    const services = new Services(auth.sessionInfo?.accessToken || "")
    const [openAddDialog, toggleOpenAddDialog] = useState(false);
    const [value, setValue] = useState<NewCar | null>(null);
    const [dialogValue, setDialogValue] = useState({
        brand: '',
        model: '',
        plateNumber: '',
    } as NewCar);
    const dialogInputIsvalid = dialogValue.brand.trim().length > 0 && dialogValue.model.trim().length > 0 && dialogValue.plateNumber.trim().length > 0
    const carsList = cars.map(car => {

        return {
            brand: car.brand,
            model: car.model,
            plateNumber: car.plateNumber,
        } as NewCar

    }
    )
    const sessions: Session[] = []
    sMap.forEach((v, _) => {
        sessions.push(v)
    })


    if (!open) {
        return (<></>)
    }
    const handleSubmit = () => {
        submitChanges(auth.User!, sessionMap)
            .then(_ => { onSubmit() })
            .catch(e => console.log(e))
    }


    const submitChanges = (user: User, sessionsMap: Map<string, Session>): Promise<void> => {
        const updates: Session[] = []
        sessionsMap.forEach((session, id) => {
            if (session.carDetails && session.carDetails.VIN) {
                updates.push(session)
            }
        })
        return services.updateSesssionsCars(user, updates)
    }
    const handleClose = () => {
        setDialogValue({
            brand: '',
            model: '',
            plateNumber: '',
        });
        toggleOpenAddDialog(false);
    };

    const handleCreateCar = async () => {
        const c: Car = {
            user: null,
            companyID: myCompany.id,
            isManual: true,
            model: dialogValue.model,
            plateNumber: dialogValue.plateNumber,
            brand: dialogValue.brand,
            VIN: "",
            lastCharge: null,
            tags: null,
            status: "active"
        }

        await services.createCar(c);
        try {
            onAddCar()
            toggleOpenAddDialog(false);
            toast.success(t("carCreated"))
        } catch {
            toast.error("error")
        }
    }

    //first value of sessionMap
    //
    //  


    var firstSession = sessionMap.get(sessionMap.keys().next().value)


    return (

        <Dialog open={open} style={{ minWidth: "100rem" }}>
            <Toaster />
            <DialogTitle ><span className={classes.popupTitle}>{t("makeBillable")}</span></DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Typography>
                        {t("makeBillableExplanation")}
                    </Typography>
                    <br />
                    <Grid container direction="row" spacing={1}>
                        {
                            sessions.map(session =>
                            (
                                <>
                                    <Grid item xs={12} >
                                        <Typography style={{ verticalAlign: "center", lineHeight: "40px" }}>
                                            <b>{cpsMap.get(session.chargePointID)?.name} - {tsToDate(session.start).toLocaleDateString()}</b>: {Math.round(session.consumedPower.total)} kWh
                                        </Typography>
                                    </Grid>
                                </>
                            )

                            )}
                        <Grid item xs={12} >
                            <Select
                                id={`select-cars`}
                                style={{ minWidth: "10rem" }}
                                value={!firstSession!.carDetails ? null : firstSession!.carDetails.VIN}
                                placeholder="select a vehicule"
                                label={t("cars")}
                                onChange={(e) => {
                                    var tmp = new Map(sessionMap)
                                    sessionMap.forEach((session, k) => {

                                        if (!session?.carDetails) {
                                            session!.carDetails = {
                                                VIN: String(e.target.value),
                                                PlateNumber: "",
                                                energyIn: 0,
                                                SOC: {
                                                    gaugeEnd: 0,
                                                    gaugeStart: 0,
                                                    start: 0,
                                                    end: 0,
                                                    estimatedEnergyDelta: 0,
                                                },
                                                location: {
                                                    lng: 0,
                                                    lat: 0
                                                }


                                            }
                                        } else {
                                            session!.carDetails.VIN = String(e.target.value)
                                        }
                                        tmp.set(session.id, session)

                                    })
                                    setsMap(tmp)
                                }}
                            >
                                {cars.map(car => {
                                    return (<MenuItem value={car.VIN || ""}>{car.plateNumber}</MenuItem>)
                                })}

                            </Select>
                        </Grid>

                        {
                            /*
                                sessions.map(session => {
                                    return (
                                        <>
                                            <Grid item xs={12} >
                                                <Typography style={{ verticalAlign: "center", lineHeight: "40px" }}>
                                                    <b>{cpsMap.get(session.chargePointID)?.name} - {tsToDate(session.start).toLocaleDateString()}</b>: {Math.round(session.consumedPower.total)} kWh
                                                </Typography>
                                            </Grid>
                                            <br />
                                            {
                                                isManualEnable ?
                                                    (<>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                {t("inputPlateExplanation")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
    
                                                            <Autocomplete
                                                                value={value}
                                                                onChange={(event, newValue) => {
                                                                    if (typeof newValue === 'string') {
                                                                        // timeout to avoid instant validation of the dialog's form.
                                                                        setTimeout(() => {
                                                                            toggleOpenAddDialog(true);
                                                                            setDialogValue({
                                                                                brand: '',
                                                                                model: '',
                                                                                plateNumber: newValue,
                                                                            });
                                                                        });
                                                                    } else if (newValue && newValue.inputValue) {
                                                                        toggleOpenAddDialog(true);
                                                                        setDialogValue({
                                                                            brand: '',
                                                                            model: '',
                                                                            plateNumber: newValue.inputValue,
                                                                        });
                                                                    } else {
                                                                        setValue(newValue);
                                                                    }
                                                                }}
                                                                filterOptions={(options, params) => {
                                                                    const filtered = filter(options, params);
    
                                                                    if (params.inputValue !== '' && filtered.length == 0) {
                                                                        filtered.push({
                                                                            inputValue: params.inputValue,
                                                                            plateNumber: `${t("notfoundInlist")}`,
                                                                            brand: '',
                                                                            model: ''
                                                                        });
                                                                    }
                                                                    return filtered;
                                                                }}
                                                                id="free-solo-dialog-demo"
                                                                options={carsList}
                                                                getOptionLabel={(option) => {
                                                                    // for example value selected with enter, right from the input
                                                                    if (typeof option === 'string') {
                                                                        return option;
                                                                    }
                                                                    if (option.inputValue) {
                                                                        return option.inputValue;
                                                                    }
                                                                    return option.plateNumber;
                                                                }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                renderOption={(props, option) => <li {...props}>{option.plateNumber}</li>}
                                                                sx={{ width: 300 }}
                                                                freeSolo
                                                                // @ts-ignore
                                                                renderInput={(params) => <TextField {...params} label={t("plateNumber")} variant="outlined" />}
                                                            //renderInput={(params) => <LegacyTextfield variant="outlined" {...params} label="Free solo dialog" 
                                                            // />}
    
    
    
                                                            />
    
    
    
                                                            <Dialog open={openAddDialog} onClose={handleClose}>
                                                                <form onSubmit={handleSubmit}>
                                                                    <DialogTitle><span className={classes.popupTitle}>{t('createCarTitle')}</span></DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText>
                                                                            {t('createCarExplanation')}
                                                                        </DialogContentText>
                                                                        <Grid container direction="row" spacing={1}>
                                                                            <Grid item xs={6}>
                                                                                <TextField
                                                                                    autoFocus
                                                                                    margin="dense"
                                                                                    id="plateNumber"
                                                                                    value={dialogValue.plateNumber}
                                                                                    onChange={(event) =>
                                                                                        setDialogValue({
                                                                                            ...dialogValue,
                                                                                            plateNumber: event.target.value,
                                                                                        })
                                                                                    }
                                                                                    label={t("plateNumber")}
                                                                                    type="text"
                                                                                    variant="outlined"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <TextField
                                                                                    margin="dense"
                                                                                    id="brand"
                                                                                    value={dialogValue.brand}
                                                                                    onChange={(event) =>
                                                                                        setDialogValue({
                                                                                            ...dialogValue,
                                                                                            brand: event.target.value,
                                                                                        })
                                                                                    }
                                                                                    label={t("brand")}
                                                                                    type="text"
                                                                                    variant="outlined"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <TextField
                                                                                    margin="dense"
                                                                                    id="model"
                                                                                    value={dialogValue.model}
                                                                                    onChange={(event) =>
                                                                                        setDialogValue({
                                                                                            ...dialogValue,
                                                                                            model: event.target.value,
                                                                                        })
                                                                                    }
                                                                                    label={t("model")}
                                                                                    type="text"
                                                                                    variant="outlined"
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button variant="contained" color="primary" disabled={!dialogInputIsvalid} style={{ display: "inline-flex" }} onClick={handleCreateCar} className={classes.spaced} component="span">
                                                                            {t("ok")}
                                                                        </Button>
                                                                        <Button variant="contained" startIcon={<Cancel />} onClick={handleClose}> {t("cancel")}</Button>
    
                                                                    </DialogActions>
                                                                </form>
                                                            </Dialog>
    
                                                        </Grid>
                                                        <br />
    
                                                    </>
                                                    ) : (
                                                        <>
                                                            <Grid item xs={12} >
                                                                <Select
                                                                    id={`select-cars-${session.id}`}
                                                                    style={{ minWidth: "10rem" }}
                                                                    value={!sessionMap.get(session.id)?.carDetails ? null : sessionMap.get(session.id)?.carDetails.VIN}
                                                                    placeholder="select a vehicule"
                                                                    label={t("cars")}
                                                                    onChange={(e) => {
                                                                        let s = sessionMap.get(session.id)!
                                                                        if (!s?.carDetails) {
                                                                            s!.carDetails = {
                                                                                VIN: String(e.target.value),
                                                                                PlateNumber: "",
                                                                                energyIn: 0,
                                                                                SOC: {
                                                                                    gaugeEnd: 0,
                                                                                    gaugeStart: 0,
                                                                                    start: 0,
                                                                                    end: 0,
                                                                                    estimatedEnergyDelta: 0,
                                                                                },
                                                                                location: {
                                                                                    lng: 0,
                                                                                    lat: 0
                                                                                }
    
    
                                                                            }
                                                                        } else {
                                                                            s!.carDetails.VIN = String(e.target.value)
                                                                        }
                                                                        const tmp = new Map(sessionMap)
                                                                        tmp.set(session.id, s)
                                                                        setsMap(tmp)
                                                                    }}//setGridID(String(e.target.value)) }}
                                                                >
                                                                    {cars.map(car => {
                                                                        return (<MenuItem value={car.VIN || ""}>{car.plateNumber}</MenuItem>)
                                                                    })}
    
                                                                </Select>
                                                            </Grid>
                                                        </>
                                                    )
                                            }
    
    
    
                                            <Grid item xs={12} >
                                            </Grid>
                                        </>
                                    )
                                })
                                    */
                        }
                    </Grid>
                    <br />
                    {<Typography>
                        {t("makeBillableLegal")}
                    </Typography>
                    }
                </form>
            </DialogContent>
            <DialogActions /*sx={{ p: '1.25rem' }}*/>
                <label htmlFor="raised-button-file">
                    <Button variant="contained" color="primary" style={{ display: "inline-flex" }} onClick={handleSubmit} className={classes.spaced} component="span">
                        {t("ok")}
                    </Button>
                </label>
                <Button variant="contained" startIcon={<Cancel />} onClick={onClose}> {t("cancel")}</Button>
            </DialogActions>
        </Dialog>
    );
};


