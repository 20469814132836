import axios from 'axios'
import { BASE_URL } from './conf'
import { ChargePoint, FirmWare, PresignedPostUrlResponse } from '../libs/types'
import fileDownload from 'js-file-download'

export async function getallFw(): Promise<FirmWare[]> {
    return new Promise(function (resolve, reject) {
        axios.get(BASE_URL + `/firmwares`, {
            responseType: 'json',
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var fws: FirmWare[] = []
                res.data.forEach((fw: FirmWare) => {
                    fws.push(fw)
                })
                resolve(fws)
            }).catch(e => reject(e))
    })
}


export async function createFw(type: String, name: String): Promise<FirmWare> {
    return new Promise(function (resolve, reject) {
        axios.post(
            `${BASE_URL}/firmwares`,
            {
                name,
                type
            },
            {
                responseType: 'json'
            })
            .then((res: any) => {
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

 async function setFwFile(fw: FirmWare,filename : string): Promise<void> {
    return new Promise(function (resolve, reject) {
        axios.put(
            `${BASE_URL}/firmwares/${fw.id}/doc`,
            {file : filename},
            {
                responseType: 'json'
            })
            .then((res: any) => {
                resolve()
            })
            .catch(e => reject(e))
    })
}





async function getUploadLink(id: string, fileExt: string): Promise<PresignedPostUrlResponse> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        params.append("ext", fileExt)
        axios.get(BASE_URL + `/firmwares/${id}/doc/uploadLink`, {
            headers: {
                Accept: '*/*',
            },
            params,
            responseType: 'json'
        })
            .then((res: any) => {
                resolve(res.data)
            })
            .catch(e => reject(e))
    })

}




export async function uploadFwToS3({ file, fw
}: {
    file: File;
    fw: FirmWare;

}): Promise<void> {
    return new Promise(async function (resolve, reject) {
        try {
            const presignedPostUrl = await getUploadLink(fw.id, file.name?.split('.')?.pop()!);
            const formData = new FormData();
            //formData.append('Content-Type', file.type);
            Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
                formData.append(k, v);
            });
            formData.append('file', file); // The file has be the last element

            const response = await axios.post(presignedPostUrl.url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            await setFwFile(fw,fw.id+file.name?.split('.')?.pop()!)
            resolve()
        } catch (e) {
            console.log(e)
            try {
            await setFwFile(fw,fw.id+"."+file.name?.split('.')?.pop()!)
            }catch (e) {
                resolve()
            }
            //TODO chase the axios bug
            resolve()
            //reject(e)
        }
    })
}
export async function downladFw(fw: FirmWare): Promise<void> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        axios.get(BASE_URL + `/firmwares/${fw.id}/doc`, {
            headers: {
                Accept: 'application/pdf',
                // ContentType :'application/pdf',
            },
            responseType: 'blob',
        }).then((res: any) => {
            fileDownload(res.data, fw.file)
        })
            .catch(e => reject(e))
    })
}



