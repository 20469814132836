import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { TransitionProps } from '@mui/material/transitions';
import { Grid, Slide, Box, makeStyles, Typography, Select, Tooltip, MenuItem } from '@material-ui/core';
import ActivityCalendar from 'react-activity-calendar';
import { calendar } from '../../libs/types';
import { getTempoCalendar } from '../../service/calendars';
import * as utils from '../../libs/utils'
import { useTranslation } from 'react-i18next';


const years = [2023, 2024, 2025]

const labels = {
    months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ],
    weekdays: [
        'Sun', // Sunday first!
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
    ],
    totalCount: '{{count}} activities in {{year}}',
    legend: {
        less: 'Less',
        more: 'More',
    },
};
const froffDay = [
    { "date": "2028-01-01", "count": 1, "level": 1 },
    { "date": "2028-04-17", "count": 1, "level": 1 },
    { "date": "2028-05-01", "count": 1, "level": 1 },
    { "date": "2028-05-08", "count": 1, "level": 1 },
    { "date": "2028-05-25", "count": 1, "level": 1 },
    { "date": "2028-06-05", "count": 1, "level": 1 },
    { "date": "2028-07-14", "count": 1, "level": 1 },
    { "date": "2028-08-15", "count": 1, "level": 1 },
    { "date": "2028-11-01", "count": 1, "level": 1 },
    { "date": "2028-11-11", "count": 1, "level": 1 },
    { "date": "2028-12-25", "count": 1, "level": 1 },
    { "date": "2027-01-01", "count": 1, "level": 1 },
    { "date": "2027-03-29", "count": 1, "level": 1 },
    { "date": "2027-05-01", "count": 1, "level": 1 },
    { "date": "2027-05-06", "count": 1, "level": 1 },
    { "date": "2027-05-08", "count": 1, "level": 1 },
    { "date": "2027-05-17", "count": 1, "level": 1 },
    { "date": "2027-07-14", "count": 1, "level": 1 },
    { "date": "2027-08-15", "count": 1, "level": 1 },
    { "date": "2027-11-01", "count": 1, "level": 1 },
    { "date": "2027-11-11", "count": 1, "level": 1 },
    { "date": "2027-12-25", "count": 1, "level": 1 },
    { "date": "2026-01-01", "count": 1, "level": 1 },
    { "date": "2026-04-06", "count": 1, "level": 1 },
    { "date": "2026-05-01", "count": 1, "level": 1 },
    { "date": "2026-05-08", "count": 1, "level": 1 },
    { "date": "2026-05-14", "count": 1, "level": 1 },
    { "date": "2026-05-25", "count": 1, "level": 1 },
    { "date": "2026-07-14", "count": 1, "level": 1 },
    { "date": "2026-08-15", "count": 1, "level": 1 },
    { "date": "2026-11-01", "count": 1, "level": 1 },
    { "date": "2026-11-11", "count": 1, "level": 1 },
    { "date": "2026-12-25", "count": 1, "level": 1 },
    { "date": "2025-01-01", "count": 1, "level": 1 },
    { "date": "2025-04-21", "count": 1, "level": 1 },
    { "date": "2025-05-01", "count": 1, "level": 1 },
    { "date": "2025-05-08", "count": 1, "level": 1 },
    { "date": "2025-05-29", "count": 1, "level": 1 },
    { "date": "2025-06-09", "count": 1, "level": 1 },
    { "date": "2025-07-14", "count": 1, "level": 1 },
    { "date": "2025-08-15", "count": 1, "level": 1 },
    { "date": "2025-11-01", "count": 1, "level": 1 },
    { "date": "2025-11-11", "count": 1, "level": 1 },
    { "date": "2025-12-25", "count": 1, "level": 1 },
    { "date": "2024-01-01", "count": 1, "level": 1 },
    { "date": "2024-04-01", "count": 1, "level": 1 },
    { "date": "2024-05-01", "count": 1, "level": 1 },
    { "date": "2024-05-08", "count": 1, "level": 1 },
    { "date": "2024-05-09", "count": 1, "level": 1 },
    { "date": "2024-05-20", "count": 1, "level": 1 },
    { "date": "2024-07-14", "count": 1, "level": 1 },
    { "date": "2024-08-15", "count": 1, "level": 1 },
    { "date": "2024-11-01", "count": 1, "level": 1 },
    { "date": "2024-11-11", "count": 1, "level": 1 },
    { "date": "2024-12-25", "count": 1, "level": 1 },
    { "date": "2023-01-01", "count": 1, "level": 1 },
    { "date": "2023-04-10", "count": 1, "level": 1 },
    { "date": "2023-05-01", "count": 1, "level": 1 },
    { "date": "2023-05-08", "count": 1, "level": 1 },
    { "date": "2023-05-18", "count": 1, "level": 1 },
    { "date": "2023-05-29", "count": 1, "level": 1 },
    { "date": "2023-07-14", "count": 1, "level": 1 },
    { "date": "2023-08-15", "count": 1, "level": 1 },
    { "date": "2023-11-01", "count": 1, "level": 1 },
    { "date": "2023-11-11", "count": 1, "level": 1 },
    { "date": "2023-12-25", "count": 1, "level": 1 }
]




const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    isOpen: boolean;
    onclose: () => void;
}

const useStyles = makeStyles((theme) => ({
    spaced: {
        marginLeft: "15px",
        marginRight: "15px"
    },
    popupTitle: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "1.8rem!important"
    },
    verticalCenter: {
        marginTop: 'auto',
        marginBottom: 'auto'
    },
}))

const PricingCalendar: React.FC<Props> = ({ isOpen, onclose }) => {
    const classes = useStyles()
    const [selectedYear, setSelectedYear] = useState(dayjs().year())
    const [selectedTempoYear, setSelectedTempoYear] = useState(dayjs().year())
    const [tempoCalendar, setTempoCalendar] = useState({} as calendar)
    const { t } = useTranslation();

    const fetchAllCalendars = async () => {
        try {
            const tempo = await getTempoCalendar()
            setTempoCalendar(tempo)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        fetchAllCalendars()
    }, [])


    const offdays = froffDay.filter((d) => dayjs(d.date).year() === selectedYear)
    const tempodays = utils.formatCalendarData(tempoCalendar, selectedTempoYear)

    const tempodaysFiltered = tempodays.filter((d) => dayjs(d.date).year() === selectedTempoYear)
    //sort the tempodaysFiltered array by date ascending 
    tempodaysFiltered.sort((a, b) => (a.date > b.date) ? 1 : -1)
    return (
        <Dialog
            open={isOpen}
            onClose={onclose}
            fullScreen
            TransitionComponent={Transition}
        >
            <DialogTitle className={classes.popupTitle}>{t("calendar")}</DialogTitle>
            <DialogContent >
                <Grid container direction='row'>
                    <Grid item xs={3} >
                        <Typography variant="h6" className={classes.spaced}>{t("offdays")}</Typography>
                    </Grid>
                    <Grid item xs={1} >
                        <Select
                            style={{ minWidth: '100px' }}
                            onChange={(e) => setSelectedYear(parseInt((e.target as HTMLSelectElement).value))}
                            value={selectedYear}
                        >
                            { years.map((year) =>
                                    <MenuItem value={year}>
                                        {year}
                                    </MenuItem>)}
                        </Select>
                    </Grid>

                    <Grid item xs={8} >

                    </Grid>

                </Grid>
                <br />
                <Box style={{ padding: '10px' }}>
                    <ActivityCalendar
                        hideColorLegend={true}
                        hideTotalCount={true}
                        weekStart={1}
                        maxLevel={1}
                        blockMargin={5}
                        labels={labels}
                        blockSize={14}
                        blockRadius={7}
                        renderBlock={(block, activity) => (
                            <Tooltip
                                title={`${activity.date}`}
                            >
                                {block}
                            </Tooltip>
                        )}
                        theme={{
                            light: ['lightgrey', '#f73859',],
                            dark: ['white', 'blue'],
                        }}
                        data={offdays} />
                </Box>
                <br />
                <Grid container direction='row'>
                    <Grid item xs={3} >
                        <Typography variant="h6" className={classes.spaced}>{t("EDFTempoCalendar")}</Typography>
                    </Grid>
                    <Grid item xs={1} >
                        <Select
                            style={{ minWidth: '100px' }}
                            onChange={(e) => setSelectedTempoYear(parseInt((e.target as HTMLSelectElement).value))}
                            value={selectedTempoYear}
                        >
                            {
                                years.map((year) =>
                                    <MenuItem value={year}>
                                        {year}
                                    </MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={8} >
                    </Grid>
                </Grid>
                <br />
                <Box style={{ padding: '10px' }}>
                    <ActivityCalendar
                        key="toto"
                        hideColorLegend={true}
                        hideTotalCount={true}
                        weekStart={1}
                        maxLevel={4}
                        blockMargin={5}
                        blockSize={14}
                        blockRadius={7}
                        labels={labels}
                        fontSize={16}
                        renderBlock={(block, activity) => (
                            <Tooltip
                                title={`${activity.date}`}
                            >
                                {block}
                            </Tooltip>
                        )}
                        theme={{
                            light: ['grey', 'red', 'white', 'blue', 'black'],
                            dark: ['grey', 'red', 'white', 'blue', 'black'],
                        }}

                        hideMonthLabels={false}
                        data={tempodaysFiltered} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onclose} color="primary">{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PricingCalendar;