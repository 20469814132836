import { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Car, Company, Tag } from '../libs/types'
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef, MaterialReactTableProps } from 'material-react-table';

import {
  Add as AddIcon,
  Check as CheckIcon,
  NotInterested as NotInterestedIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import IconButton from '@material-ui/core/IconButton'
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField, Tooltip } from '@material-ui/core'
import { Autocomplete, Stack, createFilterOptions } from '@mui/material'
import { updateCar } from '../service/cars'
import { CompaniesContext } from '../contexts/companyContext'
import { getReactTableLocalisation } from '../libs/i18n'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/authContext'
import { TagContext } from '../contexts/TagContext'
import bgF2m from '../ressources/backgroundf2m.png';
import Services from '../service/services'



interface TagRepresentation {
  tag: Tag
  company: Company
}

interface state {
  tags: TagRepresentation[],
}
//mock data - strongly typed if you are using TypeScript (optional, but recommended)
var data: state;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
  },
  spaced: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  spacedTop: {
    marginTop: "30px"
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vvw',
    backgroundColor: 'white',
    border: '2px solid #000',
  }
}))

export default function TagsPage() {

  const classes = useStyles()
  var [statedata, setstatedata] = useState(data);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const companies = useContext(CompaniesContext).nonTechnicalCompanies
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext)
  const tagContext = useContext(TagContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const isFleetManager = auth.sessionInfo?.fleetManagerProfile ? true : false
  const CarColumns = useMemo<MRT_ColumnDef<TagRepresentation>[]>(() => columnFactory(t, isFleetManager), [i18n.language])
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const companyMap = new Map<string, Company>()
  companies.forEach(c =>
    companyMap.set(c.name, c)
  )


  var refresh = async () => {
    var d: TagRepresentation[] = []
    for (const company of companies) {
      let tags = await services.listTags(company.id)
      //sort tags by company then by type then by name
      tags.sort((a, b) => {
        if (a.type > b.type) {
          return 1
        }
        if (a.type < b.type) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        if (a.name < b.name) {
          return -1
        }
        return 0
      })

      tags.forEach(t => {
        d.push({
          tag: t,
          company: company
        })
      })

    }
    setstatedata({
      tags: d,

    })
  }

  useEffect(() => {
    refresh()
  }, [])



  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="column" >
          <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
            <MaterialReactTable
              enableRowActions
              renderRowActions={({ row }) => {
                return (<></>)
                return (//disabled for now
                
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <Tooltip title="Justificatif">
                    <IconButton disabled={row.original.tag.hasMembers} onClick={async () => {
                      await services.deleteTag(row.original.company.id, row.original.tag.name)
                      tagContext.refresh()//could merged with refresh
                      refresh()
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}}
              //enableEditing
              //onEditingRowSave={handleSaveRowEdits}
              //onEditingRowCancel={handleCancelRowEdits}
              columns={CarColumns}
              state={{ isLoading: statedata ? false : true }}
              data={statedata ? statedata.tags : []}
              //enableRowSelection //enable some features
              enableColumnOrdering={false}
              enableHiding={false}
              enableDensityToggle={false}
              enableGlobalFilter={false} //turn off a feature
              renderTopToolbarCustomActions={() => (
                <Tooltip title="Ajouter un véhicule">
                  <IconButton onClick={() => setCreateModalOpen(true)} >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}

              localization={getReactTableLocalisation()}
            />
            <br />
          </Box>
        </Grid>
      </Grid>
      <CreateModal
        columns={CarColumns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={async (type: string, companyID: string, name: string, label: string) => {
          await services.createTag(type, companyID, name, label);
          refresh()
          tagContext.refresh()
        }}
        existingTags={statedata ? statedata.tags.map(t => t.tag) : []}
        title={t('createTagTitle')}
        confirmButtonCaption={t("ok")}
      />
    </Grid>
  )
}


const columnFactory = (t: (s: string) => string, isFleetManager: boolean): MRT_ColumnDef<TagRepresentation>[] => {

  const res: MRT_ColumnDef<TagRepresentation>[] = []
  if (!isFleetManager) {
    //admin

    res.push({
      accessorKey: 'company.name',
      header: t('client'),
      enableEditing: false
    })

  }

  res.push(...[

    {
      accessorKey: 'tag.name',
      header: 'id',
      enableEditing: false,
    },
    {
      accessorKey: 'tag.label',
      header: 'label',
      enableEditing: false,
    },
    {
      accessorKey: 'tag.type',
      header: t('tagType'),
    },
    /*{
      accessorKey: 'tag.hasMembers',
      header: t('taghasMembers'),
      Cell: ({ cell }) => {
        return cell.row.original.tag.hasMembers ?
          <CheckIcon /> : <NotInterestedIcon />
      },
    },*///disabled for now
  ] as MRT_ColumnDef<TagRepresentation>[])
  return res
}

interface CreateModalProps {
  columns: MRT_ColumnDef<TagRepresentation>[];
  onClose: () => void;
  onSubmit: (type: string, companyID: string, name: string, label: string) => void;
  open: boolean;
  title: string
  confirmButtonCaption: string
  existingTags: Tag[]
}

interface TagType {
  inputValue?: string;
  type: string;

}
const filter = createFilterOptions<TagType>();

export const CreateModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  existingTags,
  title,
  confirmButtonCaption
}: CreateModalProps) => {
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const handleSubmit = () => {
    onSubmit(value!.type, values["company.id"], values["tag.name"], values["tag.label"]);
    onClose();
  };

  const companies = useContext(CompaniesContext).nonTechnicalCompanies

  const [selectedCompany, setselectedCompany] = useState(companies[0] ? companies[0].name : "")
  const [value, setValue] = useState<TagType | null>(null);
  var typeOptions = existingTags.map(t => { return { type: t.type } as TagType })
  //remove duplicates
  typeOptions = typeOptions.filter((v, i, a) => a.findIndex(t => (t.type === v.type)) === i)
  if (!companies) {
    return <></>
  }
  values["company.id"] = selectedCompany
  if (companies.length == 1) {
    values["company.id"] = companies[0].id
  }
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => {
              if (column.accessorKey == "tag.hasMembers") {
                return <></>

              }
              if (column.accessorKey == "company.name") {
                if (companies.length == 1) {
                  return <></>
                }
                return (<><Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCompany}
                  label="Select Company"
                  onChange={(e) => {

                    setValues({ ...values, ["company.id"]: e.target.value })
                      ; setselectedCompany(e.target.value as string)
                  }}
                >
                  {companies.map(company =>
                    <MenuItem value={company.id}>{company.name}</MenuItem>)
                  }
                </Select></>)
              }
              if (column.accessorKey == "tag.type") {
                return <Autocomplete
                  value={value}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue({
                        type: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue({
                        type: newValue.inputValue,
                      });
                    } else {
                      setValue(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.type);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        type: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={typeOptions}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.type;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.type}</li>}
                  sx={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    // @ts-ignore
                    <TextField {...params} label={t("type")} variant="outlined" />
                  )}
                />

              }


              return (<TextField
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />)
            }
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions /*sx={{ p: '1.25rem' }}*/>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          {confirmButtonCaption}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

