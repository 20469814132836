import React, { useState, useEffect, useContext } from 'react'
import { Device } from '../libs/types';
import { AuthContext } from './authContext';
import Services from '../service/services';

export interface IDevice {
  devices: Device[]
  deviceMap: Map<string, Device>
  myDevices?: () => Device[]
  refresh: () => void
  getDevices: () => Device[]
}

const defaultState: IDevice = {
  devices: [],
  deviceMap: new Map<string, Device>(),
  refresh: () => { },
  getDevices: () => { return [] }
}

type Props = {
  children?: React.ReactNode
}
export var DeviceContext = React.createContext(defaultState)


const DeviceProvider = ({ children }: Props) => {
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const [DeviceData, setDeviceData] = useState([] as  Device[])
  const state: IDevice = {
    devices: DeviceData,
    deviceMap: new Map(DeviceData.map(device => [device.id, device])),
    refresh: async () => {
      setDeviceData(await fetch())
    },
    getDevices: () => { return DeviceData }

  }


  const fetch = async (): Promise<Device[]> => {
    try {
      let devices: Device[] = []
      if (auth.sessionInfo?.isEndUser) {
        //for now , no need
      }
      if (auth.sessionInfo?.fleetManagerProfile) {
        let companyDevices = await services.getCompanyDevices(auth.sessionInfo.companyID!)
        if (companyDevices) devices.push(...companyDevices)
        return devices
      } else {
        devices = await services.getAllDevices()
        return devices
      }

    } catch (err) {
      return []
    }

  }
  useEffect(() => {
    if (!auth.sessionInfo) {
      return
    }
    async function fetchData() {
      setDeviceData(await fetch())
    }
    fetchData()
  }, [auth.sessionInfo])


  return <DeviceContext.Provider value={state}>{children}</DeviceContext.Provider>
}




export default DeviceProvider
