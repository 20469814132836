// React component used to style a @recatour step transforming a string containing specific separators into styled content
// "###" is transformed into <br/>
// elements starting by "--" is transformed into list items (displayed in another line) prefixed by a bullet point
// It has a "title" prop to display a title
// example: "a###bb:### --item1### --item2### --item3###" will be displayed as:
// a
// bb:
// - item1
// - item2
// - item3

import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { delimiter } from 'path';

type TourContentProps = {
    title: string;
    content: string;
}

const useStyles = makeStyles((theme) => ({
    verticalCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spaced: {
        marginLeft: "15px"
    },
}));



//fonction than create  a table a string with ethe content delimited by --

const splitBydelimitor = (s:string,englobing:string,delimiter:string) => {
   if (s.startsWith(englobing)){
    return s.split(englobing)[0].split(delimiter)
    }
   return[] 
}






const TourContent = ({ title, content }: TourContentProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
  




    return (
        <Box>
            <Typography variant="h5" className={classes.verticalCenter}>{t(title)}</Typography>
            <Typography>
                {content.split('###').map((item, key) => {
                    return (
                        <span key={key}>
                            { item.startsWith("--") ? <><span className={classes.spaced}>{"- " + item.substring(2)}</span></> : <span >{ item}</span> }
                           
                             
                            <br />
                        </span>
                    );
                })}
            </Typography>
        </Box>
    );
};

export default TourContent;
