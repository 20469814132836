import { Box, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid, makeStyles } from "@material-ui/core"
import { User, UserStats } from "../../libs/types"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getUserStat } from "../../service/stats"

const WEEK_PERIOD = "week"
const MONTH_PERIOD = "month"
const YEAR_PERIOD = "year"


const useStyles = makeStyles((theme) => ({
    spaced: {
        marginLeft: "15px"
    },
    spacedTop: {
        marginTop: "30px"
    },
}))

export function UserStatsPanel(user: User) {
    const classes = useStyles()
    const { t, i18n } = useTranslation();
    const [selectedPeriod, setSelectedPeriod] = useState(YEAR_PERIOD)
    const [stats, setStats] = useState({} as UserStats)
    useEffect(() => {
        if (!user) {
            return
        }
        var minus = 0;
        switch (selectedPeriod) {
            case WEEK_PERIOD:
                minus = 7
                break;
            case MONTH_PERIOD:
                minus = 30
                break;
            case YEAR_PERIOD:
                minus = 365
                break;

        }
        var s = new Date()
        s.setDate(s.getDate() - minus)
        var e = new Date()
        getUserStat(user!.userName, s, e).then(stats => {
            setStats(stats)
        })
    }, [selectedPeriod, user])
    return (
        <>
            <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")}>
                <Typography>{t('stats')}</Typography>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">{t('period')}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e => { setSelectedPeriod(e.target.value) })}
                    >
                        <FormControlLabel value={WEEK_PERIOD} control={<Radio />} checked={selectedPeriod == WEEK_PERIOD} label="7 jours" />
                        <FormControlLabel value={MONTH_PERIOD} control={<Radio />} checked={selectedPeriod == MONTH_PERIOD} label="30 jours" />
                        <FormControlLabel value={YEAR_PERIOD} control={<Radio />} checked={selectedPeriod == YEAR_PERIOD} label="1 an" />
                    </RadioGroup>
                </FormControl>
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xs={4}>
                        <Typography>{t("sessions")}:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography> {stats.sessionNb ? stats.sessionNb : " - "}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{t("chargedPower")} :</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography> {stats.totalPower ? stats.totalPower : " - "} kWh</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{t("totalAmount")} :</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography> {stats.total ? stats.total : " - "} €</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{t('creditNotes')}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{stats.creditNoteNb ? stats.creditNoteNb : " - "}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}