import { createRef, useEffect, useState } from 'react'
import { getAllUsers, getCompanyUsers, updateUser } from '../service/users'
import { AuthContext } from '../contexts/authContext'
import { Address, ChargePoint, Company, User } from '../libs/types'
import { CompaniesContext } from '../contexts/companyContext'
import { useTranslation } from 'react-i18next'
import {
  makeStyles,
  DialogTitle,
  DialogContent,
  Grid, Box,
  DialogActions,
  Button,
  Dialog,
  Typography,
  Paper,
  TextField,
  Tooltip,
  Select,
  MenuItem
} from '@material-ui/core'
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import bgF2m from '../ressources/backgroundf2m.png';
import frFlag from '../locales/fr/flag.png';
import enFlag from '../locales/en/flag.png';
import frFlagGrey from '../locales/fr/flag_grey.png';
import enFlagGrey from '../locales/en/flag_grey.png';
import { useHistory } from 'react-router-dom'
import { Email, LastName, Name } from '../components/authComponents'
import { WarningAmberOutlined as WarningIcon, Download as DownloadIcon, FileUpload } from '@mui/icons-material';
import { downladGridDoc, getAllChargePointsforUser, updateChargePoint, uploadToS3 } from '../service/chargepoints'
import { cp } from 'fs'
import { GmapsAutocomplete } from '../components/gmaps'
import { promises } from 'dns'
import { toast, Toaster } from 'react-hot-toast'
import { useContext } from "react";
import { GridContext } from '../contexts/GridContext';
import Services from '../service/services'

var data: userRepresentation
interface userRepresentation {
  user: User
  cps: ChargePoint[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  spacedTop: {
    marginTop: "30px"
  },
  paddedTop: {
    paddingTop: "30px"
  },
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },
  sectionTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1rem!important"
  },

  mainPaper: {
    minHeight: '80vh',
  },
  twoItem: {
    width: '100%',
    display: 'inline-block'
  },
  error: {
    display: 'inline-block',
    color: "red"
  },
  verticalCenter: {
    margin: 'auto',
  }

}))

export default function Account() {
  const { t } = useTranslation();
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const history = useHistory()
  const me = auth.User! as User
  const isEnduser = auth.sessionInfo?.isEndUser
  const [userEmail, setuserEmail] = useState(me.email)
  const [userFirstName, setuserFirstName] = useState(me.firstName|| "")
  const [userLastName, setuserLastName] = useState(me.lastName || "")
  const [userLanguage, setuserLanguage] = useState(me.language)
  const isValidFirstName = userFirstName.length > 0
  const isValidLastName = userLastName.length > 0
  const isValidEmail = userEmail.length > 0
  const classes = useStyles()
  const [createModalOpen, setCreateModalOpen] = useState(false);
  var [statedata, setstatedata] = useState(data);

  const updateCp = (cp: ChargePoint, index: number) => {
    const cps = statedata.cps
    cps[index] = cp
    setstatedata({
      user: statedata.user,
      cps
    })
  }

  const refresh = async () => {
    const cps = await services.getAllChargePointsforUser(me)
    var d: userRepresentation = {
      user: me,
      cps
    }
    setstatedata(d)
  }

  const gotoHome = () => {
    history.push('/')
  }

  useEffect(() => {
    refresh()
  }, [])

  const submitChanges = () => {
    me.email = userEmail
    me.firstName = userFirstName
    me.language = userLanguage
    me.lastName = userLastName
    const p: Promise<any>[] = [services.updateUser(me)]
    for (const cp of statedata.cps) {
      p.push(services.updateChargePoint(cp))
    }
    Promise.all(p)
      .then(_ => {
        toast.success(t("changesSaved"))
        refresh()
      }).catch(e => {
        toast.success(t("errorSaving"))
      })
  }

  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="column" >
          <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
            <Paper elevation={3} className={classes.mainPaper}>
              <Toaster
                position="top-right"
              />
              <Box className={[classes.paddedTop, classes.title, classes.popupTitle].join(" ")}>
                {t('manageAccount')}
              </Box>
              <Box display={'block'} >

                <Box mt={1} className={[classes.spaced, classes.spacedTop].join(" ")}>
                  <Box className={[classes.paddedTop, classes.sectionTitle].join(" ")}>
                    {t('personnalInfo')}
                  </Box>
                  <Grid container direction="row" spacing={2} justify="center">
                    <Grid item xs={1} className={classes.verticalCenter}>
                      <label className={classes.twoItem} >{t("firstName")} : </label>
                    </Grid>
                    <Grid item xs={2} className={classes.verticalCenter}>
                      <Name
                        initialValue={userFirstName}
                        nameIsValid={isValidFirstName}
                        setname={setuserFirstName}
                      />
                    </Grid>
                    <Grid item xs={1} className={classes.verticalCenter}>
                      <label className={classes.twoItem} >{t("lastName")} : </label>
                    </Grid>
                    <Grid item xs={2} className={classes.verticalCenter}>
                      <LastName
                        initialValue={userLastName}
                        lastNameIsValid={isValidLastName}
                        setLastName={setuserLastName}
                      />
                    </Grid>
                    <Grid item xs={5} className={classes.verticalCenter}></Grid>
                  </Grid>
                  <Grid container direction="row" spacing={2} justify="center">
                    <Grid item xs={1} className={classes.verticalCenter}>
                      <label className={classes.twoItem} >{t("email")} : </label>
                    </Grid>
                    <Grid item xs={2} className={classes.verticalCenter}>
                      <Email
                        initialValue={userEmail}
                        emailIsValid={isValidEmail}
                        setEmail={setuserEmail}
                      />
                    </Grid>
                    <Grid item xs={1} className={classes.verticalCenter}>
                      <label className={classes.twoItem} >{t("language")} : </label>
                    </Grid>

                    <Grid item xs={2} className={classes.verticalCenter}>

                      <IconButton onClick={e => { setuserLanguage('fr') }} sx={{ p: 0 }}>
                        <Avatar alt="Francais" src={userLanguage == 'fr' ? frFlag : frFlagGrey} sx={{ height: '30px', width: '30px' }} />
                      </IconButton>
                      <IconButton onClick={e => { setuserLanguage('en') }} sx={{ p: 1 }}>
                        <Avatar alt="English" src={userLanguage == 'fr' ? enFlagGrey : enFlag} sx={{ height: '30px', width: '30px' }} />
                      </IconButton>

                    </Grid>
                    <Grid item xs={5} className={classes.verticalCenter}></Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.verticalCenter}></Grid>
                  {isEnduser ?
                    <Box className={[classes.paddedTop, classes.sectionTitle].join(" ")}>
                      {t('chargePoints')}
                    </Box>
                    : <></>
                  }
                  {isEnduser ? statedata && statedata.cps.map((cp, i, _) =>
                    <CpSection
                      cp={cp}
                      onCPChange={(cp) => { updateCp(cp, i) }}
                    />) : <></>
                  }
                  <br />
                  <Box mt={2}>
                    <Grid container direction="row" justify="center">
                      <Box m={1}>
                        <Button variant="contained" color="primary" onClick={submitChanges} >
                          {t('confirm')}
                        </Button>
                      </Box>
                      <Box m={1}>
                        <Button color="secondary" variant="contained" onClick={gotoHome}>
                          {t("cancel")}
                        </Button>
                      </Box>

                      {/*
                      <Box m={1}>
                        <Button variant="contained" style={{ backgroundColor: "darkred", color: "white" }}>
                          <WarningIcon />{t('supressAccount')}
                        </Button>
                     </Box>*/}
                    </Grid>
                  </Box>

                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}


function CpSection({ cp, onCPChange }: { cp: ChargePoint, onCPChange: (cp: ChargePoint) => void }) {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState(cp.name)
  const [changeGridModalOpen, setchangeGridModalOpen] = useState(false)
  const [address, setAddress] = useState<Address | null>()
  const classes = useStyles()
  const grids = useContext(GridContext).grids
  var grid = grids[0]
  grids.forEach((g) => {
    if (g.id == cp.grid) {
      grid = g
    }
  })
  var selectREf = createRef<typeof Select>()
  var [gridID, setGridID] = useState(cp.grid);
  var [selectedGridID, setselectedGridID] = useState(cp.grid);
  const nameIsValid = name.length > 0
  return (<>
    <ChangeGridModal
      key={new Date().valueOf()}
      open={changeGridModalOpen}
      cp={cp}
      newGridID={() => {
        return selectedGridID
      }}


      onClose={() => { setchangeGridModalOpen(false) }}
      onSubmit={() => { setGridID(String(selectREf.current?.prototype.value)) }}

    /> 
    <Box maxWidth={'80%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
      <Grid container direction="row" justify="center" spacing={1}>
        <Grid item xs={2} >
          <TextField
            value={name}
            fullWidth
            variant="outlined"
            label={nameIsValid ? name ? t('name') : t('name') : t('mandatory')}
            error={!nameIsValid}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
              setName(evt.target.value)
              cp.name = evt.target.value
              onCPChange(cp)
            }}
          />
        </Grid>
        <Grid item xs={1} >
          <TextField
            disabled
            label={t("status")}
            value={cp.isGridValidated?t('gridValid'):t('waitingForValidation')}
            fullWidth
            variant="outlined"
           
          />
        </Grid>
        <Grid item xs={4} >
          {GmapsAutocomplete(
            t("addressPlaceHolder"),
            (a: Address) => {
              cp.address = a
              onCPChange(cp)
              setAddress(a)

            },
            cp.address?.text
          )}
        </Grid>

        <Grid item xs={2} className={classes.verticalCenter}>
          <Select
            labelId="Point de Charge"
            id="demo-simple-select"
            value={gridID}
            ref={selectREf}
            label="Point de Charge"
            onChange={(e) => {
              setselectedGridID(String(e.target.value))
              setchangeGridModalOpen(true)
              // setGridID(String(e.target.value)) 
            }}
          >
            {grids.map(grid => {
              return (<MenuItem value={grid.id}>{grid.provider + "-" + grid.name}</MenuItem>)
            })}
          </Select>
        </Grid>
        <Grid item xs={1} className={classes.verticalCenter}>
          <Box>
            <Tooltip title="Justificatif">
              <IconButton onClick={() => downladGridDoc(cp)}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.verticalCenter}>
          <Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>)
}



interface ChangeGridModalProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean,
  cp: ChargePoint,
  newGridID: () => string,
}

export const ChangeGridModal = ({
  open,
  cp,
  newGridID,
  onClose,
  onSubmit,
}: ChangeGridModalProps) => {

  const { t, i18n } = useTranslation();
  var [isLoading, setIsLoading] = useState(false);
  const classes = useStyles()
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };
  return (
    <Dialog open={open}>
      <DialogTitle ><span className={classes.popupTitle}>{t('warningGridChange')}</span></DialogTitle>
      <DialogContent>
        <>
          <Box m={4}>
            <Typography variant='h5'>{t("warningGridChangeExplanation")} </Typography>
          </Box>
          <Box m={4}>
            <Typography variant='h5'>{t("warningGridChangeExplanationL2")} </Typography>
          </Box>

        </>
        <input
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={async (e) => {
            if (e.target.files) {
              try {
                setIsLoading(true)
                await uploadToS3({ file: e.target.files[0], cp, gridID: newGridID(),periods:[],HCF:null,HPF:null })
                setIsLoading(false)
                //onSucess()
              }
              catch (e) {
                toast.error(t("documentUploadError"))
                console.log(e)
              }

            }
            return null
          }}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" color="primary" style={{ display: isLoading ? "none" : "inline-flex" }} className={classes.spaced} startIcon={<FileUpload />} component="span">
            {t("billUploadButton")}
          </Button>
        </label>
        <span className="loader" style={{ width: '50px', display: isLoading ? "block" : "none" }}></span>
      </DialogContent>
      <DialogActions >
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


