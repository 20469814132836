import React, { useState, useEffect, useContext } from 'react'
import { Company, User } from '../libs/types';
import { AuthContext } from './authContext';
import { getAllCompanies, getCompany } from '../service/companies';
import Services from '../service/services';

export interface ICompanies {
  companies: Company[]
  nonTechnicalCompanies: Company[]
  companyMap: Map<string, Company>
  getCompany: () => Company | null
  refresh: () => void
  getAllCompanies: () => Company[]
  //nameFromID: (id: string) => string
}

const defaultState: ICompanies = {
  companies: [],
  nonTechnicalCompanies: [],
  companyMap: new Map<string, Company>(),
  getCompany: () => null,
  refresh: () => { },
  getAllCompanies: () => { return [] }
}

type Props = {
  children?: React.ReactNode
}
export var CompaniesContext = React.createContext(defaultState)


const CompanyProvider = ({ children }: Props) => {
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const [companiesData, setcompaniesData] = useState([] as Company[])
  const cMap = new Map(companiesData.map(company => [company.id, company]))
  const nonTechnicalCompanies = companiesData.filter(company => company.isTechnical != true)
  const state: ICompanies = {
    getCompany: () => {
      if (auth.sessionInfo?.fleetManagerProfile || auth.sessionInfo?.isEndUser) {
        return cMap.get(auth.sessionInfo.companyID!) || null
      }
      return null
    },
    companies: companiesData,
    companyMap: cMap,
    refresh: async () => {
      setcompaniesData(await fetch())
    },
    nonTechnicalCompanies,
    getAllCompanies: () => { return companiesData }

  }

  const fetch = async (): Promise<Company[]> => {
    try {
      let companies: Company[] = []
      if (auth.sessionInfo?.fleetManagerProfile || auth.sessionInfo?.isEndUser) {
        let company = await services.getCompany(auth.sessionInfo.companyID!)
        if (company) companies.push(company)
      } else {
        companies = await services.getAllCompanies()
      }
      return companies
    } catch (err) {
      return []
    }

  }
  useEffect(() => {
    if (!auth.sessionInfo) {
      return
    }
    async function fecthCompaniesData() {
      setcompaniesData(await fetch())
    }
    fecthCompaniesData()
  }, [auth.sessionInfo])


  return <CompaniesContext.Provider value={state}>{children}</CompaniesContext.Provider>
}




export default CompanyProvider
