import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, createTheme } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'
import {
  Add,
  Archive,
  Unarchive,
  HelpOutline
} from '@material-ui/icons';
import MaterialReactTable, { MRT_Cell, MRT_ColumnDef, MRT_RowSelectionState, MRT_TableInstance } from "material-react-table";
import { ChargePoint, Session } from "../../libs/types";
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getReactTableLocalisation } from "../../libs/i18n";
import { tsToDate, getDistanceFromLatLonInM } from "../../libs/utils";
import { theme } from "../../libs/theme";
import { AuthContext } from "../../contexts/authContext";
import Services from "../../service/services";
import ModalPlayer from "../video/modalplayer";
import SessionPanel from "./panel";

var cpsMap: Map<string, ChargePoint> = new Map()

export default function SessionList({
  archiveMode,
  filterBillable,
  id,
  createNote,
  makeBillable,
  displayNotification,
  cpMap,
  isLoading,
}: {
  archiveMode: boolean,
  filterBillable?: boolean,
  id: string,
  createNote?: (sessionIds: string[], reset: () => void) => {},
  makeBillable?: (sessionIds: string[], reset: () => void) => {},
  displayNotification: (text: string, isError: boolean) => void,
  cpMap: Map<string, ChargePoint>,
  isLoading: boolean

}) {

  const filterSession = (sessions: Session[], billabledOnly: boolean | null, billedOnly: boolean | null): Session[] => {
    return sessions.filter(session => {
      const hasCarDetails = Boolean(session.carDetails);
      const hasCreditNote = Boolean(session.creditNote);
      if (billabledOnly === true) {
        if (hasCarDetails) {
          return billedOnly === null || billedOnly === hasCreditNote;
        }
        return false;
      }
      if (billabledOnly === false) {
        return !hasCarDetails;
      }
      return true;
    });
  }
  cpsMap = cpMap
  const classes = useStyles()
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")

  const sessionColum = useMemo<MRT_ColumnDef<Session>[]>(() => SessionscolumnDef(t), [i18n.language])
  const SessionsTableInstanceRef = useRef<MRT_TableInstance<Session>>(null);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [filterbilledOnly, setfilterbilledOnly] = useState<boolean | null>(false);
  const [filterBillableOnly, setfilterBillableOnly] = useState<boolean | null>(filterBillable != undefined ? filterBillable : true);
  var [displayHelpVideo, setdisplayHelpVideo] = useState(false)
  const [sessions, setSessions] = useState([] as Session[])
  const [sessionsToshow, setsessionsToshow] = useState<Session[]>(() => { return filterSession(sessions, true, filterbilledOnly) });

  const unarchiveNote = async (sessionIds: string[], reset: () => void): Promise<void> => {
    try {
      const l = mapFromSession(sessions, sessionIds)
      const sessionToUpdate = [] as Session[]

      sessionIds.forEach(id => {
        const session = l.get(id)
        if (session) {
          sessionToUpdate.push(session)
        }
      })

      setRowSelection({})
      await services.unArchiveSessions(auth.User, sessionToUpdate)
      await refreshSessions()
      displayNotification(t("successUnarchive"), false)
    }
    catch (e) {
      displayNotification(t("errorArchive"), true)
      return
    }
  }

  const archiveNote = async (sessionIds: string[], reset: () => void): Promise<void> => {
    try {
      const l = [] as Session[]
      mapFromSession(sessions, sessionIds).forEach(session => {
        l.push(session)
      })
      setRowSelection({})
      await services.archiveSessions(auth.User, l)
      refreshSessions()
      displayNotification(t("successArchive"), false)
    } catch (error) {
      displayNotification(t("errorArchive"), true)
    }
  }

  const refreshSessions = async () => {
    const sessions = await services.getSessionForUser(auth.User, null, null, archiveMode)
    setSessions(sessions)
    setsessionsToshow(filterSession(sessions, filterBillableOnly, filterbilledOnly))
  }
  useEffect(() => {
    refreshSessions()
  }, [filterBillable])



  return (
    <>
      <ModalPlayer
        introText={['demoSessionline1', 'demoSessionline2', 'demoSessionline3']}
        open={displayHelpVideo}
        onClose={() => { setdisplayHelpVideo(false) }}
        urls={['https://youtu.be/gU_O58d-kmw?rel=0', 'https://youtu.be/ckKXbKNzRu8?rel=0']}
        urlsTitle={['videoTourCarWithoutMetadata', 'videoTourCarWithMetadata']}
      />
      <MaterialReactTable
        muiTablePaperProps={{ id }}
        muiTableProps={{
          sx: {
            tableLayout: 'grid',

          },
        }}
        muiTableHeadCellProps={{
          sx: {
            flex: '0 0 auto',
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            flex: '0 0 auto',
          },
        }}
        columns={sessionColum}
        data={sessions ? sessionsToshow : []}
        enableRowSelection={true}
        initialState={{
          density: "compact",
          sorting: [
            {
              id: 'start',
              desc: true,
            },
            {
              id: 'status',
              desc: true,
            },
          ],
        }}
        renderTopToolbarCustomActions={() => {
          const sessionsIds: string[] = []
          const selectedSessions: Session[] = []
          for (let idx in rowSelection) {
            if (rowSelection[idx]) {
              sessionsIds.push(sessionsToshow[Number(idx)].id)
              selectedSessions.push(sessionsToshow[Number(idx)])
            }
          }
          return (
            <div style={{ display: 'flex' }}>
              {
                archiveMode ?
                  getactionButton(sessionsIds, unarchiveNote!, setRowSelection, classes, t("unarchive"), <Unarchive />)
                  :
                  filterBillableOnly != false ?
                    (<>
                      {getactionButton(sessionsIds, archiveNote!, setRowSelection, classes, t("archive"), <Archive />)}
                      {filterbilledOnly ? <></> : getactionButton(sessionsIds, createNote!, setRowSelection, classes, t("creditNotes"), <Add />)}
                    </>)
                    :
                    (<>
                      {getactionButton(sessionsIds, archiveNote!, setRowSelection, classes, t("archive"), <Archive />)}
                      {getactionButton(sessionsIds, makeBillable!, setRowSelection, classes, t("makeBillable"), <Add />)}
                    </>)
              }
              <div style={{ display: 'flex' }} className={classes.spaced}>
                <div className={classes.radioGroup} style={{ float: 'left', marginRight: '10px' }}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label" className={classes.centerText}> {t("Billable only")}</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e => {

                        const v = e.target.value == "null" ? null : e.target.value == "true" ? true : false
                        var filterbilled = filterbilledOnly
                        if (v == false) {
                          filterbilled = null
                        }
                        setfilterBillableOnly(v)
                        setRowSelection({})
                        setsessionsToshow(filterSession(sessions, v, filterbilled))
                      })
                      }
                    >
                      <FormControlLabel value={"true"} control={<Radio />} checked={filterBillableOnly == true} label={t("yes")} />
                      <FormControlLabel value={"false"} control={<Radio />} checked={filterBillableOnly == false} label={t("no")} />
                    </RadioGroup>
                  </FormControl>

                </div>

                {filterBillableOnly != false && (

                  <div className={classes.radioGroup} style={{ float: 'right', marginRight: '10px' }}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label" className={classes.centerText}>{t("billedOnly")}</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e => {
                          const v = e.target.value == "null" ? null : e.target.value == "true" ? true : false
                          setRowSelection({})
                          setfilterbilledOnly(v)
                          setsessionsToshow(filterSession(sessions, filterBillableOnly, v))
                        })
                        }
                      >
                        <FormControlLabel value={true} control={<Radio />} checked={filterbilledOnly == true} label={t("yes")} />
                        <FormControlLabel value={false} control={<Radio />} checked={filterbilledOnly == false} label={t("no")} />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )
                }
                {!archiveMode &&
                  <div style={{ display: 'flex' }}>
                    <Button color={"secondary"}
                      className={classes.oneLineButtonSmall} variant="contained"
                      onClick={() => { setdisplayHelpVideo(true) }}>
                      <HelpOutline />
                    </Button>
                  </div>
                }
              </div>
              {<Typography className={classes.title} variant="h5">
                &nbsp;
              </Typography>}
            </div>

          )
        }
        }
        renderDetailPanel={row => {
          if (!row.row.getIsExpanded()) {
            return (<></>)
          }
          const s = new Date(Number(row.row.original.start) * 1000)
          const e = new Date(Number(row.row.original.end) * 1000)
          if (row.row.original.carDetails) {
            if (row.row.original.carDetails.SOC) {
              return (
                //@ts-ignore
                < SessionPanel
                  key={row.row.original.id}
                  //@ts-ignore²
                  session={row.row.original}
                  start={s}
                  end={e}
                />
              )
            }
            console.log(row.row.original)
            return (
              <>
                <b>{t("manualPanelTitle")}</b>:
                <br />
                {t("manualPaneldesc")}
              </>
            )


          }
          return (
            <>
              <b>{t("Not Billable")}</b>:
              <br />
              {t("notBillableDesc")}
            </>
          )

        }}
        muiTableBodyRowProps={({
          row
        }) => ({
          sx: {
            backgroundColor: row.getValue<any>('carDetails.VIN') ? row.original.carDetails?.PlateNumber && !row.original.carDetails.SOC ? '#9ec5e9' : 'white' : 'lightgray',
            color: row.getValue<any>('carDetails.VIN') ? row.original.carDetails?.PlateNumber && !row.original.carDetails.SOC ? 'white' : 'black' : 'black',

          }
        })}


        onRowSelectionChange={setRowSelection}
        state={{ rowSelection, isLoading }}
        tableInstanceRef={SessionsTableInstanceRef}
        enableColumnOrdering={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableGlobalFilter={false}
        localization={getReactTableLocalisation()}
      />
    </>
  )
}

function getactionButton(sessionsIds: string[],
  action: (sessionIds: string[], reset: () => void) => void,
  setRowSelection: (rowSelection: MRT_RowSelectionState) => void,
  classes: any,
  label: string,
  icon: ReactNode
) {
  return (
    <Button color={sessionsIds.length > 0 ? "secondary" : "default"}
      className={classes.oneLineButton} variant="contained"
      startIcon={icon}
      onClick={() => {
        if (sessionsIds.length == 0) {
          return
        }
        action(sessionsIds, () => { setRowSelection({}); return })
      }}>
      {label}
    </Button>)
}




const SessionscolumnDef = (t: (s: string) => string): MRT_ColumnDef<Session>[] => [
  {
    accessorKey: 'chargePointID',
    header: t('chargePoint'),
    accessorFn: (row) => {
      console.log("row", row)
      if (row.carDetails ){
        if (row.carDetails.location && row.carDetails.location.lat != 0  ) {
        //compute distance
        const distance = getDistanceFromLatLonInM(row.carDetails.location.lat, row.carDetails.location.lng, cpsMap.get(row.chargePointID)?.address?.location.lat!, cpsMap.get(row.chargePointID)?.address?.location.lng!)
        return cpsMap.get(row.chargePointID)?.name + " (" + distance.toFixed(0) + " m" + ")"
      }else{
        return `${cpsMap.get(row.chargePointID)?.name} ( ${t("unavalaibleCarlocation")} )`
      }
    }
      return cpsMap.get(row.chargePointID)?.name || "" || cpsMap.get(row.chargePointID)
    },
    Cell: (cell) => formatSessionCell(t, cell.cell),
    minSize: 60,
    size: 60,
    maxSize: 60
  },
  {
    accessorKey: 'carDetails.VIN',
    header: t('car'),
    minSize: 50,
    size: 50,
    maxSize: 50,
    accessorFn: (row) => row.carDetails?.PlateNumber ? !row.carDetails.SOC ? row.carDetails?.PlateNumber + " (" + t("manual") + ")" : row.carDetails?.PlateNumber : "",
    Cell: (cell) => formatSessionCell(t, cell.cell),
  },
  {
    accessorKey: 'start',
    header: t('start'),
    accessorFn: (row) => tsToDate(row.start).toLocaleString(["fr"], { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }),
    sortingFn: (rowA: any, rowB: any, id): number => {
      const a = rowA.original.start;
      const b = rowB.original.start;
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    },
    Cell: (cell) => formatSessionCell(t, cell.cell),
    minSize: 50,
    size: 50,
    maxSize: 80
  },
  {
    accessorKey: 'end',
    header: t('end'),
    accessorFn: (row) => row.end ? tsToDate(row.end).toLocaleString([], { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) : " - ",
    Cell: (cell) => formatSessionCell(t, cell.cell),
    sortingFn: (rowA: any, rowB: any, id): number => {
      const a = rowA.original.start;
      const b = rowB.original.start;
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    },
    minSize: 50,
    maxSize: 50,
    size: 50,
  },
  {
    accessorKey: 'consumedPower.total',
    header: 'kwh',
    accessorFn: (row) => row.consumedPower && row.consumedPower.total ? Math.round(row.consumedPower.total * 10) / 10 : "- ",
    minSize: 30,
    size: 30,
    maxSize: 30,
    Cell: (cell) => formatSessionCell(t, cell.cell),
  },
  {
    accessorKey: 'billing.total',
    header: 'Total (€)',
    accessorFn: (row) => row.billing ? Math.round(row.billing.total * 100) / 100 : " -",
    minSize: 50,
    size: 50,
    maxSize: 50,
    Cell: (cell) => formatSessionCell(t, cell.cell),
  },
  {
    accessorKey: 'status',
    header: t('status'),
    accessorFn: (row) => row.creditNote ? 'noteEditedStatus' : row.status,
    minSize: 50,
    size: 50,
    maxSize: 50,
    Cell: (cell) => formatSessionCell(t, cell.cell, true),
  },
  {
    accessorKey: 'creditNote',
    header: t('creditNote'),
    accessorFn: (row) => {
      const date = row.creditNote?.ts ? new Date(row.creditNote.ts) : null;
      return date ? date.toLocaleDateString() : " - ";
    },
    minSize: 50,
    maxSize: 50,
    size: 50,
    Cell: (cell) => formatSessionCell(t, cell.cell),
  },
];




const mapFromSession = (allSessions: Session[], sessionIds: string[] | null): Map<string, Session> => {
  const res = new Map<string, Session>
  if (sessionIds == null) {
    allSessions.map(session => {
      res.set(session.id, session)
    })
    return res
  }
  sessionIds.forEach(id => {
    const session = allSessions.find(s => s.id == id)
    if (session) {
      res.set(id, session)
    }
  })
  return res
}


const formatSessionCell = (t: (s: string) => string, cell: MRT_Cell<Session>, translatable: boolean = false): ReactNode => {
  let isEnded = cell.row.original.status == "Ended"
  if (!cell.getValue()) {
    return "-"
  }
  return (
    <Box
      component="span"
      width="100%"
      className={!isEnded ? "blink" : ""}
      style={{
        //color: isEnded ? "black" : "#066DCD",
      }}
    >
      {translatable ? t(cell.getValue<String>().toString()) : cell.getValue<String>().toString()}
    </Box>
  )
}


const theme2 = createTheme({ ...theme }, {
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          disabled: {
            display: 'none'

          },
        }
      },
    },
  },
});

const useStyles = makeStyles((theme2) => ({
  root: {
    overflow: 'auto',
    width: '99%',
    marginLeft: '10px',
    marginRight: '10px',
  },
  title: {
    textAlign: 'center',
    width: '30%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px"
  },
  spacedTop: {
    marginTop: "30px"
  },
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },
  session: {
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  radioGroup: {
    width: '220px',
  },
  green: {
    backgroundColor: 'green',
  },
  hero: {
    width: '100%'
  },
  oneLineButton: {
    width: '300px',
    margin: '10px',
    padding: '2px',

  },
  oneLineButtonSmall: {

    margin: 'auto',


  },
  centerText: {
    margin: "auto"
  }
}))