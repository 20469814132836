import axios from 'axios'
import { BASE_URL } from './conf'
import { Session, User } from '../libs/types'




/*** 
 * 
 * 
 * export interface Session {
    chargePointID: string
    type : "residential"| ""
    status : "Started" | "Ended"
    start: Date
    end :Date
    duration: number
    carID: string
    ConsumedPower : {
        StartIndexes: Index[]
        EndIndexes : Index[]
        total: number
    }
    billing:{
        Grid :any 
        Total:number
        HT:number
        TVA:number
    }
};
 * 
 * 
 * 
 * **/

export async function getSessionForCompany(authtoken :string,companyID: string, start: Date | null, end: Date | null,archivedOnly:boolean): Promise<Session[]> {
    return new Promise(function (resolve, reject) {
        const params = new URLSearchParams([]);
        if (start) {
            params.append('start', String(Math.floor(new Date(start).getTime() / 1000)))
        }
        if (end) {
            params.append('end', String(Math.floor(new Date(end).getTime() / 1000)))
        }
        if (archivedOnly) {
            params.append('archivedOnly', 'true')
        }
        axios.get(BASE_URL + `/sessions/company/${companyID}`, {
            params,
            responseType: 'json',
            headers:{
                Authorization:authtoken
            }
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var sessions: Session[] = []
                if (res.data){
                    res.data.forEach((idx: Session) => {
                        idx.companyID = companyID
                        sessions.push(idx)
                    })
                    resolve(sessions)
                }
                resolve([])
            }).catch(e => reject(e))
    })
}




export async function getSessionForUser(authtoken :string,user: User, start: Date | null, end: Date | null,archivedOnly:boolean): Promise<Session[]> {
    return new Promise(function (resolve, reject) {
        if (!user.companyID || !user.userName) {
            resolve([])
        }
        const params = new URLSearchParams([]);
        if (start) {
            params.append('start', String(Math.floor(new Date(start).getTime() / 1000)))
        }
        if (end) {
            params.append('end', String(Math.floor(new Date(end).getTime() / 1000)))
        }
        if (archivedOnly) {
            params.append('archivedOnly', 'true')
        }
        axios.get(BASE_URL + `/sessions/company/${user.companyID}/user/${user.userName}`, {
            params,
            responseType: 'json',
            headers:{
                Authorization:authtoken
            }

        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    resolve([])
                }
                var sessions: Session[] = []
                if (res.data){
                    res.data.forEach((idx: Session) => {
                        idx.companyID = user.companyID
                        sessions.push(idx)
                    })
                    resolve(sessions)
                }
                resolve([])
            }).catch(e => reject(e))
    })
}


export async function archiveSessions(authtoken :string,user: User, sessions: Session[]): Promise<void> {
    const  updateSession =[] as any[]
    
    for (const session of sessions) {
        updateSession.push({
            chargePointID: session.chargePointID,
            ts: session.start,
            archived:true
        })
    }
    return updateSessions(authtoken,user,updateSession)
}


export async function unArchiveSessions(authtoken :string,user: User, sessions: Session[]): Promise<void> {
    const  updateSession =[] as any[]
    
    for (const session of sessions) {
        updateSession.push({
            chargePointID: session.chargePointID,
            ts: session.start,
            archived:false
        })
    }
    return updateSessions(authtoken,user,updateSession)
}


const updateSessions=(authtoken :string,user: User, sessions: Session[]): Promise<void> =>{
    const body = {
        sessions
    }
    return new Promise(function (resolve, reject) {
        axios.put(BASE_URL + `/company/${user.companyID}/sessions`, body, {
            responseType: 'json',
            headers:{
                Authorization:authtoken
            }
        }).then((res: any) => {
            resolve()
        }).catch(e => reject(e))
    })
}

export async function updateSesssionsCars(authtoken :string,user: User, sessions: Session[]): Promise<void> {
    const   updateSession =[] as any[]
    for (const session of sessions) {
        updateSession.push({
            chargePointID: session.chargePointID,
            ts: session.start,
            carVIN: session.carDetails.VIN
        })
    }
    return updateSessions(authtoken,user,updateSession)
}
