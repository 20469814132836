import { Box, Button, Grid, MenuItem, Select, Tooltip, Typography, makeStyles } from "@material-ui/core";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import IconButton from '@material-ui/core/IconButton'
import { useState, useContext, useMemo } from "react";
import { GridContext } from '../contexts/GridContext';
import { ChargePoint } from "../libs/types";
import { downloadIndex } from "../service/Indexes";
import { downladGridDoc, uploadToS3 } from "../service/chargepoints";
import { GetGMap } from "./gmaps";
import {
  Download as DownloadIcon, FileUpload, WarningAmberOutlined,HourglassFullTwoTone as HourglassIcon
} from '@mui/icons-material';

import { useTranslation } from "react-i18next";
import { getReactTableLocalisation } from "../libs/i18n";
const useStyles = makeStyles({
  verticalCenter: {
    margin: 'auto',
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px"
  },
  spacedTop: {
    marginTop: "5%"
  },
})

export default function ChargePointList({
  cps
}: {
  cps: ChargePoint[]
}) {
  
  const classes = useStyles()
  const { t,i18n } = useTranslation();
  const CPcolumns = useMemo<MRT_ColumnDef<ChargePoint>[]>(()=>CPcolumnDef(t), [i18n.language])
  return (
    <MaterialReactTable
      columns={CPcolumns}
      data={cps}
      enablePagination={false}
      renderDetailPanel={row => {
        return CPPanel(cps[row.row.index])
      }}
      renderTopToolbarCustomActions={() => (
        <>
          <Typography className={classes.title} variant="h5">
            {t("chargePoints")}
          </Typography>
        </>
      )}
      initialState={{density: "compact"}}
      enableRowSelection={false} 
      enableColumnOrdering={false}
      enableHiding={false}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableColumnFilters={false}
      enableExpandAll={true}
      enableSorting={false}
      enableGlobalFilter={false}
      localization={getReactTableLocalisation()}
    />
  )

}


const CPcolumnDef = (t: (s: string) => string): MRT_ColumnDef<ChargePoint>[] => [
  {
    accessorKey: 'name', 
    header: t('name'),
    Cell: ({ cell }) => {
      let hasGrid = cell.row.original.grid ? true : false
      let isGridValidated = cell.row.original.isGridValidated 
      if (!hasGrid) {
        return (<Box
          component="span"
          style={{
            color: "red",
            height: '25px',
            verticalAlign: 'sub'

          }}>
          <Tooltip title={t('missingGrid')}>
            <WarningAmberOutlined />
          </Tooltip>

          <span style={{
            height: '25px',
            verticalAlign: "middle",
            display: 'inline-block',
            color: "black",
            marginLeft: "5px"
          }}>
            {cell.getValue<String>()}
          </span>
        </Box>
        )
      }
      if (!isGridValidated){
        return (<Box
          component="span"
          style={{
            color: "red",
            height: '25px',
            verticalAlign: 'sub'

          }}>
          <Tooltip title={t('waitingForValidation')}>
            <HourglassIcon
            color="primary" />
          </Tooltip>

          <span style={{
            height: '25px',
            verticalAlign: "middle",
            display: 'inline-block',
            color: "black",
            marginLeft: "5px"
          }}>
            {cell.getValue<String>()}
          </span>
        </Box>
        )
      }
      return (
        cell.getValue<String>()
      )
    }
  },
  /*{
    accessorKey: 'isCharging', //simple recommended way to define a column
    header: 'status',
    Cell: ({ cell }) => {
      let inCharge = cell.row.original.isCharging ;
      return (
        <Box
          component="span"
          className={inCharge ? 'blink' : ""}
          style={{
            backgroundColor: inCharge ? "green" : "white",
            color: inCharge ? "white" : "black",
            padding: "8px",
            borderRadius: "5px",
          }}
        >
          {inCharge?t("charging"):t("waiting")}
        </Box>
      )
    }
  }*/

]

function CPPanel(cp: ChargePoint) {
  var [gridID, setGridID] = useState(cp.grid);
  const classes = useStyles()
  const { t } = useTranslation();
  const grids = useContext(GridContext).grids
  if (!grids) {
    return (<></>)
  }

  var grid = grids[0]
  grids.forEach((g) => {
    if (g.id == gridID) {
      grid = g
    }
  })

  return (
    <>
      <Grid container direction="row" spacing={1} justify="center">
        {
          !gridID ? (<>
            <Grid item xs={4} className={classes.verticalCenter}>
              <Typography >{t("grid")} : </Typography>
            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <Select
                labelId="Point de Charge"
                id="demo-simple-select"
                value={gridID}
                label="Point de Charge"
                onChange={(e) => { setGridID(String(e.target.value)) }}
              >
                {grids.map(grid => {
                  return (<MenuItem value={grid.id}>{grid.provider + "-" + grid.name}</MenuItem>)
                })}

              </Select>
            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <input
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={async (e) => {
                  if (e.target.files) {
                   // await uploadToS3({ file: e.target.files[0], cp, gridID,periods:[], })
                  }
                  return null
                }}
              />
              <label htmlFor="raised-button-file"> 
                <Button variant="contained" color="primary" className={classes.spaced} startIcon={<FileUpload />} component="span">
                  Justificatif
                </Button>
              </label>
            </Grid>
          </>) : (<>
            <Grid item xs={4} className={classes.verticalCenter}>
              <Typography >{t("grid")} : </Typography>
            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <Typography >
                {grid ? grid.provider + " - " + grid.name : ""}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.verticalCenter}>
              <Box>
                <Tooltip title="Justificatif">
                  <IconButton onClick={() => downladGridDoc(cp)}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </>)
        }

        <Grid item xs={6} className={classes.verticalCenter}>
          <Typography >Indexes : </Typography>
        </Grid>
        <Grid item xs={6} className={classes.verticalCenter}>
          <Tooltip title={t("downloadIndexes")}>
            <IconButton onClick={() => { downloadIndex(cp) }}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid >
      {cp.address ? GetGMap(cp.address,400,400) : ''}

    </>)
}